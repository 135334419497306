import React, { useEffect, useState } from "react";
import "./SavedInvoice.scss";
import "./SavedInvoiceEpay.scss";
import "./SavedInvoiceVNeID.scss";
import { useNavigate } from "react-router-dom";
import {
  serviceType,
  serviceTypeEnum,
} from "../../../../../Auth/services/auth.type";
import { savedInvoiceType } from "../../../../services/invoices.type";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../Redux/store";
import { MainRoutesEnum } from "../../../../../../Types/routes.type";
import { queryEnum } from "../../../../../../Types/global.type";
import {
  LoanIDTypeCode,
  LoanIDTypeEnum,
} from "../../../../../BillingService/components/LoanIDTypeField/services/loanIDType.type";

interface PT {
  data: savedInvoiceType;
}

const SavedInvoice: React.FC<PT> = ({ data }) => {
  const navigate = useNavigate();
  const {
    serviceCode,
    serviceName,
    fullName,
    billingCode,
    provinceCode,
    typeBillingCode,
  } = data;
  const [service, setService] = useState<string>("");
  const [serviceTypePath, setServiceTypePath] = useState<string>("");

  const services = useSelector<RootState, serviceType[]>(
    (state) => state.auth.services
  );

  const theme = document
    .getElementsByTagName("HTML")[0]
    .getAttribute("data-theme");

  useEffect(() => {
    const service = services.find((a) => a.code === serviceCode)?.serviceType;
    if (service)
      setServiceTypePath(
        `?${queryEnum.service}=${encodeURIComponent(service)}`
      );
    setService(service ? service : "");
  }, [serviceCode, services]);

  const onClickSavedInvoice = () => {
    const servicePath = `&${queryEnum.provider}=${encodeURIComponent(
      serviceCode
    )}`;
    const billingPath = `&${queryEnum.clientID}=${encodeURIComponent(
      billingCode
    )}`;
    const provincePath = provinceCode
      ? `&${queryEnum.province}=${encodeURIComponent(provinceCode)}`
      : "";
    let loanIDTypePath = `&${queryEnum.loanIDType}=`;

    switch (true) {
      case typeBillingCode === LoanIDTypeCode.CCCD:
        loanIDTypePath += encodeURIComponent(LoanIDTypeEnum.CCCD);
        break;

      case typeBillingCode === LoanIDTypeCode.CONTRACT_CODE_LOAN &&
        service === serviceTypeEnum["TAI-CHINH"]:
        loanIDTypePath += encodeURIComponent(LoanIDTypeEnum.CONTRACT_CODE_LOAN);
        break;

      case typeBillingCode === LoanIDTypeCode.CONTRACT_CODE_INS &&
        service === serviceTypeEnum["BAO-HIEM"]:
        loanIDTypePath += encodeURIComponent(LoanIDTypeEnum.CONTRACT_CODE_INS);
        break;

      default:
        loanIDTypePath = "";
        break;
    }

    navigate(
      `/${MainRoutesEnum.BILLING}${serviceTypePath}${servicePath}${billingPath}${provincePath}${loanIDTypePath}`
    );
  };

  return (
    <div className="saved-invoice-container" onClick={onClickSavedInvoice}>
      <div className={`saved-invoice-icon ${service}-${theme}`}></div>
      <div className="saved-invoice-detail">
        <div className="saved-invoice-text saved-invoice-text-bold">
          {serviceName}
        </div>
        {fullName && fullName.trim() !== "" && (
          <div className="saved-invoice-text">{fullName}</div>
        )}
        {billingCode && <div className="saved-invoice-text">{billingCode}</div>}
        {!fullName && <div className="saved-invoice-text"></div>}
        {fullName && fullName.trim() === "" && (
          <div className="saved-invoice-text"></div>
        )}
      </div>
    </div>
  );
};

export default React.memo(SavedInvoice);
