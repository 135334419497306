import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../Redux/store";
import { useEffect } from "react";
import { billingServiceActions } from "../services/billingService.slice";
import { servicesAutoNeedLoanIDType } from "../components/LoanIDTypeField/services/loanIDType.type";

const useSetNeedLoanIdType = () => {
  const dispatch = useAppDispatch();
  const service = useSelector<RootState, string>(
    (state) => state.billingService.service
  );

  useEffect(() => {
    switch (true) {
      case servicesAutoNeedLoanIDType.map((a) => a.service).includes(service):
        dispatch(billingServiceActions.setNeedLoanIDType(true));
        break;

      default:
        dispatch(billingServiceActions.setNeedLoanIDType(false));
        break;
    }
  }, [service]);

  return {};
};

export default useSetNeedLoanIdType;
