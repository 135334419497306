import React, { useEffect, useState } from "react";
import "./SelectableItem.scss";
import Util from "../../Util/Util";
import { queryEnum } from "../../Types/global.type";
import { logoedProviderService } from "../../Containers/BillingService/services/billingService.type";

interface PT {
  label: string;
  icon?: string;
  className?: string;
}

const SelectableItem: React.FC<PT> = ({ icon, label, className }) => {
  const [isCustomizedLogo, setCustomizedLogo] = useState<boolean>(false);
  const { service } = Util.getByQuery([queryEnum.service]);
  const theme = document
    .getElementsByTagName("HTML")[0]
    .getAttribute("data-theme");

  useEffect(() => {
    setCustomizedLogo(logoedProviderService.includes(service));
  }, []);

  return (
    <div
      className={`selectable-item-container ${
        isCustomizedLogo ? "selectable-logoed-item-container" : ""
      } ${className ? className : ""}`}
    >
      {icon && (
        <div
          className={`selectable-item-icon ${icon}${
            isCustomizedLogo ? "" : `-${theme}`
          }-selectable`}
        ></div>
      )}
      <div className="selectable-item-text">{label}</div>
    </div>
  );
};

export default React.memo(SelectableItem);
