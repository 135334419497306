import { ReactNode } from "react";

export interface MainRoutesType {
  path: string;
  element: ReactNode;
}

export enum MainRoutesEnum {
  INVOICES = "/invoices",

  BILLING = "billing",
  TOPUP = "topup",
  TV_INTERNET = "tv-internet",

  PAYMENT = "payment",
  CLEAR = "clear",
}

export enum MainRoutesReverseEnum {
  PHONE = "PHONE",
  DATA = "DATA",
}
