import React from "react";
import "./PaymentRow.scss";
import AirPaymentRow from "../../Containers/Payment/components/AirPaymentRow/AirPaymentRow";
import NumberPaymentRow from "../../Containers/Payment/components/NumberPaymentRow/NumberPaymentRow";
import EduPaymentRow from "../../Containers/Payment/components/EduPaymentRow/EduPaymentRow";
import {
  eduBillDetailType,
  resAddInfoType,
} from "../../Containers/Payment/services/payment.type";
import { EnumTypeRow } from "../../Containers/SubBillingService/services/subBillingService.type";

export interface paymentRowType {
  title: string;
  text: string | undefined;
  isShown?: boolean;
  service?: string[];
  addInfo?: resAddInfoType;
  billDetail?: eduBillDetailType[];
  type?: paymentRowSpecialType;
  className?: string;
  typeRowContract?: EnumTypeRow
}

export enum paymentRowSpecialType {
  AIR = "AIR",
  NUMBER_INPUT = "NUMBER_INPUT",
  EDU = "EDU",
}

interface PaymentRowType {
  data: paymentRowType;
}

const PaymentRow: React.FC<PaymentRowType> = ({ data }) => {
  switch (data.type) {
    case paymentRowSpecialType.AIR:
      return <AirPaymentRow airData={data.addInfo?.FlightInfo} />;

    case paymentRowSpecialType.EDU:
      return <EduPaymentRow data={data} />;

    case paymentRowSpecialType.NUMBER_INPUT:
      return <NumberPaymentRow data={data} />;

    default:
      return (
        <div className={`payment-item ${data.className ? data.className : ""}`}>
          <div className="payment-title">{data.title}</div>
          <div className="payment-text">{data.text}</div>
        </div>
      );
  }
};

export default React.memo(PaymentRow);
