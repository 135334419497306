import React, { useEffect, useState } from "react";
import "./RegularServices.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../../../Redux/store";
import LayoutWithTitle from "../../../../Components/ErrorMessModal/LayoutWithTitle/LayoutWithTitle";
import LazyService from "../LazyService/LazyService";
import ServiceIcon from "../ServiceIcon/ServiceIcon";
import { serviceTypeEnum } from "../../../Auth/services/auth.type";

const numberOfServices = 8;
const lazyRegularArray = [
  ...new Array(numberOfServices).fill(true),
  ...new Array(Math.ceil(numberOfServices / 4) * 4 - numberOfServices).fill(
    null
  ),
];

const numberOfTopupServices = 2;
const lazyTopupArray = [
  ...new Array(numberOfTopupServices).fill(true),
  ...new Array(
    Math.ceil(numberOfTopupServices / 4) * 4 - numberOfTopupServices
  ).fill(null),
];

interface PT {
  items: (serviceTypeEnum | null)[];
  isTopup?: boolean;
}

const RegularServices: React.FC<PT> = ({ items, isTopup }) => {
  const [lazyArray, setLazyArray] = useState<any[]>([]);
  const loadingGetService = useSelector<RootState, boolean>(
    (state) => state.auth.loadingGetService
  );

  useEffect(() => {
    if (isTopup) setLazyArray(lazyTopupArray);
    else setLazyArray(lazyRegularArray);
  }, [isTopup]);

  return (
    <LayoutWithTitle
      title={isTopup ? "Topup" : "Hóa đơn"}
      className="services-container"
    >
      {loadingGetService &&
        lazyArray.map((a, i) =>
          a === true ? (
            <LazyService key={i} />
          ) : (
            <div key={i} style={{ flex: 1 }}></div>
          )
        )}
      {!loadingGetService &&
        items.map((a, i) => (
          <div key={i} className="services-containe-item">
            {a !== null && <ServiceIcon isTopup={isTopup} key={i} icon={a} />}
          </div>
        ))}
    </LayoutWithTitle>
  );
};

export default React.memo(RegularServices);
