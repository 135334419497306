import Api from "../../../Providers/Api";
import { getContractsResType, getInvoiceResType } from "../../Payment/services/payment.type";
import {
  VNeIDSessionResType,
  getContractsReqType,
  getInvoiceReqType,
  serviceType,
  walletStatusResType,
} from "./auth.type";

export default class authAPI {
  static getServiceListAPI = async () => {
    return await Api.get<serviceType[]>(`/v1.0/api/payment/providers`);
  };

  static getInvoiceAPI = async (data: getInvoiceReqType) => {
    return await Api.post<getInvoiceResType>(
      `/v1.0/api/billing/debt-query`,
      data
    );
  };

  static getContractsAPI = async (data: getContractsReqType) => {
    return await Api.post<getContractsResType[]>(
      `/v1.0/api/billing/contracts`,
      data
    );
  };

  static getSessionFromSSOCAPI = async (ssoCode: string) => {
    return await Api.post<VNeIDSessionResType>(`/v1.0/api/vneid/init-session`, {
      ssoCode: ssoCode,
      partnerCode: "VNEID_PARTNER",
    });
  };

  static getWalletStatusAPI = async () => {
    return await Api.get<walletStatusResType>(`/v1.0/api/vneid/info/session`);
  };
}
