import React, { useEffect, useState } from "react";
import "./Denominations.scss";
import LayoutWithTitle from "../../../../Components/ErrorMessModal/LayoutWithTitle/LayoutWithTitle";
import Util from "../../../../Util/Util";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../../Redux/store";
import { dataMethodEnum } from "../../services/topupService.type";
import { topupServiceActions } from "../../services/topupService.slice";

const Item: React.FC<{
  deno: number;
  selected: number;
  setSelected: (e: number) => void;
}> = ({ deno, selected, setSelected }) => {
  return (
    <div
      className={`denomination-item ${
        deno === selected ? "denomination-item-selected" : ""
      }`}
      onClick={() => {
        setSelected(deno);
      }}
    >
      <div className="denomination-item-amount">
        {Util.formatNumberWithFixedAndComma(deno, 0)}
      </div>
    </div>
  );
};

const Denominations = () => {
  const dispatch = useAppDispatch();
  const [currentDeno, setCurrentDeno] = useState<number>(0);
  const denos = useSelector<RootState, number[]>(
    (state) => state.topupService.denos
  );
  const denomination = useSelector<RootState, number>(
    (state) => state.topupService.denomination
  );
  const denominationDownload = useSelector<RootState, number>(
    (state) => state.topupService.denominationDownload
  );
  const dataMethod = useSelector<RootState, dataMethodEnum>(
    (state) => state.topupService.dataMethod
  );

  const setDeno = (e: number) => {
    switch (dataMethod) {
      case dataMethodEnum.TOPUP_DATA:
        dispatch(topupServiceActions.setDenomination(e));
        break;

      case dataMethodEnum.TOPUP_DOWNLOAD:
        dispatch(topupServiceActions.setDenominationDownload(e));
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    switch (dataMethod) {
      case dataMethodEnum.TOPUP_DATA:
        setCurrentDeno(denomination);
        break;

      case dataMethodEnum.TOPUP_DOWNLOAD:
        setCurrentDeno(denominationDownload);
        break;

      default:
        break;
    }
  }, [dataMethod, denomination, denominationDownload]);

  return (
    <LayoutWithTitle title="Mệnh giá " className="denominations-container">
      <div className="denominations-box">
        <div className="denominations-box-minor">
          {denos.map((a, i) => (
            <Item
              key={i}
              deno={a}
              selected={currentDeno}
              setSelected={setDeno}
            />
          ))}
        </div>
      </div>
    </LayoutWithTitle>
  );
};

export default React.memo(Denominations);
