import React from "react";
import "./PaymentItem.scss";
import "./PaymentItemClickable.scss";
import "./PaymentItemDisable.scss";

interface PT {
  disabled: boolean;
  title: string;
  icon: string;
}

const PaymentItem: React.FC<PT> = ({ disabled, title, icon }) => {
  return (
    <div
      className={`payment-item-container ${
        disabled ? "payment-item-disabled-container" : ""
      }`}
    >
      <div className="payment-item-icon-container">
        <div
          className={`payment-item-icon payment-item-${icon}-icon ${
            disabled ? `payment-item-${icon}-disabled-icon` : ""
          }`}
        ></div>
      </div>
      <div className="payment-item-title">{title}</div>
    </div>
  );
};

export default React.memo(PaymentItem);
