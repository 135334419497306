import React from "react";
import "./FixInfo.scss";
import Util from "../../../../Util/Util";
import { queryEnum } from "../../../../Types/global.type";
import PaymentRow from "../../../../Components/PaymentRow/PaymentRow";
import useRenderData from "../../../Payment/hooks/useRenderData";
import { useSelector } from "react-redux";
import { RootState } from "../../../../Redux/store";
import { serviceType } from "../../../Auth/services/auth.type";

const FixInfo = () => {
  const services = useSelector<RootState, serviceType[]>(
    (state) => state.auth.services
  );
  const { service, provider } = Util.getByQuery([
    queryEnum.service,
    queryEnum.provider,
  ]);
  const providerFull = services.find((a) => a.code === provider);
  const { infoArray } = useRenderData(providerFull ? providerFull : null);

  return (
    <>
      {infoArray
        .filter((a) => !a.isShown && !!a.text)
        .filter((a) => !a.service || a.service.includes(service))
        .map((a, i) => (
          <PaymentRow data={a} key={i} />
        ))}
    </>
  );
};

export default React.memo(FixInfo);
