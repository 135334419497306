import React from "react";
import "./ServiceIcon.scss";
import "./ServiceIconEpay.scss";
import "./ServiceIconVNeID.scss";
import { useNavigate } from "react-router-dom";
import {
  serviceTrans,
  serviceTypeEnum,
} from "../../../../Containers/Auth/services/auth.type";
import { queryEnum, themeTrans } from "../../../../Types/global.type";
import { MainRoutesEnum } from "../../../../Types/routes.type";
import { useAppDispatch } from "../../../../Redux/store";
import {
  invoicesActions,
  redirectToTrain,
} from "../../services/invoices.slice";
import { authActions } from "../../../Auth/services/auth.slice";
import { redirectToTrainResType } from "../../services/invoices.type";

interface PT {
  icon: serviceTypeEnum;
  isTopup?: boolean;
}

const ServiceIcon: React.FC<PT> = ({ icon, isTopup }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const theme = document
    .getElementsByTagName("HTML")[0]
    .getAttribute("data-theme");

  const handleRedirectToTrain = () => {
    const redirectToTrainSuccess = (data: redirectToTrainResType) => {
      window.location.href = data.kiosUrl;
    };
    const redirectToTrainError = () => {
      dispatch(authActions.setLoading(false));
    };

    dispatch(invoicesActions.setOpenMore(false));
    dispatch(authActions.setLoading(true));
    dispatch(redirectToTrain(redirectToTrainSuccess, redirectToTrainError));
  };

  return (
    <div
      className="service-icon-container"
      onClick={() => {
        switch (icon) {
          case serviceTypeEnum.TRAIN:
            handleRedirectToTrain();
            break;

          case serviceTypeEnum.MORE:
            dispatch(invoicesActions.setOpenMore(true));
            break;

          default:
            navigate(
              `/${isTopup ? MainRoutesEnum.TOPUP : MainRoutesEnum.BILLING}?${
                queryEnum.service
              }=${icon}`
            );
            break;
        }
      }}
    >
      <div
        className={`service-icon-main ${
          theme === themeTrans.EPAY_PARTNER ? "service-icon-main-epay" : ""
        }`}
      >
        <div
          className={`service-icon-icon service-icon-${icon}-${theme}`}
        ></div>
      </div>
      <div className="service-icon-text">{serviceTrans[icon]}</div>
    </div>
  );
};

export default React.memo(ServiceIcon);
