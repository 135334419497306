import { ReactElement, useEffect, useState } from "react";
import { RootState, useAppDispatch } from "../../../../../Redux/store";
import { useNavigate } from "react-router-dom";
import {
  serviceType,
  serviceTypeEnum,
} from "../../../../Auth/services/auth.type";
import { useSelector } from "react-redux";
import Util from "../../../../../Util/Util";
import { MainRoutesEnum } from "../../../../../Types/routes.type";
import ElectricProvider from "../../ElectricProvider/ElectricProvider";
import { billingServiceActions } from "../../../services/billingService.slice";
import { authActions } from "../../../../Auth/services/auth.slice";
import { queryEnum, selectOptionType } from "../../../../../Types/global.type";

const useGetProvider = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [customRender, setCustomRender] = useState<ReactElement | undefined>(
    undefined
  );
  const [initProvider, setInitProvider] = useState<selectOptionType | null>(
    null
  );

  const services = useSelector<RootState, serviceType[]>(
    (state) => state.auth.services
  );

  const getProviderCode = () => {
    const { service, provider } = Util.getByQuery([
      queryEnum.service,
      queryEnum.provider,
    ]);

    if (service) dispatch(billingServiceActions.setService(service));
    if (service === serviceTypeEnum.DIEN) setCustomRender(<ElectricProvider />);

    return service === serviceTypeEnum.DIEN ? "DIEN-VIET-NAM" : provider;
  };

  const setupProvider = () => {
    const providerCode = getProviderCode();
    if (providerCode) {
      const found = services.find((a) => a.code === providerCode);
      if (found) {
        const foundData = {
          value: found.code,
          label: found.name,
        };
        dispatch(billingServiceActions.setProvider(foundData));
        setInitProvider(foundData);
      } else {
        navigate(MainRoutesEnum.INVOICES);
        dispatch(authActions.setErrorMess("Không tìm thấy dịch vụ"));
      }
    }
  };

  useEffect(() => {
    setupProvider();
  }, []);

  return { initProvider, customRender };
};

export default useGetProvider;
