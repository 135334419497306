import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../Redux/store";
import "./Loading.scss";
import CustomModal from "../CustomModal/CustomModal";

const pcType = window.innerHeight < window.innerWidth;

const Loading = () => {
  const loading = useSelector<RootState, boolean>(
    (state) => state.auth.loading
  );

  const theme = document
    .getElementsByTagName("HTML")[0]
    .getAttribute("data-theme");

  return (
    <CustomModal
      isShowing={loading}
      className={`loading-modal-container ${
        pcType ? "loading-modal-pc" : "loading-modal-phone"
      }`}
    >
      <div className={`loading-icon loading-icon-${theme}`} />
      <div className="loading-title">Đang chờ xử lý...</div>
    </CustomModal>
  );
};

export default React.memo(Loading);
