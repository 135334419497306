import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { Provider } from "react-redux";
import { store } from "./Redux/store";
import { BrowserRouter } from "react-router-dom";
import ErrorBoundary from "./Components/ErrorBoundary/ErrorBoundary";
import Auth from "./Containers/Auth/Auth";
import "./Style/ePay.scss";
import "./Style/VNeID.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <ErrorBoundary>
    <BrowserRouter>
      <Provider store={store}>
        <Auth />
      </Provider>
    </BrowserRouter>
  </ErrorBoundary>
);
