import React, { useEffect, useState } from "react";
import "./CustomNumberInput.scss";
import { themeTrans } from "../../Types/global.type";

interface PT {
  initValue?: any;
  onChange: (e: any) => void;
  title?: string;
  placeholder?: string;
  className?: string;
  errroMess?: string;
  noNeedConvertedNumber?: boolean;
  needChangeInitValue?: boolean;
  allowClear?: boolean;
}

const CustomNumberInput: React.FC<PT> = ({
  initValue,
  onChange,
  title,
  placeholder,
  className,
  errroMess,
  noNeedConvertedNumber,
  needChangeInitValue,
  allowClear,
}) => {
  const theme = document
    .getElementsByTagName("HTML")[0]
    .getAttribute("data-theme");

  const [value, setValue] = useState<any>("");

  useEffect(() => {
    if (initValue !== undefined) onChangeValue(initValue);
  }, [initValue, needChangeInitValue]);

  const onChangeValue = (e: any) => {
    switch (true) {
      case noNeedConvertedNumber &&
        !isNaN(Number(e)) &&
        Number(e) >= 0 &&
        Number(e) <= 10 ** 15:
        onChange(e);
        setValue(e);
        break;

      case !noNeedConvertedNumber &&
        !isNaN(Number(e)) &&
        Number(e) > 0 &&
        Number(e) <= 10 ** 15:
        onChange(Math.round(e));
        setValue(
          Math.round(e)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        );
        break;

      case Number(e) === 0:
        onChange(0);
        setValue("");
        break;
    }
  };

  return (
    <>
      {title && <div className="customized-number-input-title">{title}</div>}

      <div className="customized-number-input-container">
        <input
          className={`customized-number-input-main ${
            theme === themeTrans.EPAY_PARTNER ? "epay-input" : ""
          } ${className ? className : ""}`}
          placeholder={placeholder}
          value={value}
          onChange={(e) => {
            onChangeValue(e.target.value.replace(/,/g, ""));
          }}
        />

        {allowClear && value && (
          <div className="customized-number-input-clear-icon-wrap">
            <div
              className="customized-number-input-clear-icon"
              onClick={() => {
                onChangeValue("");
              }}
            ></div>
          </div>
        )}
      </div>

      {errroMess && (
        <div className="customized-number-input-errroMess">{errroMess}</div>
      )}
    </>
  );
};

export default React.memo(CustomNumberInput);
