import { themeTrans } from "../Types/global.type";

export const specialChars = "!@#$%^&*()_+-=[]{}|<>?,./;:~`'\"\\";
export const cannotBeInName = '()[]":;<>,\\';

// export const fixedTheme = themeTrans.VNEID_PARTNER;
export const fixedTheme = themeTrans.EPAY_PARTNER;

export enum PROVIDER_ENUM {
  VDS = "vds",
  NAPAS = "napas",
  VNPTDS = "vnptds",
}

export enum ProvinceCode {
  AGG = "An Giang",
  BDG = "Bình Dương",
  BDH = "Bình Định",
  BGG = "Bắc Giang",
  BKN = "Bắc Cạn",
  BLU = "Bạc Liêu",
  BNH = "Bắc Ninh",
  BPC = "Bình Phước",
  BTE = "Bến Tre",
  BTN = "Bình Thuận",
  CBG = "Cao Bằng",
  CMU = "Cà Mau",
  CTO = "Cần Thơ",
  DBN = "Điện Biên",
  DKG = "Đắc Nông",
  DLK = "Đắc Lắc",
  DNG = "Đà Nẵng",
  DNI = "Đồng Nai",
  DTP = "Đồng Tháp",
  GLI = "Gia Lai",
  HBH = "Hòa Bình",
  HCM = "Hồ Chí Minh",
  HDG = "Hải Dương",
  HGG = "Hà Giang",
  HNI = "Hà Nội",
  HNM = "Hà Nam",
  HPG = "Hải Phòng",
  HTH = "Hà Tĩnh",
  HUE = "Thừa Thiên Huế",
  HUG = "Hậu Giang",
  HYN = "Hưng Yên",
  KGG = "Kiên Giang",
  KHA = "Khánh Hòa",
  KTM = "Kon Tum",
  LAN = "Long An",
  LCI = "Lào Cai",
  LCU = "Lai Châu",
  LDG = "Lâm Đồng",
  LSN = "Lạng Sơn",
  NAN = "Nghệ An",
  NBH = "Ninh Bình",
  NDH = "Nam Định",
  NTN = "Ninh Thuận",
  PTO = "Phú Thọ",
  PYN = "Phú Yên",
  QBH = "Quảng Bình",
  QNH = "Quảng Ninh",
  QNI = "Quảng Ngãi",
  QNM = "Quảng Nam",
  QTI = "Quảng Trị",
  SLA = "Sơn La",
  STG = "Sóc Trăng",
  TBH = "Thái Bình",
  TGG = "Tiền Giang",
  THA = "Thanh Hóa",
  TNH = "Tây Ninh",
  TNN = "Thái Nguyên",
  TQG = "Tuyên Quang",
  TVH = "Trà Vinh",
  VLG = "Vĩnh Long",
  VPC = "Vĩnh Phúc",
  VTU = "Bà Rịa - Vũng Tàu",
  YBN = "Yên Bái",
}
