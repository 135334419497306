import React, { useEffect } from "react";
import "./Payment.scss";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../Redux/store";
import useGetInvoice from "./hooks/useGetInvoice";
import usePayment from "./hooks/usePayment";
import NeedPayment from "./components/NeedPayment/NeedPayment";
import DonotNeedPayment from "./components/DonotNeedPayment/DonotNeedPayment";
import CustomDrawer from "../../Components/CustomSelect/components/CustomDrawer/CustomDrawer";
import useGetPaymentList from "./hooks/useGetPaymentList";
import RedirectToEpay from "./components/RedirectToEpay/RedirectToEpay";
import { paymentActions } from "./services/payment.slice";
import useGetBack from "./hooks/useGetBack";
import useCheckDisabledSubmit from "./hooks/useCheckDisabledSubmit";
import Buttons from "../../Components/Buttons/Buttons";

const Payment = () => {
  const dispatch = useAppDispatch();

  const loading = useSelector<RootState, boolean>(
    (state) => state.auth.loading
  );
  const disabledSubmit = useSelector<RootState, boolean>(
    (state) => state.payment.disabledSubmit
  );

  const { onBack, onBackToHomePage } = useGetBack();
  const { providerFull, isNeedPayment } = useGetInvoice(onBack);
  const { paymentList } = useGetPaymentList();
  const {
    onSubmit,
    onChangeValue,
    onClose,
    isOpenPaymentList,
    isOpen,
    setOpen,
  } = usePayment(isNeedPayment);
  useCheckDisabledSubmit();

  useEffect(() => {
    return () => {
      dispatch(paymentActions.reset());
    };
  }, []);

  return !loading ? (
    <div className="payment-container">
      {/* Phần chính */}
      {isNeedPayment ? (
        <>
          <NeedPayment provider={providerFull} />
          <Buttons
            onBack={onBack}
            onOk={onSubmit}
            disabledOk={disabledSubmit}
          />
        </>
      ) : (
        <>
          <DonotNeedPayment providerData={providerFull} />
          <Buttons onBack={onBackToHomePage} />
        </>
      )}

      {/* Danh sách các hình thức thanh toán (áp dụng cho theme không phải là ePay) */}
      <CustomDrawer
        isOpen={isOpenPaymentList}
        options={[
          ...paymentList.filter((a) => a.disabled).sort(),
          ...paymentList.filter((a) => !a.disabled).sort(),
        ]}
        placeholder={"Chọn phương thức thanh toán"}
        onClose={onClose}
        onChange={onChangeValue}
      />

      <RedirectToEpay isOpen={isOpen} setOpen={setOpen} />
    </div>
  ) : (
    <></>
  );
};

export default React.memo(Payment);
