import React, { ReactNode } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { UnauthErrorMess } from "../../Config/error.config";
import { authActions } from "../../Containers/Auth/services/auth.slice";
import { RootState, useAppDispatch } from "../../Redux/store";
import CustomButton from "../CustomButton/CustomButton";
import "./ErrorMessModal.scss";
import "./ErrorMessModalEpay.scss";
import { themeTrans } from "../../Types/global.type";
import CustomModal from "../CustomModal/CustomModal";

const pcType = window.innerHeight < window.innerWidth;

const ErrorMessModal = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isErrorModalOpen = useSelector<RootState, boolean>(
    (state) => state.auth.isErrorModalOpen
  );
  const errorMess = useSelector<RootState, ReactNode>(
    (state) => state.auth.errorMess
  );
  const theme = document
    .getElementsByTagName("HTML")[0]
    .getAttribute("data-theme");

  return (
    <CustomModal
      isShowing={isErrorModalOpen}
      className={`error-mess-modal-container error-mess-modal-container-${theme} ${
        pcType
          ? "error-mess-modal-container-pc"
          : "error-mess-modal-container-phone"
      }`}
    >
      <div className={`error-mess-back ${pcType ? "error-mess-back-pc" : ""}`}>
        <div className="error-mess-img">
          <div className="error-mess-img-sub"></div>
        </div>
        <div className="error-mess-icon"></div>
      </div>

      {theme !== themeTrans.EPAY_PARTNER && (
        <div className="error-mess-title">Thông báo</div>
      )}

      <div className="error-mess-big-modal-content">{errorMess}</div>

      <CustomButton
        className="error-mess-modal-button"
        title={theme === themeTrans.EPAY_PARTNER ? "Đồng ý" : "Đóng"}
        onClick={() => {
          dispatch(authActions.setErrorModalClose());
          if (errorMess === UnauthErrorMess) {
            dispatch({ type: "LOGOUT" });
            navigate("/");
          }
        }}
      />
    </CustomModal>
  );
};

export default React.memo(ErrorMessModal);
