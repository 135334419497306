import { redirectToTrainResType, savedInvoiceType } from "./invoices.type";
import Api from "../../../Providers/Api";

export default class invoicesAPI {
  static getSavedInvoicesAPI = async () => {
    return await Api.get<savedInvoiceType[]>(
      `/v1.0/api/payment-portal/histories`
    );
  };

  static redirectToTrainAPI = async () => {
    return await Api.get<redirectToTrainResType>(
      `/v1.0/api/billing/services/kiosk`
    );
  };
}
