import React from "react";
import useGetContracts from "../../hooks/useGetContracts";
import { useSelector } from "react-redux";
import { RootState } from "../../../../Redux/store";

import "./ViettelProvider.style.scss";
import ListContracts from "./ListContracts/ListContracts";
import {
  EnumTypeScreen,
  subbillingServiceState,
} from "../../services/subBillingService.type";
import ContractDetail from "./ContractDetail/ContractDetail";

interface IPropsViettelProvider {
  onBack: () => void;
}

const ViettelProvider = ({ onBack }: IPropsViettelProvider) => {
  useGetContracts(onBack);

  const { typeScreen, billingContracts, billingContractItem } = useSelector<
    RootState,
    subbillingServiceState
  >((state) => state.subbillingService);

  const renderViettelProvider = () => {
    if (billingContracts.length === 1) {
      return <ContractDetail onBack={onBack} />;
    } else {
      switch (typeScreen) {
        case EnumTypeScreen.DETAIL:
          return <ContractDetail onBack={onBack} />;

        case EnumTypeScreen.LIST:
          return <ListContracts />;

        default:
          return <ListContracts />;
      }
    }
  };

  return (
    <div className="Viettel-provider">
      {billingContractItem && renderViettelProvider()}
    </div>
  );
};

export default ViettelProvider;
