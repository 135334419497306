import { getContractsResType } from "../../Payment/services/payment.type";

export enum EnumTypeRow {
  NOMAL = "nomal",
  BUTTON = "button",
}
// type?: "nomal" | "button";
export enum EnumTypeScreen {
  LIST = "list",
  DETAIL = "detail",
}

export interface subbillingServiceState {
  addedAmount: string;
  isOpenAddedAmount: boolean;
  typeScreen: EnumTypeScreen;
  billingContracts: getContractsResType[];
  billingContractItem: getContractsResType | undefined;
  idCheckboxContract: string
}
