import React from "react";
import "./Buttons.scss";
import CustomButton from "../CustomButton/CustomButton";

interface PT {
  onBack?: () => void;
  onOk?: () => void;
  backTitle?: string;
  okTitle?: string;
  disabledOk?: boolean;
}

const Buttons: React.FC<PT> = ({
  onBack,
  onOk,
  backTitle,
  okTitle,
  disabledOk,
}) => {
  return (
    <div className="buttons-container">
      {onBack && (
        <CustomButton
          title={backTitle ? backTitle : "Quay lại"}
          onClick={onBack}
          className="back-button"
        />
      )}
      {onBack && onOk && <div className="buttons-break"></div>}
      {onOk && (
        <CustomButton
          title={okTitle ? okTitle : "Tiếp tục"}
          onClick={onOk}
          disabled={disabledOk}
        />
      )}
    </div>
  );
};

export default React.memo(Buttons);
