import { createSlice, Dispatch } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  invoicesState,
  redirectToTrainResType,
  savedInvoiceType,
} from "./invoices.type";
import invoicesAPI from "./invoices.api";
import Util from "../../../Util/Util";

const initialState: invoicesState = {
  refresh: false,

  savedInvoices: null,
  isOpenMore: false,
};

export const invoicesSlice = createSlice({
  name: "invoices",
  initialState,
  reducers: {
    reset: () => initialState,

    setRefresh: (state, action: PayloadAction<boolean>) => {
      state.refresh = action.payload;
    },

    setSavedInvoices: (state, action: PayloadAction<savedInvoiceType[]>) => {
      state.savedInvoices = action.payload;
    },

    setOpenMore: (state, action: PayloadAction<boolean>) => {
      state.isOpenMore = action.payload;
    },
  },
});

export const invoicesReducer = invoicesSlice.reducer;
export const invoicesActions = invoicesSlice.actions;

export const resetinvoicesState = () => (dispatch: Dispatch) => {
  dispatch(invoicesActions.reset());
  dispatch(invoicesActions.setRefresh(true));
};

export const getSavedInvoices =
  (onSuccess: (e: savedInvoiceType[]) => void, onError: () => void) =>
  async () => {
    try {
      const res = await invoicesAPI.getSavedInvoicesAPI();

      if (res.data) onSuccess(res.data);
      else {
        onError();
      }
    } catch {
      onError();
    }
  };

export const redirectToTrain =
  (onSuccess: (data: redirectToTrainResType) => void, onError: () => void) =>
  async (dispatch: Dispatch) => {
    try {
      const res = await invoicesAPI.redirectToTrainAPI();

      if (res.data) onSuccess(res.data);
      else {
        Util.showCodeMessWithModal(res, dispatch);
        onError();
      }
    } catch {
      onError();
    }
  };
