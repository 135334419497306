import Confirm from "../Containers/Confirm/Confirm";
import BillingService from "../Containers/BillingService/BillingService";
import Invoices from "../Containers/Invoices/Invoices";
import Payment from "../Containers/Payment/Payment";
import PhonePayment from "../Containers/PhonePayment/PhonePayment";
import { MainRoutesEnum, MainRoutesType } from "../Types/routes.type";
import TopupService from "../Containers/TopupService/TopupService";
import SubBillingService from "../Containers/SubBillingService/SubBillingService";

export const MainRoutes: MainRoutesType[] = [
  // Màn hình chính
  {
    path: MainRoutesEnum.INVOICES,
    element: <Invoices />,
  },

  // Dịch vụ billing
  {
    path: MainRoutesEnum.BILLING,
    element: <BillingService />,
  },
  {
    path: MainRoutesEnum.BILLING + "/" + MainRoutesEnum.TV_INTERNET,
    element: <SubBillingService />,
  },
  {
    path: MainRoutesEnum.PAYMENT,
    element: <Payment />,
  },

  // Dịch vụ TOPUP
  {
    path: MainRoutesEnum.TOPUP,
    element: <TopupService />,
  },
  {
    path: `${MainRoutesEnum.TOPUP}/${MainRoutesEnum.PAYMENT}`,
    element: <PhonePayment />,
  },

  // Xác nhận gạch nợ
  {
    path: MainRoutesEnum.CLEAR + "/:invoiceID",
    element: <Confirm />,
  },
];
