import React from "react";
import "./CustomField.scss";
import CustomInput from "../../../../Components/CustomInput/CustomInput";
import CustomSelect from "../../../../Components/CustomSelect/CustomSelect";
import { fieldEnum, fieldType } from "../../services/billingService.type";
import CustomNumberInput from "../../../../Components/CustomNumberInput/CustomNumberInput";

const CustomField: React.FC<fieldType> = (data) => {
  if (data.customRender) return data.customRender;
  else
    switch (data.type) {
      case fieldEnum.TEXT:
        return (
          <CustomInput
            initValue={data.initValue}
            onChange={data.setValue}
            title={data.title}
            placeholder={data.placeholder}
            className={`provider ${data.className}`}
            needChangeInitValue={data.needChangeInitValue}
            allowClear
            needUnaccentedVietnamese
          />
        );

      case fieldEnum.SELECT:
        return (
          <CustomSelect
            initValue={data.initValue}
            onSetValue={data.setValue}
            options={
              data.selectProp && data.selectProp.options
                ? data.selectProp.options
                : []
            }
            title={data.title}
            placeholder={data.placeholder}
            className={`provider ${data.className}`}
            showSearch={data.selectProp?.showSearch}
            needFullHeight={data.selectProp?.needFullHeight}
            disabled={data.disabled}
            needChangeInitValue={data.needChangeInitValue}
          />
        );

      case fieldEnum.NUMBER:
        return (
          <CustomNumberInput
            allowClear
            noNeedConvertedNumber
            initValue={data.initValue}
            onChange={data.setValue}
            title={data.title}
            placeholder={data.placeholder}
            className={`provider ${data.className}`}
            needChangeInitValue={data.needChangeInitValue}
          />
        );

      default:
        return <></>;
    }
};

export default React.memo(CustomField);
