import { useEffect, useState } from "react";
import Util from "../../../../../Util/Util";
import {
  INTERNET_VIETTEL_TRA_SAU,
  TRUYEN_HINH_VIETTEL,
  serviceTypeEnum,
} from "../../../../Auth/services/auth.type";
import { billingServiceActions } from "../../../services/billingService.slice";
import { RootState, useAppDispatch } from "../../../../../Redux/store";
import { useSelector } from "react-redux";
import { queryEnum, selectOptionType } from "../../../../../Types/global.type";
import { LoanIDTypeEnum } from "../../LoanIDTypeField/services/loanIDType.type";

export const titleAndPlaceholder = [
  {
    service: serviceTypeEnum.DIEN,
    title: "Mã khách hàng",
    placeholder: "Nhập mã khách hàng",
  },
  {
    service: serviceTypeEnum.NUOC,
    title: "Mã khách hàng",
    placeholder: "Nhập mã khách hàng",
  },
  {
    service: serviceTypeEnum["TRUYEN-HINH"],
    // title: "Số điện thoại",
    // placeholder: "Nhập số điện thoại",
    title: "Mã khách hàng",
    placeholder: "Nhập mã khách hàng",
  },
  {
    service: serviceTypeEnum.INTERNET,
    // title: "Số điện thoại",
    // placeholder: "Nhập số điện thoại",
    title: "Mã khách hàng",
    placeholder: "Nhập mã khách hàng",
  },
  {
    service: serviceTypeEnum["VIEN-THONG-TRA-SAU"],
    title: "Số thuê bao",
    placeholder: "Nhập số thuê bao",
  },
  {
    service: serviceTypeEnum["VIEN-THONG-CO-DINH"],
    title: "Số thuê bao",
    placeholder: "Nhập số thuê bao",
  },
  {
    service: serviceTypeEnum["VAN-TAI"],
    title: "Mã đặt chỗ",
    placeholder: "Nhập mã",
  },
  {
    service: serviceTypeEnum.EDU,
    title: "Mã học sinh / Số định danh",
    placeholder: "Nhập mã",
  },
  {
    service: serviceTypeEnum["TAI-CHINH"],
    loanIDType: LoanIDTypeEnum.CONTRACT_CODE_LOAN,
    title: "Số hợp đồng / Mã khoản vay",
    placeholder: "Nhập mã",
  },
  {
    service: serviceTypeEnum["TAI-CHINH"],
    loanIDType: LoanIDTypeEnum.CCCD,
    title: "Số CMND / CCCD",
    placeholder: "Nhập CMND / CCCD",
  },
  {
    service: serviceTypeEnum["BAO-HIEM"],
    loanIDType: LoanIDTypeEnum.CONTRACT_CODE_INS,
    title: "Số hợp đồng / Số HSYCBH",
    placeholder: "Nhập số hợp đồng / Số HSYCBH",
  },
  {
    service: serviceTypeEnum["BAO-HIEM"],
    loanIDType: LoanIDTypeEnum.CCCD,
    title: "Số CMND / CCCD",
    placeholder: "Nhập CMND / CCCD",
  },
  {
    service: TRUYEN_HINH_VIETTEL,
    title: "Số điện thoại",
    placeholder: "Nhập số điện thoại",
  },
];

const useGetClientID = () => {
  const dispatch = useAppDispatch();
  const [title, setTitle] = useState<string>("");
  const [placeholder, setPlaceholder] = useState<string>("");
  const [initClientID, setInitClientID] = useState<string>("");

  const provider = useSelector<RootState, selectOptionType | null>(
    (state) => state.billingService.provider
  );

  const service = useSelector<RootState, string>(
    (state) => state.billingService.service
  );
  const loanIDType = useSelector<RootState, selectOptionType | null>(
    (state) => state.billingService.loanIDType
  );

  useEffect(() => {
    const { clientID } = Util.getByQuery([queryEnum.clientID]);
    if (clientID) {
      dispatch(billingServiceActions.setClientID(clientID));
      setInitClientID(clientID);
    }
  }, []);

  useEffect(() => {
    const found = titleAndPlaceholder.find((a) => {
      let check = false;
      if (a.service === service) {
        if (!loanIDType) check = true;
        else {
          check = loanIDType.value === a.loanIDType;
        }
      }
      return check;
    });
    if (found) {
      setTitle(found.title);
      setPlaceholder(found.placeholder);
    }

    if (
      provider?.value === TRUYEN_HINH_VIETTEL ||
      provider?.value === INTERNET_VIETTEL_TRA_SAU
    ) {
      setTitle(titleAndPlaceholder[12].title);
      setPlaceholder(titleAndPlaceholder[12].placeholder);
    }
  }, [service, loanIDType, provider?.value]);

  return { title, placeholder, initClientID };
};

export default useGetClientID;
