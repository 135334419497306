import React, { ReactNode, useEffect, useState } from "react";
import { selectOptionType } from "../../Types/global.type";
import "./CustomSelect.scss";
import CustomDrawer from "./components/CustomDrawer/CustomDrawer";
import DrawerButton from "./components/DrawerButton/DrawerButton";

interface PT {
  value?: selectOptionType | null;
  initValue?: selectOptionType | null;
  onSetValue: (e: selectOptionType | null) => void;
  options: selectOptionType[];
  title?: string;
  placeholder?: string;
  className?: string;
  drawerClassName?: string;
  disabled?: boolean;
  showSearch?: boolean;
  noted?: ReactNode;
  doNotNeedClosable?: boolean;
  buttonPlaceHolder?: ReactNode;
  doNotNeedDropDownIcon?: boolean;
  needFullHeight?: boolean;
  needChangeInitValue?: boolean;
}

const CustomSelect: React.FC<PT> = ({
  initValue,
  value,
  onSetValue,
  options,
  title,
  placeholder,
  className,
  drawerClassName,
  disabled,
  showSearch,
  noted,
  doNotNeedClosable,
  buttonPlaceHolder,
  doNotNeedDropDownIcon,
  needFullHeight,
  needChangeInitValue,
}) => {
  const [isOpen, setOpen] = useState<boolean>(false);
  const [closeOrClear, setCloseOrClear] = useState<number>(0);
  const [isClear, setClear] = useState<boolean>(false);
  const [renderValue, setRenderValue] = useState<selectOptionType | null>(null);

  useEffect(() => {
    if (closeOrClear) {
      if (isClear) {
        onHandleChange(null);
        setClear(false);
      } else onOpen();
      setCloseOrClear(0);
    }
  }, [closeOrClear]);

  useEffect(() => {
    if (initValue !== undefined) onHandleChange(initValue);
  }, [initValue, needChangeInitValue]);

  useEffect(() => {
    if (value !== undefined) onHandleChange(value);
  }, [value]);

  const onHandleChange = (e: selectOptionType | null) => {
    onSetValue(e);
    setRenderValue(e);
    onClose();
  };

  const onClose = () => {
    setOpen(false);
  };

  const onOpen = () => {
    !disabled && setOpen(true);
  };

  return (
    <>
      {title && <div className="customized-select-title">{title}</div>}

      <DrawerButton
        disabled={disabled}
        value={renderValue}
        className={className}
        placeholder={placeholder}
        closeOrClear={closeOrClear}
        setCloseOrClear={setCloseOrClear}
        setClear={setClear}
        doNotNeedClosable={doNotNeedClosable}
        buttonPlaceHolder={buttonPlaceHolder}
        doNotNeedDropDownIcon={doNotNeedDropDownIcon}
      />

      <CustomDrawer
        isOpen={isOpen}
        options={options}
        placeholder={placeholder}
        onClose={onClose}
        onChange={onHandleChange}
        showSearch={showSearch}
        noted={noted}
        needFullHeight={needFullHeight}
        className={drawerClassName}
      />
    </>
  );
};

export default React.memo(CustomSelect);
