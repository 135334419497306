import React from "react";
import "./UnAuth.scss";

export const onGoBack = () => {
  console.log("Back to main app");
};

const UnAuth = () => {
  return (
    <div className="un-auth-container">
      <div className="un-auth-main">
        <div className="un-auth-icon" />
        <div className="un-auth-text">
          Xác thực người dùng <span>không thành công</span>
        </div>
      </div>
    </div>
  );
};

export default React.memo(UnAuth);
