import React from "react";
import "./EduPaymentBodyItem.scss";
import Util from "../../../../../../Util/Util";

interface PT {
  value: string;
  amount: number;
}

const EduPaymentBodyItem: React.FC<PT> = ({ amount, value }) => {
  return (
    <div className="edu-payment-body-item-container">
      <div className="edu-payment-body-item-label">{value}</div>
      <div className="edu-payment-body-item-value">
        {Util.formatNumberWithFixedAndComma(Number(amount), 0)}
      </div>
    </div>
  );
};

export default React.memo(EduPaymentBodyItem);
