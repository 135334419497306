import React from "react";
import "./SaveInvoiceBlock.scss";
import { savedInvoiceType } from "../../../../services/invoices.type";
import SavedInvoice from "../SavedInvoice/SavedInvoice";

interface PT {
  data: (savedInvoiceType | null)[];
}

const SaveInvoiceBlock: React.FC<PT> = ({ data }) => {
  return (
    <div className="save-invoice-block-container">
      {data.map((a, i) =>
        a ? (
          <SavedInvoice data={a} key={i} />
        ) : (
          <div className="save-invoice-null" key={i}></div>
        )
      )}
    </div>
  );
};

export default React.memo(SaveInvoiceBlock);
