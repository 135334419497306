import Api from "../../../Providers/Api";
import { createOrderResType } from "../../Payment/services/payment.type";
import {
  createTopupServiceOrderReqType,
  recentlyPhoneType,
} from "./topupService.type";

export default class topupServiceAPI {
  static createTopupServiceOrderAPI = async (
    data: createTopupServiceOrderReqType
  ) => {
    return await Api.post<createOrderResType>(
      `/v1.0/api/topup/req-payment`,
      data
    );
  };

  static getRecentlyPhoneAPI = async () => {
    return await Api.get<recentlyPhoneType[]>(`/v1.0/api/topup/histories`);
  };
}
