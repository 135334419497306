import { useEffect, useState } from "react";
import { MainRoutesReverseEnum } from "../../../Types/routes.type";
import Util from "../../../Util/Util";
import {
  dataMethodDesEnum,
  dataMethodEnum,
  phoneServiceTrans,
  serviceDes,
} from "../../TopupService/services/topupService.type";
import { queryEnum } from "../../../Types/global.type";
import { serviceTypeEnum } from "../../Auth/services/auth.type";

const useGetInfo = () => {
  const [infoArray, setInfoArray] = useState<
    {
      title: string;
      text: string;
      service: string[];
      minorService: string[];
      className?: string;
    }[]
  >([]);

  useEffect(() => {
    const { service, minorService, provider, amount, quantity, phone } =
      Util.getByQuery([
        queryEnum.service,
        queryEnum.minorService,
        queryEnum.provider,
        queryEnum.amount,
        queryEnum.quantity,
        queryEnum.phone,
      ]);

    // Tạo mảng để render data
    setInfoArray(
      [
        {
          title: "Nhà mạng",
          text: Util.convertTrans(provider, phoneServiceTrans),
          service: [serviceTypeEnum.PHONE, serviceTypeEnum.DATA],
          minorService: [
            dataMethodEnum.TOPUP_DATA,
            dataMethodEnum.TOPUP_DOWNLOAD,
          ],
        },
        {
          title: "Dịch vụ",
          text: `${Util.convertTrans(
            minorService,
            dataMethodDesEnum
          )} ${Util.convertTrans(service, serviceDes)}`,
          service: [serviceTypeEnum.PHONE, serviceTypeEnum.DATA],
          minorService: [
            dataMethodEnum.TOPUP_DATA,
            dataMethodEnum.TOPUP_DOWNLOAD,
          ],
        },
        {
          title: "Số điện thoại được nạp",
          text: phone,
          service: [serviceTypeEnum.PHONE, serviceTypeEnum.DATA],
          minorService: [dataMethodEnum.TOPUP_DATA],
        },
        {
          title: "Gói",
          text: "1GB/ ngày",
          service: [serviceTypeEnum.DATA],
          minorService: [
            dataMethodEnum.TOPUP_DATA,
            dataMethodEnum.TOPUP_DOWNLOAD,
          ],
        },
        {
          title: "Dung lượng",
          text: "1GB/ ngày",
          service: [serviceTypeEnum.DATA],
          minorService: [
            dataMethodEnum.TOPUP_DATA,
            dataMethodEnum.TOPUP_DOWNLOAD,
          ],
        },
        {
          title: "Thời gian sử dụng",
          text: "30 ngày",
          service: [serviceTypeEnum.DATA],
          minorService: [
            dataMethodEnum.TOPUP_DATA,
            dataMethodEnum.TOPUP_DOWNLOAD,
          ],
        },
        {
          title: "Mệnh giá",
          text: Util.formatNumberWithFixedAndComma(Number(amount), 0),
          service: [serviceTypeEnum.PHONE, serviceTypeEnum.DATA],
          minorService: [
            dataMethodEnum.TOPUP_DATA,
            dataMethodEnum.TOPUP_DOWNLOAD,
          ],
        },
        {
          title: "Số tiền thanh toán (VND)",
          text: Util.formatNumberWithFixedAndComma(
            minorService === dataMethodEnum.TOPUP_DATA
              ? Number(amount)
              : Number(amount) * Math.max(quantity, 1),
            0
          ),
          service: [serviceTypeEnum.PHONE, serviceTypeEnum.DATA],
          minorService: [
            dataMethodEnum.TOPUP_DATA,
            dataMethodEnum.TOPUP_DOWNLOAD,
          ],
          className: "payment-amount",
        },
      ].filter(
        (a) =>
          a.service.includes(
            Util.convertTrans(service, MainRoutesReverseEnum)
          ) &&
          a.minorService.includes(
            Util.convertTrans(minorService, dataMethodEnum)
          )
      )
    );
  }, []);

  return { infoArray };
};

export default useGetInfo;
