import React from "react";
import "./RecentlyPhone.scss";
import LayoutWithTitle from "../../../../Components/ErrorMessModal/LayoutWithTitle/LayoutWithTitle";
import { useSelector } from "react-redux";
import { RootState } from "../../../../Redux/store";
import { recentlyPhoneType } from "../../services/topupService.type";
import Slider from "react-slick";

const sliderSetting = {
  className: "center",
  dots: true,
  infinite: true,
  speed: 500,
  slidesToScroll: 1,
  rows: 1,
};

const Item: React.FC<{
  phone: recentlyPhoneType;
  selected: string;
  setPhone: (e: string) => void;
}> = ({ phone, selected, setPhone }) => {
  return (
    <div
      className={`recently-phone-item ${
        selected === phone.phoneNumber ? "recently-phone-item-selected" : ""
      }`}
      onClick={() => {
        setPhone(phone.phoneNumber);
      }}
    >
      {phone.phoneNumber}
    </div>
  );
};

interface PT {
  setPhone: (e: string) => void;
}

const RecentlyPhone: React.FC<PT> = ({ setPhone }) => {
  const phone = useSelector<RootState, string>(
    (state) => state.topupService.phone
  );
  const recentlyPhone = useSelector<RootState, recentlyPhoneType[]>(
    (state) => state.topupService.recentlyPhone
  );

  return (
    <LayoutWithTitle
      title={
        <div className="recently-phone-titles">
          <div className="recently-phone-title-main">Nạp gần đây</div>
        </div>
      }
      className="recently-phone-container"
    >
      <Slider
        {...{
          ...sliderSetting,
          slidesToShow: Math.min(2, recentlyPhone.length),
        }}
      >
        {recentlyPhone.map((a, i) => (
          <Item phone={a} key={i} selected={phone} setPhone={setPhone} />
        ))}
      </Slider>
    </LayoutWithTitle>
  );
};

export default React.memo(RecentlyPhone);
