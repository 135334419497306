import React, { ReactNode } from "react";
import "./CustomRadioItem.scss";

interface PT {
  value: string;
  name: string;
  label: ReactNode;
  onChange: (e: string) => void;
  selectedValue: string;
}

const CustomRadioItem: React.FC<PT> = ({
  value,
  name,
  label,
  onChange,
  selectedValue,
}) => {
  return (
    <div className="custom-radio-container">
      <div className="custom-radio-item">
        <input
          type="radio"
          id={value}
          name={name}
          checked={selectedValue === value}
          onChange={(e: any) => {
            onChange(e.target.id);
          }}
        />
        <label htmlFor={value} className="slider"></label>
      </div>
      <label htmlFor={value} className="value">
        {label}
      </label>
    </div>
  );
};

export default React.memo(CustomRadioItem);
