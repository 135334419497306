import React, { ReactNode, useEffect, useState } from "react";
import "./CustomButton.scss";
import { themeTrans } from "../../Types/global.type";

interface PT {
  title: ReactNode;
  onClick: () => void;
  disabled?: boolean;
  className?: string;
}

const CustomButton: React.FC<PT> = ({
  title,
  onClick,
  disabled,
  className,
}) => {
  const [style, setStyle] = useState<string>();
  const isThemeEpay =
    document.getElementsByTagName("HTML")[0].getAttribute("data-theme") ===
    themeTrans.EPAY_PARTNER;

  useEffect(() => {
    switch (true) {
      case !isThemeEpay && !disabled:
        setStyle("button-active");
        break;

      case !isThemeEpay && disabled:
        setStyle("button-disabled");
        break;

      case isThemeEpay && !disabled:
        setStyle("epay-button-active");
        break;

      case isThemeEpay && disabled:
        setStyle("epay-button-disabled");
        break;

      default:
        setStyle("");
        break;
    }
  }, [disabled, isThemeEpay]);

  return (
    <div className={`button-container ${style} ${className ? className : ""}`}>
      <button
        disabled={disabled}
        className="customized-button"
        onClick={() => {
          if (!disabled) onClick();
        }}
      >
        {title}
      </button>
    </div>
  );
};

export default CustomButton;
