import { useEffect } from "react";
import { useAppDispatch } from "../../../Redux/store";
import Util from "../../../Util/Util";
import { queryEnum } from "../../../Types/global.type";
import { subbillingServiceActions } from "../services/subBillingService.slice";

const useGetInitAddedAmount = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    const { addedAmount } = Util.getByQuery([queryEnum.addedAmount]);
    dispatch(subbillingServiceActions.setOpenAddedAmount(!!addedAmount));
    dispatch(subbillingServiceActions.setAddedAmount(addedAmount));
  }, []);
};

export default useGetInitAddedAmount;
