import React from "react";
import "./MainLayout.scss";
import { Route, Routes } from "react-router-dom";
import { MainRoutes } from "../../Routes/routes";

const MainLayout = () => {
  return (
    <Routes>
      {MainRoutes.map((a, index) => (
        <Route key={index} path={a.path} element={a.element} />
      ))}
    </Routes>
  );
};

export default React.memo(MainLayout);
