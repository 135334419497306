import React from "react";
import "./PhoneInput.scss";
import LayoutWithTitle from "../../../../Components/ErrorMessModal/LayoutWithTitle/LayoutWithTitle";
import CustomInput from "../../../../Components/CustomInput/CustomInput";
import { useSelector } from "react-redux";
import { RootState } from "../../../../Redux/store";
import PhoneServices from "../PhoneServices/PhoneServices";
import { recentlyPhoneType } from "../../services/topupService.type";
import RecentlyPhone from "../RecentlyPhone/RecentlyPhone";

interface PT {
  errorPhone: boolean;
  setErrorPhone: (e: boolean) => void;
  setPhone: (e: string) => void;
}

const PhoneInput: React.FC<PT> = ({ errorPhone, setErrorPhone, setPhone }) => {
  const phone = useSelector<RootState, string>(
    (state) => state.topupService.phone
  );
  const recentlyPhone = useSelector<RootState, recentlyPhoneType[]>(
    (state) => state.topupService.recentlyPhone
  );

  const onClear = () => {
    setErrorPhone(false);
  };

  return (
    <LayoutWithTitle
      title="Số điện thoại được nạp"
      className="phone-input-container"
    >
      <div className="phone-input-main">
        <CustomInput
          initValue={phone}
          onChange={setPhone}
          onClear={onClear}
          allowClear
          placeholder="Nhập số điện thoại"
          className={`${errorPhone ? "input-error" : ""}`}
        />
        <PhoneServices />
      </div>
      {errorPhone && phone !== "" && (
        <div className="phone-input-error">
          Số điện thoại chưa đúng định dạng
        </div>
      )}
      {recentlyPhone.length !== 0 && <RecentlyPhone setPhone={setPhone} />}
    </LayoutWithTitle>
  );
};

export default React.memo(PhoneInput);
