import React, { useMemo } from "react";
import "./AddedAmount.scss";
import CustomRadio from "../../../../Components/CustomRadio/CustomRadio";
import useGetAddedAmout from "../../hooks/useGetAddedAmout";
import { RootState, useAppDispatch } from "../../../../Redux/store";
import { useSelector } from "react-redux";
import { subbillingServiceActions } from "../../services/subBillingService.slice";
// import useRenderData from "../../hooks/useRenderData";
import { IBillDetailListViettel } from "../../../Payment/services/payment.type";
import Util from "../../../../Util/Util";
import { queryEnum } from "../../../../Types/global.type";
import {
  INTERNET_VIETTEL_TRA_SAU,
  TRUYEN_HINH_VIETTEL,
} from "../../../Auth/services/auth.type";

interface IPropsAddedAmount {
  options?: IBillDetailListViettel[];
}

const AddedAmount = ({ options }: IPropsAddedAmount) => {
  const dispatch = useAppDispatch();
  const { provider } = useMemo(() => {
    return Util.getByQuery([queryEnum.provider]);
  }, []);

  const addedAmount = useSelector<RootState, string>(
    (state) => state.subbillingService.addedAmount
  );

  const { renderData, renderDataViettel } = useGetAddedAmout({ options });
  const onChange = (e: string) => {
    dispatch(subbillingServiceActions.setAddedAmount(e));
  };

  return (
    <>
      {provider === TRUYEN_HINH_VIETTEL ||
      provider === INTERNET_VIETTEL_TRA_SAU ? (
        <>
          {renderDataViettel && (
            <CustomRadio
              selectedValue={addedAmount}
              options={renderDataViettel}
              onChange={onChange}
            />
          )}
        </>
      ) : (
        <CustomRadio
          selectedValue={addedAmount}
          options={renderData}
          onChange={onChange}
        />
      )}
    </>
  );
};

export default React.memo(AddedAmount);
