import React from "react";
import "./LazyService.scss";

const LazyService = () => {
  return (
    <div className="lazy-service-warp">
      <div className="lazy-service-container">
        <div className="lazy-service-icon"></div>
        <div className="lazy-service-text"></div>
      </div>
    </div>
  );
};

export default React.memo(LazyService);
