import React from "react";
import "./LoanIDTypeField.scss";
import CustomField from "../CustomField/CustomField";
import { RootState } from "../../../../Redux/store";
import { useSelector } from "react-redux";
import useGetLoanIDTypeList from "./hooks/useGetLoanIDTypeList";
import useGetloanIDType from "./hooks/useGetloanIDType";
import { fieldEnum } from "../../services/billingService.type";

const LoanIDTypeField = () => {
  const needLoanIDType = useSelector<RootState, boolean>(
    (state) => state.billingService.needLoanIDType
  );
  const { loanIDTypes, disabled } = useGetLoanIDTypeList();
  const { initLoanIDType, needChangeInitValue, setLoanIDType } =
    useGetloanIDType();

  return needLoanIDType ? (
    <CustomField
      type={fieldEnum.SELECT}
      title="Hình thức thanh toán"
      placeholder="Chọn hình thức thanh toán"
      setValue={setLoanIDType}
      initValue={initLoanIDType}
      selectProp={{
        showSearch: false,
        needFullHeight: false,
        options: loanIDTypes,
      }}
      disabled={disabled}
      needChangeInitValue={needChangeInitValue}
    />
  ) : (
    <></>
  );
};

export default React.memo(LoanIDTypeField);
