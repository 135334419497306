import React from "react";
import "./Conpon.scss";

const ConponItem = () => {
  return (
    <div className="mySwiperNested swiperHomeElectric">
      <div className="swiper-wrapper">
        <div className="swiper-slide">
          <div className={`coupon__wrap coupon__wrap__active`}>
            <div className="coupon__title coupon__title__active">
              <div className="wave"></div>
              <div className="text-20">ST120K</div>
              <div className="text-20">120.000</div>
              <div className="">30 ngày</div>
            </div>
            <div className="coupon__detail coupon__detail__active">
              <div className="btn">Chi tiết</div>
              <ul>
                <li className="coupon__detail__text">5 GB / ngày</li>
                <li className="coupon__detail__text">
                  Tặng 30 phút gọi nội mạng
                </li>
                <li className="coupon__detail__text">15 phút gọi ngoại mạng</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConponItem;
