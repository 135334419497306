import { ReactNode } from "react";

export interface resWithPaginationType<T> {
  hits: T[];
  dataExtra?: any;
  pagination: {
    totalRows: number;
    totalPages: number;
  };
}

export interface selectOptionType {
  value: any;
  label: any;
  render?: ReactNode;
  selectedRender?: ReactNode;
  onClick?: () => void;
  disabled?: boolean;
  needPhone?: boolean;
}

export enum themeTrans {
  VNEID_PARTNER = "VNeID",
  EPAY_PARTNER = "ePay",
}

export enum queryEnum {
  service = "service",
  minorService = "minorService",
  provider = "provider",
  province = "province",
  loanIDType = "loanIDType",
  clientID = "clientID",
  addedAmount = "addedAmount",
  accountId = "accountId",

  phone = "phone",
  amount = "amount",
  quantity = "quantity",
}

export const doNotNeedPaymentInvoiceErrorCode = 1501;
