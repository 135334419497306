import React from "react";
import "./CustomSwitch.scss";

interface PT {
  isCheck: boolean;
  onChange: (e: boolean) => void;
}

const CustomSwitch: React.FC<PT> = ({ isCheck, onChange }) => {
  return (
    <label className="custom-switch">
      <input
        type="checkbox"
        checked={isCheck}
        onChange={() => {
          onChange(!isCheck);
        }}
      />
      <span className="slider"></span>
    </label>
  );
};

export default React.memo(CustomSwitch);
