import React, { useEffect, useState } from "react";
import "./EduPaymentRow.scss";
import { paymentRowType } from "../../../../Components/PaymentRow/PaymentRow";
import { Collapse } from "antd";
import EduPaymentTitle from "./components/EduPaymentTitle/EduPaymentTitle";
import EduPaymentFooter from "./components/EduPaymentFooter/EduPaymentFooter";
import EduPaymentBody from "./components/EduPaymentBody/EduPaymentBody";
import { useSelector } from "react-redux";
import { RootState } from "../../../../Redux/store";
import { eduBillDetailType } from "../../services/payment.type";
import Util from "../../../../Util/Util";

interface PT {
  data: paymentRowType;
}

const EduPaymentRow: React.FC<PT> = ({ data }) => {
  const [totalOverall, setTotalOverall] = useState<number>(0);
  const eduTotal = useSelector<RootState, eduBillDetailType[]>(
    (state) => state.payment.eduTotal
  );

  useEffect(() => {
    setTotalOverall(
      eduTotal.reduce((a, b) => {
        return (
          a +
          b.details.reduce((c, d) => {
            return c + Number(d.d_amount);
          }, 0)
        );
      }, 0)
    );
  }, [eduTotal]);

  return (
    <div className="edu-payment-row-container">
      {/* Header tính total */}
      <div className="edu-payment-row-title">
        <div className="edu-payment-row-title-text">
          Số tiền thanh toán (VND)
        </div>
        <div className="edu-payment-row-title-value">
          {Util.formatNumberWithFixedAndComma(totalOverall, 0)}
        </div>
      </div>

      {/* Danh sách các items */}
      {data.billDetail &&
        data.billDetail.map((a, i) => (
          <div className="edu-payment-item" key={i}>
            {/* Chi tiết item */}
            <Collapse
              items={[
                {
                  label: <EduPaymentTitle data={a} />,
                  children: <EduPaymentBody options={a.details} />,
                },
              ]}
              expandIconPosition="end"
              ghost
              destroyInactivePanel
            />

            {/* Tổng của item */}
            <EduPaymentFooter options={a.details} />
          </div>
        ))}
    </div>
  );
};

export default React.memo(EduPaymentRow);
