import { useEffect } from "react";
import Util from "../../../Util/Util";
import { IBillDetailListViettel } from "../../Payment/services/payment.type";
import { useAppDispatch } from "../../../Redux/store";
import { subbillingServiceActions } from "../services/subBillingService.slice";

interface IPropsUseGetAddedAmout {
  options: IBillDetailListViettel[] | undefined;
}

const useGetAddedAmout = ({ options }: IPropsUseGetAddedAmout) => {
  const dispatch = useAppDispatch();

  const fakeData = [
    {
      value: "6m",
      duration: 6,
      amount: 132000,
    },
    {
      value: "12m",
      duration: 12,
      amount: 264000,
    },
    {
      value: "18m",
      duration: 18,
      amount: 369000,
    },
  ];

  const renderData = fakeData.map((a) => {
    return {
      value: a.value,
      label: (
        <div className="added-amount">
          <div className="added-amount-value">
            {a.duration} tháng <span>(tặng 1 tháng)</span>
          </div>
          <div className="added-amount-amount">
            {Util.formatNumberWithFixedAndComma(a.amount, 0)}đ
          </div>
        </div>
      ),
    };
  });

  const renderDataViettel =
    options &&
    options.map((a) => {
      return {
        value: a.totalMoney,
        label: (
          <div className="added-amount">
            <div className="added-amount-value">
              {a.totalMonth} tháng{" "}
              <span>(tặng {a.promotionTypeDTO.numMonth100} tháng)</span>
            </div>
            <div className="added-amount-amount">
              {Util.formatNumberWithFixedAndComma(a.totalMoney, 0)}đ
            </div>
          </div>
        ),
      };
    });

  useEffect(() => {
    if (options) {
      dispatch(subbillingServiceActions.setAddedAmount(options[0].totalMoney));
    }
  }, [options]);

  return { renderData, renderDataViettel };
};

export default useGetAddedAmout;
