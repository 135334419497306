import { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../Redux/store";
import { serviceTypeEnum } from "../../Auth/services/auth.type";
import { eduBillDetailType } from "../services/payment.type";
import Util from "../../../Util/Util";
import { queryEnum } from "../../../Types/global.type";
import { paymentActions } from "../services/payment.slice";

const useCheckDisabledSubmit = () => {
  const dispatch = useAppDispatch();

  const eduTotal = useSelector<RootState, eduBillDetailType[]>(
    (state) => state.payment.eduTotal
  );
  const amount = useSelector<RootState, number>(
    (state) => state.payment.amount
  );
  const amountError = useSelector<RootState, boolean>(
    (state) => state.payment.amountError
  );

  useEffect(() => {
    const { service } = Util.getByQuery([queryEnum.service]);

    switch (service) {
      case serviceTypeEnum.EDU:
        dispatch(paymentActions.setDisabledSubmit(eduTotal.length === 0));
        break;

      case serviceTypeEnum["BAO-HIEM"]:
        dispatch(paymentActions.setDisabledSubmit(amountError));
        break;

      case serviceTypeEnum["TAI-CHINH"]:
        dispatch(paymentActions.setDisabledSubmit(amountError));

        break;

      default:
        dispatch(paymentActions.setDisabledSubmit(false));
        break;
    }
  }, [eduTotal, amount, amountError]);

  return {};
};

export default useCheckDisabledSubmit;
