import React from "react";
import "./ProviderField.scss";
import CustomField from "../CustomField/CustomField";
import { fieldEnum } from "../../services/billingService.type";
import useGetProviderList from "./hooks/useGetProviderList";
import { selectOptionType } from "../../../../Types/global.type";
import { billingServiceActions } from "../../services/billingService.slice";
import { useAppDispatch } from "../../../../Redux/store";
import useGetProvider from "./hooks/useGetProvider";

const ProviderField = () => {
  const dispatch = useAppDispatch();
  const { initProvider, customRender } = useGetProvider();
  const { providers } = useGetProviderList();

  const onSetProvider = (e: selectOptionType | null) => {
    dispatch(billingServiceActions.setProvider(e));
  };

  return (
    <CustomField
      type={fieldEnum.SELECT}
      title="Nhà cung cấp dịch vụ"
      placeholder="Chọn nhà cung cấp"
      setValue={onSetProvider}
      initValue={initProvider}
      customRender={customRender}
      selectProp={{
        showSearch: providers.length >= 5,
        needFullHeight: providers.length >= 5,
        options: providers,
      }}
    />
  );
};

export default React.memo(ProviderField);
