import React from "react";
import "./RedirectToEpay.scss";
import CustomButton from "../../../../Components/CustomButton/CustomButton";
import { openVNeIDRegisEpay } from "./vneidPostMessage";
import CustomModal from "../../../../Components/CustomModal/CustomModal";

const pcType = window.innerHeight < window.innerWidth;

interface PT {
  isOpen: boolean;
  setOpen: (e: boolean) => void;
}

const RedirectToEpay: React.FC<PT> = ({ isOpen, setOpen }) => {
  const theme = document
    .getElementsByTagName("HTML")[0]
    .getAttribute("data-theme");

  return isOpen ? (
    <CustomModal
      isShowing={isOpen}
      className={`redirect-epay-modal-container redirect-epay-modal-container-${theme} ${
        pcType
          ? "redirect-epay-modal-container-pc"
          : "redirect-epay-modal-container-phone"
      }`}
    >
      <div className="redirect-epay-title">Thông báo</div>

      <div className="redirect-epay-big-modal-content">
        Vui lòng sử dụng ví Epay để thanh toán hóa đơn một cách nhanh chóng và
        thuận thiện
      </div>

      <div className="redirect-epay-buttons">
        <CustomButton
          className="redirect-epay-modal-button redirect-epay-button"
          title={"Đóng"}
          onClick={() => {
            setOpen(false);
          }}
        />
        <CustomButton
          className="redirect-epay-modal-button"
          title={"Sử dụng ví Epay"}
          onClick={() => {
            openVNeIDRegisEpay();
          }}
        />
      </div>
    </CustomModal>
  ) : (
    <></>
  );
};

export default React.memo(RedirectToEpay);
