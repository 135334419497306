import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../Redux/store";
import {
  clearStatusEnum,
  clearStatusResType,
  invoiceDataType,
} from "../../services/confirm.type";
import Util from "../../../../Util/Util";
import moment from "moment";
import "./PhoneClear.scss";

const PhoneClear = () => {
  const clearData = useSelector<RootState, clearStatusResType | null>(
    (state) => state.confirm.clearData
  );
  const data = useSelector<RootState, invoiceDataType | null>(
    (state) => state.confirm.data
  );

  return clearData?.transStatus === clearStatusEnum.SUCCESS ||
    clearData?.transStatus === clearStatusEnum.FAIL ? (
    <div className="confirm-second-phone">
      <div className="confirm-second-phone-text">
        Quý khách đã nạp
        {clearData?.transStatus === clearStatusEnum.FAIL && " không"} thành công
        {clearData?.transStatus !== clearStatusEnum.FAIL && (
          <span>
            {" "}
            {Util.formatNumberWithFixedAndComma(
              Number(data?.totalAmount),
              0
            )}{" "}
            VND
          </span>
        )}
        <div className="confirm-second-phone-text">
          cho thuê bao<span> {clearData?.billingCode}</span>
        </div>
        <div className="confirm-second-phone-text">
          vào lúc{" "}
          {moment(new Date(clearData.transDate)).format("HH:mm:ss DD-MM-YYYY")}
        </div>
      </div>
    </div>
  ) : clearData ? (
    clearData.transStatus === clearStatusEnum.PENDING ? (
      <div className="confirm-second-phone-text">
        Kết quả nạp tiền điện thoại sẽ được gửi đến quý khách sau quá trình đối
        soát
      </div>
    ) : (
      <></>
    )
  ) : (
    <></>
  );
};

export default React.memo(PhoneClear);
