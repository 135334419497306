import { useEffect } from "react";
import { useAppDispatch } from "../../../Redux/store";
import { queryEnum } from "../../../Types/global.type";
import Util from "../../../Util/Util";
import { authActions, getContracts } from "../../Auth/services/auth.slice";
import { getContractsResType } from "../../Payment/services/payment.type";
import { subbillingServiceActions } from "../services/subBillingService.slice";

const useGetContracts = (onBack: () => void) => {
  const dispatch = useAppDispatch();
  const { clientID } = Util.getByQuery([queryEnum.clientID]);

  

  // [BILLING] - Lấy danh sách hợp đòng theo số điện thoại
  const getBillingContracts = () => {
    const getBillingContractsSuccess = (data: getContractsResType[]) => {
      dispatch(subbillingServiceActions.setBillingContracts(data));
      dispatch(subbillingServiceActions.setBillingContractItem(data[0]));
      dispatch(authActions.setLoading(false));
    };

    const getBillingContractsError = (res?: any) => {
      dispatch(authActions.setLoading(false));

      if (res) Util.showCodeMessWithModal(res, dispatch);
      onBack();
    };

    // getContracts
    dispatch(authActions.setLoading(true));
    dispatch(
      getContracts(
        { phoneNumber: clientID },
        getBillingContractsSuccess,
        getBillingContractsError
      )
    );
  };

  useEffect(() => {
    getBillingContracts();
  }, []);

  // return { getBillingContracts };
};

export default useGetContracts;
