import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  EnumTypeScreen,
  subbillingServiceState,
} from "./subBillingService.type";
import { getContractsResType } from "../../Payment/services/payment.type";

const initialState: subbillingServiceState = {
  addedAmount: "",
  billingContracts: [],
  typeScreen: EnumTypeScreen.LIST,
  isOpenAddedAmount: false,
  billingContractItem: undefined,
  idCheckboxContract: "", // phục vụ cho checkbox chọn chi tiết contract
};

export const subbillingServiceSlice = createSlice({
  name: "subbillingService",
  initialState,
  reducers: {
    reset: () => initialState,

    setAddedAmount: (state, action: PayloadAction<string>) => {
      state.addedAmount = action.payload;
    },

    setOpenAddedAmount: (state, action: PayloadAction<boolean>) => {
      state.isOpenAddedAmount = action.payload;
    },

    setBillingContracts: (
      state,
      action: PayloadAction<getContractsResType[]>
    ) => {
      state.billingContracts = action.payload;
    },

    setTypeScreen: (state, action: PayloadAction<EnumTypeScreen>) => {
      state.typeScreen = action.payload;
    },

    setIdCheckboxContract: (state, action: PayloadAction<string>) => {
      state.idCheckboxContract = action.payload;
    },

    setBillingContractItem: (
      state,
      action: PayloadAction<getContractsResType | undefined>
    ) => {
      state.billingContractItem = action.payload;
    },
  },
});

export const subbillingServiceReducer = subbillingServiceSlice.reducer;
export const subbillingServiceActions = subbillingServiceSlice.actions;
