import { useSelector } from "react-redux";
import { RootState } from "../../../../../Redux/store";
import {
  serviceType,
  serviceTypeEnum,
} from "../../../../Auth/services/auth.type";
import Util from "../../../../../Util/Util";
import SelectableItem from "../../../../../Components/SelectableItem/SelectableItem";
import { queryEnum } from "../../../../../Types/global.type";
import { logoedProviderService } from "../../../services/billingService.type";

const useGetProviderList = () => {
  const IntlCollator = new Intl.Collator("vi");
  const { service } = Util.getByQuery([queryEnum.service]);
  const serviceList = useSelector<RootState, serviceType[]>(
    (state) => state.auth.services
  );

  const providers = serviceList
    // Lọc những nhà cung cấp theo serviceType
    .filter((a) => a.serviceType === service)
    // Render thành những thành phần clickable trong list
    .map((a) => {
      return {
        value: a.code,
        label: a.name,
        render: (
          <div
            className={service === serviceTypeEnum.EDU ? "provider-item" : ""}
          >
            <SelectableItem
              label={a.name}
              icon={logoedProviderService.includes(service) ? a.code : service}
            />
          </div>
        ),
      };
    })
    // Sắp xếp theo abc tiếng Việt
    .sort((a, b) => IntlCollator.compare(a.label, b.label));

  return { providers };
};

export default useGetProviderList;
