import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../../../Redux/store";
import { useEffect, useState } from "react";
import { queryEnum, selectOptionType } from "../../../../../Types/global.type";
import { billingServiceActions } from "../../../services/billingService.slice";
import Util from "../../../../../Util/Util";
import { MainRoutesEnum } from "../../../../../Types/routes.type";
import { ProvinceCode } from "../../../../../Config/global.config";
import { authActions } from "../../../../Auth/services/auth.slice";

export const providerNeedProvince = [
  "TRUYEN-HINH-MYTV",
  "INTERNET-VNPT",
  "ADSL-NAM-SAI-GON",
  "DIEN-THOAI-CO-DINH-SPT",
  "DIEN-THOAI-CO-DINH-VIETTEL",
  "DIEN-THOAI-CO-DINH-VNPT",
  "HOME-PHONE-NAM-SAI-GON",
];

const useGetProvince = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [initProvince, setInitProvince] = useState<selectOptionType | null>(
    null
  );

  const setProvince = (e: selectOptionType | null) => {
    dispatch(billingServiceActions.setProvince(e));
  };

  const setupProvince = () => {
    const { province } = Util.getByQuery([queryEnum.province]);
    if (province) {
      const found = Object.keys(ProvinceCode).find((a) => a === province);

      if (found) {
        const foundData = {
          value: province,
          label: Util.convertTrans(found, ProvinceCode),
        };
        setProvince(foundData);
        setInitProvince(foundData);
      } else {
        navigate(MainRoutesEnum.INVOICES);
        dispatch(authActions.setErrorMess("Không tìm thấy tỉnh thành"));
      }
    }
  };

  useEffect(() => {
    setupProvince();
  }, []);

  return { initProvince, setProvince };
};

export default useGetProvince;
