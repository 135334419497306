import React, { useEffect, useState } from "react";
import "./AirPaymentRow.scss";
import Util from "../../../../Util/Util";
import { resFlightAddInfoType } from "../../services/payment.type";

interface PT {
  airData?: resFlightAddInfoType;
}

enum airportCode {
  UIH = "Quy Nhơn",
  HAN = "Hà Nội",
}

const AirPaymentRow: React.FC<PT> = ({ airData }) => {
  const [dest, setDest] = useState<(string | null)[]>([]);

  useEffect(() => {
    if (airData && airData.dest) setDest(airData.dest.split("-"));
  }, [airData]);

  return (
    <div className="air-payment-container">
      <div className="air-payment-row air-payment-code">
        <div>{Util.convertTrans(dest[0], airportCode)}</div>
        <div>{Util.convertTrans(dest[1], airportCode)}</div>
      </div>

      <div className="air-payment-row air-payment-city">
        <div>{dest[0]}</div>
        <div className="air-payment-icon">.</div>
        <div>{dest[1]}</div>
      </div>

      <div className="air-payment-row air-payment-time">
        <div>{airData && airData.flightDate}</div>
        <div>{airData && airData.flightDate}</div>
      </div>
    </div>
  );
};

export default React.memo(AirPaymentRow);
