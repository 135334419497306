import React from "react";
import "./Quantity.scss";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../../Redux/store";
import { topupServiceActions } from "../../services/topupService.slice";

const Quantity = () => {
  const dispatch = useAppDispatch();
  const quantity = useSelector<RootState, number>(
    (state) => state.topupService.quantity
  );
  return (
    <div className="quantity-container">
      <div className="quantity-text">Số lượng</div>
      <div className="quantity-input">
        <div
          className={`quantity-button quantity-button-minus ${
            quantity > 1 ? "" : "quantity-disabled-minus"
          }`}
          onClick={() => {
            if (quantity > 1)
              dispatch(topupServiceActions.setQuantity(quantity - 1));
          }}
        ></div>
        <div className="quantity-value">{quantity}</div>
        <div
          className={`quantity-button quantity-button-add ${
            quantity < 5 ? "" : "quantity-disabled-add"
          }`}
          onClick={() => {
            if (quantity < 5)
              dispatch(topupServiceActions.setQuantity(quantity + 1));
          }}
        ></div>
      </div>
    </div>
  );
};

export default React.memo(Quantity);
