import { memo, useState } from "react";
import "./PhonePayment.scss";
import CustomDrawer from "../../Components/CustomSelect/components/CustomDrawer/CustomDrawer";
import useSubmit from "./hooks/useSubmit";
import useGetPaymentList from "../Payment/hooks/useGetPaymentList";
import RedirectToEpay from "../Payment/components/RedirectToEpay/RedirectToEpay";
import PaymentRow from "../../Components/PaymentRow/PaymentRow";
import { themeTrans } from "../../Types/global.type";
import useGetInfo from "./hooks/useGetInfo";
import Buttons from "../../Components/Buttons/Buttons";

const PhonePayment = () => {
  const [isOpen, setOpen] = useState<boolean>(false);
  const { paymentList } = useGetPaymentList();

  const theme = document
    .getElementsByTagName("HTML")[0]
    .getAttribute("data-theme");

  const {
    onSubmit,
    onClose,
    onChangeValue,
    onBack,
    isOpenPaymentList,
    setOpenPaymentList,
  } = useSubmit(setOpen);
  const { infoArray } = useGetInfo();

  const handleSubmit = () => {
    theme === themeTrans.EPAY_PARTNER ? onSubmit() : setOpenPaymentList(true);
  };

  return (
    <div className="payment-container">
      <div className="payment-main">
        {infoArray.map((a, i) => (
          <PaymentRow key={i} data={a} />
        ))}
      </div>

      <Buttons onBack={onBack} onOk={handleSubmit} />

      <CustomDrawer
        isOpen={isOpenPaymentList}
        options={[
          ...paymentList.filter((a) => a.disabled).sort(),
          ...paymentList.filter((a) => !a.disabled).sort(),
        ]}
        placeholder={"Chọn phương thức thanh toán"}
        onClose={onClose}
        onChange={onChangeValue}
      />

      <RedirectToEpay isOpen={isOpen} setOpen={setOpen} />
    </div>
  );
};

export default memo(PhonePayment);
