import React from "react";
import "./ServiceItem.scss";

interface PT {
  label: string;
}

const ServiceItem: React.FC<PT> = ({ label }) => {
  return (
    <div className="service-item-container">
      <div className={`service-item-icon service-item-icon-${label}`}></div>
      <div className="service-item-label">{label}</div>
    </div>
  );
};

export default React.memo(ServiceItem);
