import React, { useEffect, useState } from "react";
import "./ItemsList.scss";
import { selectOptionType } from "../../../../../Types/global.type";
import Util from "../../../../../Util/Util";

interface PT {
  options: selectOptionType[];
  onChange: (e: selectOptionType) => void;
  search: string;
}

const ItemsList: React.FC<PT> = ({ options, onChange, search }) => {
  const [renderList, setRenderList] = useState<selectOptionType[]>([]);

  useEffect(() => {
    setRenderList(
      options.filter((a) => {
        const searchText = Util.convertToUnaccentedVietnamese(
          search.toLocaleLowerCase()
        );
        const convertedLabel = Util.convertToUnaccentedVietnamese(
          a.label.toLocaleLowerCase()
        );
        return convertedLabel.includes(searchText);
      })
    );
  }, [search, options]);

  return (
    <div className="customized-select-drawer-items">
      {renderList.map((a) => {
        return (
          <div
            key={a.value}
            onClick={() => {
              if (!a.disabled) {
                onChange(a);
              }
            }}
          >
            {a.render ? a.render : a.label}
          </div>
        );
      })}
    </div>
  );
};

export default React.memo(ItemsList);
