import React from "react";
import "./Auth.scss";
import useAuthorize from "./hooks/useAuthorize";
import { useSelector } from "react-redux";
import { RootState } from "../../Redux/store";
import ErrorMessModal from "../../Components/ErrorMessModal/ErrorMessModal";
import Loading from "../../Components/Loading/Loading";
import UnAuth from "../UnAuth/UnAuth";
import MainLayout from "../MainLayout/MainLayout";

const Auth = () => {
  const isAuth = useSelector<RootState, boolean>((state) => state.auth.isAuth);

  const theme = document
    .getElementsByTagName("HTML")[0]
    .getAttribute("data-theme");

  useAuthorize();

  return (
    <div className={`auth-container auth-${theme}`}>
      {isAuth ? <MainLayout /> : <UnAuth />}
      <ErrorMessModal />
      <Loading />
    </div>
  );
};

export default React.memo(Auth);
