import { RootState, useAppDispatch } from "../../../Redux/store";
import { confirmActions, getClearStatus } from "../services/confirm.slice";
import { useNavigate, useParams } from "react-router-dom";
import { clearStatusEnum, clearStatusResType } from "../services/confirm.type";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useCountdown from "ahooks/lib/useCountDown";
import { MainRoutesEnum } from "../../../Types/routes.type";
import { authActions } from "../../Auth/services/auth.slice";
import { errorCodeList } from "../../../Config/error.config";

const countdownTime = 10;
const limitCount = 3;

const useGetClearStatus = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const [targetDate, setTargetDate] = useState<number>();
  const [countCheckClear, setCountCheckClear] = useState<number>(0);
  const [needCheckClear, setNeedCheckClear] = useState<boolean>(true);

  const clearData = useSelector<RootState, clearStatusResType | null>(
    (state) => state.confirm.clearData
  );

  useEffect(() => {
    if (needCheckClear && countCheckClear < limitCount && !clearData) {
      setNeedCheckClear(false);
      setCountCheckClear(countCheckClear + 1);
      checkClearStatus();
      setTargetDate(Date.now() + countdownTime * 1000);
    }
  }, [needCheckClear]);

  useCountdown({
    targetDate,
    onEnd: () => {
      setNeedCheckClear(true);
    },
  });

  const checkClearStatus = () => {
    const getClearStatusSuccess = (e: clearStatusResType) => {
      if (e.transStatus === clearStatusEnum.INIT) {
        if (countCheckClear >= limitCount - 1) {
          dispatch(
            confirmActions.setClearData({
              ...e,
              transStatus: clearStatusEnum.PENDING,
            })
          );
        }
      } else dispatch(confirmActions.setClearData(e));

      if (e.responseCode) {
        const errorCodeArray = Object.keys(errorCodeList);
        const errorMessArray = Object.values(errorCodeList);
        const found = errorCodeArray.find(
          (a) => a === e.responseCode?.toString()
        );
        if (found)
          dispatch(
            confirmActions.setClearErrorMess(
              errorMessArray[errorCodeArray.indexOf(found)]
            )
          );
      }
    };

    const getClearStatusError = () => {
      dispatch(authActions.setLoading(false));
      navigate(MainRoutesEnum.INVOICES);
    };

    if (params && params.invoiceID) {
      dispatch(authActions.setLoading(true));
      dispatch(
        getClearStatus(
          params.invoiceID,
          getClearStatusSuccess,
          getClearStatusError
        )
      );
    } else navigate(MainRoutesEnum.INVOICES);
  };

  return {};
};

export default useGetClearStatus;
