import React, { useEffect, useState } from "react";
import "./EduPaymentTitle.scss";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../../../../Redux/store";
import { eduBillDetailType } from "../../../../services/payment.type";
import { paymentActions } from "../../../../services/payment.slice";

interface TitlePT {
  data: eduBillDetailType;
}

const EduPaymentTitle: React.FC<TitlePT> = ({ data }) => {
  const dispatch = useAppDispatch();
  const [checkStatus, setCheckStatus] = useState<string>("uncheck");
  const theme = document
    .getElementsByTagName("HTML")[0]
    .getAttribute("data-theme");

  const eduTotal = useSelector<RootState, eduBillDetailType[]>(
    (state) => state.payment.eduTotal
  );

  useEffect(() => {
    if (eduTotal.map((a) => a.description).includes(data.description))
      setCheckStatus(theme ? theme : "uncheck");
    else setCheckStatus("uncheck");
  }, [eduTotal]);

  const onChange = (e: any) => {
    e.stopPropagation();
    if (eduTotal.map((a) => a.description).includes(data.description))
      dispatch(
        paymentActions.setEduTotal(
          eduTotal.filter((a) => a.description !== data.description)
        )
      );
    else dispatch(paymentActions.setEduTotal([...eduTotal, data]));
  };

  return (
    <div className="edu-payment-title-container">
      <div style={{ display: "flex", alignItems: "center" }}>
        <div
          className={`edu-payment-title-checkbox edu-payment-title-checkbox-${checkStatus}`}
          onClick={onChange}
        ></div>
        <div className="edu-payment-title-title">{data.description}</div>
      </div>
      <div className="edu-payment-title-more">Chi tiết</div>
    </div>
  );
};

export default React.memo(EduPaymentTitle);
