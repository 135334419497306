import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import { selectOptionType } from "../../../Types/global.type";
import { billingServiceState } from "./billingService.type";

const initialState: billingServiceState = {
  service: "",
  clientID: "",
  provider: null,
  needProvince: false,
  province: null,
  needLoanIDType: false,
  loanIDType: null,
};

export const billingServiceSlice = createSlice({
  name: "billingService",
  initialState,
  reducers: {
    reset: () => initialState,

    setService: (state, action: PayloadAction<string>) => {
      state.service = action.payload;
    },

    setClientID: (state, action: PayloadAction<string>) => {
      state.clientID = action.payload;
    },

    setProvider: (state, action: PayloadAction<selectOptionType | null>) => {
      state.provider = action.payload;
    },

    setNeedProvince: (state, action: PayloadAction<boolean>) => {
      state.needProvince = action.payload;
    },

    setProvince: (state, action: PayloadAction<selectOptionType | null>) => {
      state.province = action.payload;
    },

    setNeedLoanIDType: (state, action: PayloadAction<boolean>) => {
      state.needLoanIDType = action.payload;
    },

    setLoanIDType: (state, action: PayloadAction<selectOptionType | null>) => {
      state.loanIDType = action.payload;
    },
  },
});

export const billingServiceReducer = billingServiceSlice.reducer;
export const billingServiceActions = billingServiceSlice.actions;

export const resetbillingServiceState = () => (dispatch: Dispatch) => {
  dispatch(billingServiceActions.reset());
};
