import { useNavigate } from "react-router-dom";
import { MainRoutesEnum } from "../../../Types/routes.type";
import { queryEnum, selectOptionType } from "../../../Types/global.type";
import { useEffect, useState } from "react";
import { RootState, useAppDispatch } from "../../../Redux/store";
import { billingServiceActions } from "../services/billingService.slice";
import { providerNeedProvince } from "../components/ProvinceField/hooks/useGetProvince";
import { useSelector } from "react-redux";
import Util from "../../../Util/Util";
import {
  LoanIDTypeEnum,
  servicesAutoNeedLoanIDType,
} from "../components/LoanIDTypeField/services/loanIDType.type";
import {
  INTERNET_VIETTEL_TRA_SAU,
  TRUYEN_HINH_VIETTEL,
} from "../../Auth/services/auth.type";

const useSubmit = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [disabledSubmit, setDisabledSubmit] = useState<boolean>(true);
  const [serviceType, setServiceType] = useState<string>("");

  const provider = useSelector<RootState, selectOptionType | null>(
    (state) => state.billingService.provider
  );
  const needLoanIDType = useSelector<RootState, boolean>(
    (state) => state.billingService.needLoanIDType
  );
  const loanIDType = useSelector<RootState, selectOptionType | null>(
    (state) => state.billingService.loanIDType
  );
  const province = useSelector<RootState, selectOptionType | null>(
    (state) => state.billingService.province
  );
  const clientID = useSelector<RootState, string>(
    (state) => state.billingService.clientID
  );

  // Check disable submit button
  useEffect(() => {
    let isError = false;

    if (!clientID) isError = true;
    if (!provider) isError = true;
    else {
      if (providerNeedProvince.includes(provider.value) && !province)
        isError = true;
    }

    const { service } = Util.getByQuery([queryEnum.service]);
    if (service) {
      setServiceType(service);
      if (
        servicesAutoNeedLoanIDType.map((a) => a.service).includes(service) &&
        !loanIDType
      )
        isError = true;
    }

    setDisabledSubmit(isError);
  }, [clientID, provider, province, loanIDType]);

  const onSubmitData = () => {
    const serviceTypePath = `?${queryEnum.service}=${encodeURIComponent(
      serviceType
    )}`;
    let providerPath = "";
    let clientIDPath = "";
    let provincePath = "";
    let loanIDTypePath = "";

    if (provider) {
      providerPath = `&${queryEnum.provider}=${encodeURIComponent(
        provider.value
      )}`;
      if (clientID)
        clientIDPath = `&${queryEnum.clientID}=${encodeURIComponent(clientID)}`;
      if (province && providerNeedProvince.includes(provider.value))
        provincePath = `&${queryEnum.province}=${encodeURIComponent(
          province.value
        )}`;
    }

    if (needLoanIDType) {
      let convertedValue = "";

      switch (true) {
        case provider?.value === "THU-HO-LOTTE" &&
          loanIDType?.value === LoanIDTypeEnum.CCCD:
          convertedValue = LoanIDTypeEnum.CONTRACT_CODE_LOAN;
          break;

        case provider?.value === "THU-HO-LOTTE" &&
          (loanIDType?.value === LoanIDTypeEnum.CONTRACT_CODE_INS ||
            loanIDType?.value === LoanIDTypeEnum.CONTRACT_CODE_LOAN):
          convertedValue = LoanIDTypeEnum.CCCD;
          break;

        default:
          convertedValue = loanIDType?.value;
          break;
      }

      loanIDTypePath = `&${queryEnum.loanIDType}=${encodeURIComponent(
        convertedValue
      )}`;
    }

    let mainPath = "";
    if (
      provider?.value === TRUYEN_HINH_VIETTEL ||
      provider?.value === INTERNET_VIETTEL_TRA_SAU
    ) {
      mainPath += MainRoutesEnum.BILLING + "/" + MainRoutesEnum.TV_INTERNET;
    } else {
      mainPath += MainRoutesEnum.PAYMENT;
    }

    dispatch(billingServiceActions.reset());
    navigate(
      `/${mainPath}${serviceTypePath}${providerPath}${clientIDPath}${provincePath}${loanIDTypePath}`
    );
  };

  const onBack = () => {
    dispatch(billingServiceActions.reset());
    setTimeout(() => {
      navigate(MainRoutesEnum.INVOICES);
    }, 30);
  };

  return {
    onSubmitData,
    onBack,
    disabledSubmit,
  };
};

export default useSubmit;
