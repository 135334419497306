import React from "react";
import "./NeedPayment.scss";
import {
  INTERNET_VIETTEL_TRA_SAU,
  TRUYEN_HINH_VIETTEL,
  serviceType,
} from "../../../../Containers/Auth/services/auth.type";
import {
  hideAmountServices,
  hideAmountText,
} from "../../services/payment.type";
import PaymentRow from "../../../../Components/PaymentRow/PaymentRow";
import useRenderData from "../../hooks/useRenderData";

interface PT {
  provider: serviceType | null;
}

const NeedPayment: React.FC<PT> = ({ provider }) => {
  const { infoArray, infoArrayViettel } = useRenderData(provider);

  return (
    <div className="need-payment-container">
      {/* ================== other provider ================ */}
      {provider?.code !== INTERNET_VIETTEL_TRA_SAU &&
        provider?.code !== TRUYEN_HINH_VIETTEL &&
        infoArray
          .filter((a) => !a.isShown && !!a.text)
          .filter(
            (a) =>
              !a.service ||
              a.service.includes(provider ? provider.serviceType : "")
          )
          .map((a, i) => <PaymentRow data={a} key={i} />)}

      {provider?.code === INTERNET_VIETTEL_TRA_SAU && (
        <>
          {infoArrayViettel
            .filter((e) => !e.isShown)
            .map((a, i) => (
              <PaymentRow data={a} key={i} />
            ))}
        </>
      )}

      {/* ================== Viettel provider ================ */}
      {provider?.code === TRUYEN_HINH_VIETTEL && (
        <>
          {infoArrayViettel
            .filter((e) => !e.isShown)
            .map((a, i) => (
              <PaymentRow data={a} key={i} />
            ))}
        </>
      )}

      {provider && hideAmountServices.includes(provider.code) && (
        <div className="need-payment-warning">{hideAmountText}</div>
      )}
    </div>
  );
};

export default React.memo(NeedPayment);
