import React, { ReactNode } from "react";
import "./LayoutWithTitle.scss";

interface PT {
  title: ReactNode;
  children: ReactNode;
  className?: string;
  onBack?: () => void;
}

const LayoutWithTitle: React.FC<PT> = ({
  title,
  children,
  className,
  onBack,
}) => {
  return (
    <div
      className={`layout-with-title-container ${
        onBack ? "" : "layout-with-title-container-noBack"
      } ${className ? className : ""}`}
    >
      <div className="layout-with-title-title">
        {onBack && (
          <div className="layout-with-title-back-icon" onClick={onBack}></div>
        )}
        {title}
        {onBack && <div style={{ width: "20px" }}></div>}
      </div>
      <div className="layout-with-title-main">{children}</div>
    </div>
  );
};

export default React.memo(LayoutWithTitle);
