import React, { useEffect } from "react";
import "./Confirm.scss";
import ConfirmFirst from "./components/ConfirmFirst/ConfirmFirst";
import ConfirmSecond from "./components/ConfirmSecond/ConfirmSecond";
import { useNavigate } from "react-router-dom";
import useGetPaymentStatus from "./hooks/useGetPaymentStatus";
import { RootState, useAppDispatch } from "../../Redux/store";
import { confirmActions } from "./services/confirm.slice";
import { useSelector } from "react-redux";
import { clearStatusEnum, clearStatusResType } from "./services/confirm.type";
import useGetClearStatus from "./hooks/useGetClearStatus";
import { MainRoutesEnum } from "../../Types/routes.type";
import Buttons from "../../Components/Buttons/Buttons";

const Confirm = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useGetPaymentStatus();
  useGetClearStatus();
  const clearData = useSelector<RootState, clearStatusResType | null>(
    (state) => state.confirm.clearData
  );

  useEffect(() => {
    return () => {
      dispatch(confirmActions.reset());
    };
  }, []);

  return (
    <div className="confirm-container">
      <div className="confirm-container-main">
        <ConfirmFirst />
        <ConfirmSecond />
      </div>
      {clearData && clearData.transStatus !== clearStatusEnum.INIT && (
        <Buttons
          okTitle="Thêm giao dịch"
          onOk={() => {
            navigate(MainRoutesEnum.INVOICES);
          }}
        />
      )}
    </div>
  );
};

export default React.memo(Confirm);
