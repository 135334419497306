import { useEffect, useState } from "react";
import { RootState, useAppDispatch } from "../../../Redux/store";
import { useSelector } from "react-redux";
import Util from "../../../Util/Util";
import {
  INTERNET_VIETTEL_TRA_SAU,
  TRUYEN_HINH_VIETTEL,
  // INTERNET_VIETTEL_TRA_SAU,
  // TRUYEN_HINH_VIETTEL,
  serviceType,
} from "../../../Containers/Auth/services/auth.type";
import { savedInvoiceType } from "../../../Containers/Invoices/services/invoices.type";
import {
  authActions,
  getInvoice,
} from "../../../Containers/Auth/services/auth.slice";
import {
  doNotNeedPaymentInvoiceErrorCode,
  queryEnum,
} from "../../../Types/global.type";
import { invoicesActions } from "../../../Containers/Invoices/services/invoices.slice";
import {
  getContractsResType,
  getInvoiceResType,
} from "../services/payment.type";
import {
  LoanIDTypeCode,
  ViettelIDTypeCode,
} from "../../BillingService/components/LoanIDTypeField/services/loanIDType.type";
import { paymentActions } from "../services/payment.slice";

const useGetInvoice = (onBack: () => void) => {
  const dispatch = useAppDispatch();
  const { province, loanIDType, provider, clientID, accountId } =
    Util.getByQuery([
      queryEnum.province,
      queryEnum.loanIDType,
      queryEnum.provider,
      queryEnum.clientID,
      queryEnum.accountId,
    ]);

  const [isNeedPayment, setNeedPayment] = useState<boolean>(true);
  const [providerFull, setProviderFull] = useState<serviceType | null>(null);

  const serviceList = useSelector<RootState, serviceType[]>(
    (state) => state.auth.services
  );
  const savedInvoices = useSelector<RootState, savedInvoiceType[] | null>(
    (state) => state.invoices.savedInvoices
  );

  const billingContractItem = useSelector<
    RootState,
    getContractsResType | undefined
  >((state) => state.subbillingService.billingContractItem);

  // Set provider
  useEffect(() => {
    // Set provider
    const decodedProvider = provider;
    if (decodedProvider) {
      const found = serviceList.find((a) => a.code === decodedProvider);
      if (found) {
        // Get invoice info
        if (clientID) getInvoiceInfo(found, provider);
        setProviderFull(found);
      } else {
        onBack();
        dispatch(authActions.setErrorMess("Không tìm thấy dịch vụ"));
      }
    }
  }, [serviceList]);

  // Save to saved invoices
  const saveInvoice = (
    provider: serviceType,
    invoice: getInvoiceResType,
    reqData: any
  ) => {
    if (savedInvoices !== null && provider && invoice) {
      const { province, loanIDType } = Util.getByQuery([
        queryEnum.province,
        queryEnum.loanIDType,
      ]);

      const existSavedInvoice = savedInvoices.find(
        (a) =>
          a.billingCode === invoice.billingCode &&
          a.serviceCode === invoice.serviceCode &&
          a.provinceCode === (province ? province : null) &&
          a.typeBillingCode ===
            (loanIDType
              ? Util.convertTrans(loanIDType, LoanIDTypeCode)
              : provider.code === TRUYEN_HINH_VIETTEL ||
                provider.code === INTERNET_VIETTEL_TRA_SAU
              ? Util.convertTrans(provider.code, ViettelIDTypeCode)
              : null)
      );

      if (!existSavedInvoice) {
        const saveData = {
          serviceCode: "",
          serviceName: provider.name,
          billingCode: "",

          fullName: invoice.customerName,
          provinceCode: province ? province : null,
          typeBillingCode: loanIDType
            ? loanIDType
            : provider.code === TRUYEN_HINH_VIETTEL ||
              provider.code === INTERNET_VIETTEL_TRA_SAU
            ? Util.convertTrans(provider.code, ViettelIDTypeCode)
            : null,
        };

        dispatch(
          invoicesActions.setSavedInvoices([
            {
              ...saveData,
              ...reqData,
            },
            ...savedInvoices.slice(0, 9),
          ])
        );
      } else {
        dispatch(
          invoicesActions.setSavedInvoices([
            existSavedInvoice,
            ...savedInvoices.filter(
              (a) =>
                !(
                  a.billingCode === invoice.billingCode &&
                  a.serviceCode === invoice.serviceCode &&
                  a.provinceCode === (province ? province : null) &&
                  a.typeBillingCode ===
                    (loanIDType
                      ? Util.convertTrans(loanIDType, LoanIDTypeCode)
                      : provider.code === TRUYEN_HINH_VIETTEL ||
                        provider.code === INTERNET_VIETTEL_TRA_SAU
                      ? Util.convertTrans(provider.code, ViettelIDTypeCode)
                      : null)
                )
            ),
          ])
        );
      }
    }
  };

  // Get invoice info
  const getInvoiceInfo = (provider: serviceType, providerQuery: string) => {
    let reqData: any = {
      serviceCode: provider.code,
      billingCode: clientID,
    };

    if (
      (billingContractItem && providerQuery === TRUYEN_HINH_VIETTEL) ||
      providerQuery === INTERNET_VIETTEL_TRA_SAU
    ) {
      reqData = { ...reqData, accountId: billingContractItem?.accountId + "" };
    } else if (accountId) {
      reqData = { ...reqData, accountId };
    }

    if (province) reqData = { ...reqData, provinceCode: province };
    if (loanIDType)
      reqData = {
        ...reqData,
        typeBillingCode: Util.convertTrans(loanIDType, LoanIDTypeCode),
      };

    const getInvoiceSuccess = (data: getInvoiceResType) => {
      dispatch(paymentActions.setInvoice(data));
      saveInvoice(provider, data, reqData);
      dispatch(authActions.setLoading(false));
    };

    const getInvoiceError = (res?: any) => {
      dispatch(authActions.setLoading(false));

      if (res.errorCode === doNotNeedPaymentInvoiceErrorCode) {
        setNeedPayment(false);
      } else {
        if (res) Util.showCodeMessWithModal(res, dispatch);
        onBack();
      }
    };

    dispatch(authActions.setLoading(true));
    dispatch(getInvoice(reqData, getInvoiceSuccess, getInvoiceError));
  };

  return { providerFull, isNeedPayment };
};

export default useGetInvoice;
