import { Collapse } from "antd";
import React from "react";
import AddedAmount from "../../AddedAmount/AddedAmount";
import CustomSwitch from "../../../../../Components/CustomSwitch/CustomSwitch";
import { RootState, useAppDispatch } from "../../../../../Redux/store";
import { useSelector } from "react-redux";
import { subbillingServiceActions } from "../../../services/subBillingService.slice";
import { IBillDetailViettel } from "../../../../Payment/services/payment.type";

interface IPropsCollapseViettel {
  data?: IBillDetailViettel;
}

const CollapseViettel = ({ data }: IPropsCollapseViettel) => {
  const dispatch = useAppDispatch();
  const isOpenAddedAmount = useSelector<RootState, boolean>(
    (state) => state.subbillingService.isOpenAddedAmount
  );

  const onClickSwitch = (event: any) => {
    event.stopPropagation();
  };

  const setOpen = (e: boolean) => {
    dispatch(subbillingServiceActions.setOpenAddedAmount(e));
  };

  return (
    <Collapse
      activeKey={isOpenAddedAmount ? ["0"] : []}
      items={[
        {
          showArrow: false,
          label: (
            <div className="sub-billing-service-switch">
              <div className="sub-billing-service-text">Đóng cước trước</div>
              <div onClick={onClickSwitch}>
                <CustomSwitch isCheck={isOpenAddedAmount} onChange={setOpen} />
              </div>
            </div>
          ),
          children: <AddedAmount options={data?.list} />,
        },
      ]}
      onChange={(e) => {
        setOpen(e.length > 0);
      }}
      expandIconPosition="end"
      ghost
      destroyInactivePanel
    />
  );
};

export default CollapseViettel;
