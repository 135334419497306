import {
  errorCodeList,
  UnauthErrorCode,
  UnauthErrorMess,
} from "../Config/error.config";
import { cannotBeInName, specialChars } from "../Config/global.config";
import { authActions } from "../Containers/Auth/services/auth.slice";
import { TeleComServiceTypeTV } from "../Containers/Payment/services/payment.type";
import { headPhoneNumber } from "../Containers/TopupService/services/topupService.type";

export default class Util {
  static convertTeleComServiceType = (teleComServiceType: number) => {
    return TeleComServiceTypeTV[
      teleComServiceType as keyof typeof TeleComServiceTypeTV
    ]
      ? TeleComServiceTypeTV[
          teleComServiceType as keyof typeof TeleComServiceTypeTV
        ]
      : "Internet & Truyền hình";
  };

  static showCodeMessWithModal = (res: any, dispatch: any) => {
    if (res.errCode === UnauthErrorCode) {
      dispatch({ type: "LOGOUT" });
      dispatch(authActions.setErrorMess(UnauthErrorMess));
    }

    const errorCodeArray = Object.keys(errorCodeList);
    const errorMessArray = Object.values(errorCodeList);
    const found = errorCodeArray.find((a) => a === res.errorCode.toString());
    if (found)
      dispatch(
        authActions.setErrorMess(errorMessArray[errorCodeArray.indexOf(found)])
      );
    else dispatch(authActions.setErrorMess(res.errorDesc));
  };

  static checkValidatedAmount = (amountRaw: number) => {
    const amount = amountRaw.toString();
    const headArrRaw = headPhoneNumber.map((a) => a.heads);
    let headArr: string[] = [];
    headArrRaw.forEach((a) => (headArr = [...headArr, ...a]));

    let checkChars = false;
    let noSpace = false;

    if (amount.split("").some((a) => isNaN(Number(a)))) checkChars = false;
    else checkChars = true;

    if (amount.split("").some((a) => a === " ")) noSpace = false;
    else noSpace = true;

    return checkChars && noSpace;
  };

  static checkValidatedCode = (code: string) => {
    let noSpecialChars = false;
    let noSpace = false;

    if (code.split("").some((a) => specialChars.includes(a)))
      noSpecialChars = false;
    else noSpecialChars = true;

    if (code.split("").some((a) => a === " ")) noSpace = false;
    else noSpace = true;

    return noSpecialChars && noSpace;
  };

  static checkValidatedPhone = (phone: string) => {
    const headArrRaw = headPhoneNumber.map((a) => a.heads);
    let headArr: string[] = [];
    headArrRaw.forEach((a) => (headArr = [...headArr, ...a]));

    let checkLength = false;
    let checkChars = false;
    let checkFirstChar = false;
    let noSpace = false;
    let checkHead = false;

    if (phone.length === 10) checkLength = true;
    else checkLength = false;

    if (phone.split("").some((a) => isNaN(Number(a)))) checkChars = false;
    else checkChars = true;

    if (phone.split("")[0] !== "0") checkFirstChar = false;
    else checkFirstChar = true;

    if (phone.split("").some((a) => a === " ")) noSpace = false;
    else noSpace = true;

    if (headArr.some((a) => a.includes(phone.slice(0, 3)))) checkHead = true;
    else checkHead = false;

    return checkLength && checkChars && checkFirstChar && noSpace && checkHead;
  };

  static checkValidatedByProvider = (phone: string) => {
    let checkLength = false;
    let checkChars = false;
    let checkFirstChar = false;
    let noSpace = false;
    let checkHead = false;

    if (phone.length === 10) checkLength = true;
    else checkLength = false;

    if (phone.split("").some((a) => isNaN(Number(a)))) checkChars = false;
    else checkChars = true;

    if (phone.split("")[0] !== "0") checkFirstChar = false;
    else checkFirstChar = true;

    if (phone.split("").some((a) => a === " ")) noSpace = false;
    else noSpace = true;

    if (
      headPhoneNumber.some((a) =>
        a.heads.some((a) => a.includes(phone.slice(0, 3)))
      )
    )
      checkHead = true;
    else checkHead = false;

    return checkLength && checkChars && checkFirstChar && noSpace && checkHead;
  };

  static checkValidatedLinePhone = (phone: string) => {
    let checkLength = false;
    let checkChars = false;

    if (phone.length !== 0) checkLength = true;
    else checkLength = false;

    if (phone.split("").some((a) => isNaN(Number(a)))) checkChars = false;
    else checkChars = true;

    return checkChars && checkLength;
  };

  static checkValidatedName = (name: string) => {
    let checkChars = false;

    if (
      name
        .split("")
        .filter((a) => a !== " " && a !== "'")
        .some((a) => specialChars.includes(a) || !isNaN(Number(a)))
    )
      checkChars = false;
    else checkChars = true;

    return checkChars;
  };

  static checkValidatedCCCD = (cccd: string) => {
    let noSpecialChars = false;
    let checkChars = false;
    let noSpace = false;
    let checkLenght = false;

    if (cccd.split("").some((a) => specialChars.includes(a)))
      noSpecialChars = false;
    else noSpecialChars = true;

    if (cccd.split("").some((a) => isNaN(Number(a)))) checkChars = false;
    else checkChars = true;

    if (cccd.split("").some((a) => a === " ")) noSpace = false;
    else noSpace = true;

    if (cccd.length !== 9 && cccd.length !== 12) checkLenght = false;
    else checkLenght = true;

    return noSpecialChars && noSpace && checkChars && checkLenght;
  };

  static checkValidatedEmail = (email: string) => {
    let haveOneAtSymbol = false;
    let haveTwoMoreDomains = false;
    let checkMainEmail = false;
    let checkDomains = false;
    let noSpace = false;

    if (email.includes("@")) {
      if (email.slice(email.indexOf("@") + 1).includes("@"))
        haveOneAtSymbol = false;
      else haveOneAtSymbol = true;
    } else haveOneAtSymbol = false;

    if (email.includes("@")) {
      if (email.slice(email.indexOf("@") + 1).includes("."))
        haveTwoMoreDomains = true;
      else haveTwoMoreDomains = false;
    }

    if (email.includes("@")) {
      const mainEmail = email.slice(0, email.indexOf("@"));
      if (mainEmail.split("").some((a) => cannotBeInName.includes(a)))
        checkMainEmail = false;
      else checkMainEmail = true;
    }

    if (email.includes("@")) {
      const domains = email.slice(email.indexOf("@") + 1).split(".");

      if (
        domains.some((a) => a.split("").some((b) => specialChars.includes(b)))
      )
        checkDomains = false;
      else if (domains[domains.length - 1].length < 2) checkDomains = false;
      else checkDomains = true;
    }

    if (email.split("").some((a) => a === " ")) noSpace = false;
    else noSpace = true;

    return (
      haveOneAtSymbol &&
      haveTwoMoreDomains &&
      checkMainEmail &&
      checkDomains &&
      noSpace
    );
  };

  static checkValidatedPass = (pass: string) => {
    let length = false;
    let upper = false;
    let lower = false;
    let number = false;
    let noSpace = false;

    if (pass.length >= 8 && pass.length <= 16) length = true;

    if (
      pass.split("").some((a) => {
        return a >= "A" && a <= "Z";
      })
    )
      upper = true;

    if (
      pass.split("").some((a) => {
        return a >= "a" && a <= "z";
      })
    )
      lower = true;

    if (
      pass.split("").some((a) => {
        return a >= "0" && a <= "9";
      })
    )
      number = true;

    if (pass.split("").some((a) => a === " ")) noSpace = false;
    else noSpace = true;

    return length && upper && lower && number && noSpace;
  };

  static formatNumberWithFixedAndComma(money: any, fixed: number) {
    if (typeof money === "number" || !isNaN(Number(money))) {
      const fixedMoney = money.toString();
      const beforeDot =
        fixedMoney.indexOf(".") !== -1
          ? fixedMoney.slice(0, fixedMoney.indexOf("."))
          : fixedMoney;
      let afterDot =
        fixedMoney.indexOf(".") !== -1
          ? fixedMoney.slice(fixedMoney.indexOf(".") + 1)
          : "";

      if (afterDot.length < fixed)
        while (afterDot.length < fixed) afterDot += "0";
      else if (afterDot.length > fixed) {
        if (Number(afterDot[fixed]) >= 5)
          afterDot = (Number(afterDot.slice(0, fixed)) + 1).toString();
        else afterDot = afterDot.slice(0, fixed);
      }

      const finalBeforeDot = `${beforeDot.replace(
        /(\d)(?=(\d{3})+(?!\d))/g,
        "$1,"
      )}`;

      if (fixed === 0) return finalBeforeDot;
      return `${finalBeforeDot}.${afterDot}`;
    } else {
      return "0";
    }
  }

  static convertTrans(data: string | null | undefined, trans: object) {
    if (!data) return "";
    const keys = Object.keys(trans).map((a) => a.toString());
    const values = Object.values(trans).map((a) => a.toString());
    const index = keys.indexOf(data);
    if (index !== -1) return values[index];
    return "";
  }

  static convertTransBackVer(data: string | null | undefined, trans: object) {
    if (!data) return "";
    const keys = Object.keys(trans).map((a) => a.toString());
    const values = Object.values(trans).map((a) => a.toString());
    const index = values.indexOf(data);
    if (index !== -1) return keys[index];
    return "";
  }

  static convertToUnaccentedVietnamese(text: string) {
    const charMap = [
      {
        value: "ùúủũụưừứửữự",
        trans: "u",
      },
      {
        value: "èéẻẽẹêềếểễệ",
        trans: "e",
      },
      {
        value: "òóỏõọôồốổỗộơờớởỡợ",
        trans: "o",
      },
      {
        value: "àáảãạâầấẩẫậăằắẳẵặ",
        trans: "a",
      },
      {
        value: "ìíỉĩị",
        trans: "i",
      },
      {
        value: "ỳýỷỹỵ",
        trans: "y",
      },
      {
        value: "dđ",
        trans: "d",
      },
    ];

    const result = [];
    if (text)
      for (let i = 0; i < text.length; i++) {
        const charRaw = text[i];
        const char = charRaw.toLocaleLowerCase();
        const found = charMap.find((a) => a.value.includes(char));
        if (found)
          switch (true) {
            case char === charRaw:
              result.push(found.trans);
              break;

            default:
              result.push(found.trans.toLocaleUpperCase());
          }
        else result.push(charRaw);
      }

    return result.join("");
  }

  static getUniqueData(data: any[], key: string) {
    return data.filter(
      (obj, index, self) => index === self.findIndex((o) => o[key] === obj[key])
    );
  }

  static getByQuery(query: string[]) {
    const params = new URLSearchParams(window.location.search);
    const ans: any = {};
    query.forEach((a) => {
      const found = params.get(a);
      if (found) ans[a] = decodeURIComponent(found);
    });
    return ans;
  }
}
