import React from "react";
import "./CustomDrawerHeader.scss";

interface PT {
  placeholder: string | undefined;
  close: () => void;
}

const CustomDrawerHeader: React.FC<PT> = ({ placeholder, close }) => {
  return (
    <div className="customized-select-drawer-title-container">
      <div className="customized-select-drawer-title">
        {placeholder}
        {/* {window.innerHeight} */}
      </div>
      <div className="customized-select-drawer-title-close" onClick={close} />
    </div>
  );
};

export default React.memo(CustomDrawerHeader);
