import React, { ReactNode } from "react";
import "./DrawerButton.scss";
import { selectOptionType } from "../../../../Types/global.type";

interface PT {
  disabled?: boolean;
  value: selectOptionType | null;
  className?: string;
  placeholder?: string;
  closeOrClear: number;
  setCloseOrClear: (e: number) => void;
  setClear: (e: boolean) => void;
  doNotNeedClosable?: boolean;
  buttonPlaceHolder?: ReactNode;
  doNotNeedDropDownIcon?: boolean;
}

const DrawerButton: React.FC<PT> = ({
  disabled,
  value,
  className,
  placeholder,
  closeOrClear,
  setCloseOrClear,
  setClear,
  doNotNeedClosable,
  buttonPlaceHolder,
  doNotNeedDropDownIcon,
}) => {
  return (
    <div
      className={`customized-select ${className ? className : ""} ${
        disabled ? "customized-select-disabled" : ""
      }`}
      onClick={() => {
        setCloseOrClear(closeOrClear + 1);
      }}
    >
      {/* Text */}
      <div className="customized-select-main">
        <div className="customized-select-text">
          {value && (value.selectedRender ? value.selectedRender : value.label)}
        </div>
        {!value && placeholder && (
          <div className="customized-select-text customized-select-placeholder">
            {buttonPlaceHolder ? buttonPlaceHolder : placeholder}
          </div>
        )}
      </div>

      {/* Icons */}
      <div className="customized-select-icons">
        {value && !doNotNeedClosable && (
          <div
            className="customized-select-icon customized-select-clear-icon"
            onClick={() => {
              if (!disabled) {
                setClear(true);
                setCloseOrClear(closeOrClear + 1);
              }
            }}
          ></div>
        )}
        {!doNotNeedDropDownIcon && (
          <div className="customized-select-icon customized-select-dropdown-icon"></div>
        )}
      </div>
    </div>
  );
};

export default React.memo(DrawerButton);
