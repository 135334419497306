import React from "react";
import "./CustomRadio.scss";
import CustomRadioItem from "./CustomRadioItem/CustomRadioItem";
import { selectOptionType } from "../../Types/global.type";

interface PT {
  options: selectOptionType[];
  onChange: (e: string) => void;
  selectedValue: string;
}

const CustomRadio: React.FC<PT> = ({ options, onChange, selectedValue }) => {
  return (
    <>
      {options.map((a, i) => (
        <CustomRadioItem
          key={i}
          name="added-amount"
          {...a}
          onChange={onChange}
          selectedValue={selectedValue}
        />
      ))}
    </>
  );
};

export default React.memo(CustomRadio);
