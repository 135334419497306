import React, { Fragment } from "react";
import CheckBox from "../CheckBox/CheckBox";
import useRenderData from "../../../hooks/useRenderData";
import { getContractsResType } from "../../../../Payment/services/payment.type";
import RowItem from "../RowItem/RowItem";
import { RootState, useAppDispatch } from "../../../../../Redux/store";
import { subbillingServiceActions } from "../../../services/subBillingService.slice";
import { useSelector } from "react-redux";
import { subbillingServiceState } from "../../../services/subBillingService.type";

interface IPropsItemContract {
  data: getContractsResType;
}

const ItemContract = ({ data }: IPropsItemContract) => {
  const dispatch = useAppDispatch();
  const { idCheckboxContract } = useSelector<RootState, subbillingServiceState>(
    (state) => state.subbillingService
  );

  const { handleSetFieldItemContract } = useRenderData();

  const handleChangeCheckBox = () => {
    dispatch(
      subbillingServiceActions.setIdCheckboxContract(`${data.accountNo}`)
    );
    dispatch(subbillingServiceActions.setBillingContractItem(data));
  };

  return (
    <div
      className="item-contract"
      key={data.accountId}
      onClick={() => handleChangeCheckBox()}
    >
      <div className="">
        <CheckBox
          checked={idCheckboxContract === `${data?.accountNo}`}
          // setValue={() => {
          //   handleChangeCheckBox();
          // }}
        />
        <span className="contract-code-text">
          Mã hợp đồng: {data.accountId}
        </span>
      </div>

      <div className="contract-code-text font-400 text-uppercase">
        {data.custName}
      </div>
      {handleSetFieldItemContract(data).map((e, i) => (
        <Fragment key={e.title}>
          <div
            className={
              handleSetFieldItemContract(data).length - 1 !== i
                ? ""
                : "border-t"
            }
          ></div>
          <RowItem
            text={e.text ?? ""}
            title={e.title}
            key={i}
            clasName={
              handleSetFieldItemContract(data).length - 1 === i
                ? "text-16px no-underline font-600 text-red"
                : "text-16px no-underline "
            }
          />
        </Fragment>
      ))}
    </div>
  );
};

export default ItemContract;
