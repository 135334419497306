import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../Redux/store";
import {
  clearStatusEnum,
  clearStatusResType,
  invoiceDataType,
} from "../../services/confirm.type";
import moment from "moment";
import "./Clear.scss";
import {
  serviceTrans,
  serviceType,
  serviceTypeEnum,
} from "../../../Auth/services/auth.type";
import Util from "../../../../Util/Util";
import { titleAndPlaceholder } from "../../../BillingService/components/ClientIDField/hooks/useGetClientID";

const Clear = () => {
  const [service, setService] = useState<string>("");
  const [customerType, setCustomerType] = useState<string>("");
  const clearData = useSelector<RootState, clearStatusResType | null>(
    (state) => state.confirm.clearData
  );
  const data = useSelector<RootState, invoiceDataType | null>(
    (state) => state.confirm.data
  );
  const services = useSelector<RootState, serviceType[]>(
    (state) => state.auth.services
  );

  useEffect(() => {
    if (services.length !== 0 && data) {
      const found = services.find((a) => a.code === data?.serviceCode);
      if (found) {
        setService(Util.convertTrans(found.serviceType, serviceTrans));

        switch (true) {
          case [
            serviceTypeEnum["VIEN-THONG-CO-DINH"],
            serviceTypeEnum["VIEN-THONG-TRA-SAU"],
          ].includes(found.serviceType):
            setCustomerType("số thuê bao");
            break;

          default:
            const foundService = titleAndPlaceholder.find(
              (a) => a.service === found.serviceType
            );
            const titleTCService = foundService ? foundService.title : "";
            setCustomerType(titleTCService);
            break;
        }
      }
    }
  }, [services, data]);

  return clearData ? (
    [clearStatusEnum.SUCCESS, clearStatusEnum.FAIL].includes(
      clearData.transStatus
    ) ? (
      <div className="confirm-second-clear-text">
        Quý khách đã gạch nợ
        {clearData?.transStatus === clearStatusEnum.FAIL && " không"} thành công
        dịch vụ
        <span> {service}</span> cho {customerType}
        <span> {clearData?.billingCode}</span> vào lúc{" "}
        {moment(new Date(clearData.transDate)).format("HH:mm:ss DD-MM-YYYY")}
      </div>
    ) : clearData.transStatus === clearStatusEnum.PENDING ? (
      <div className="confirm-second-clear-text">
        Kết quả gạch nợ sẽ được gửi đến quý khách sau quá trình đối soát.
      </div>
    ) : (
      <></>
    )
  ) : (
    <></>
  );
};

export default React.memo(Clear);
