import { ReactElement } from "react";
import { selectOptionType } from "../../../Types/global.type";
import { serviceTypeEnum } from "../../Auth/services/auth.type";

export interface billingServiceState {
  service: string;
  clientID: string;
  provider: selectOptionType | null;
  needProvince: boolean;
  province: selectOptionType | null;
  needLoanIDType: boolean;
  loanIDType: selectOptionType | null;
}

export enum fieldEnum {
  TEXT = "TEXT",
  NUMBER = "NUMBER",
  SELECT = "SELECT",
}

export interface fieldType {
  type: fieldEnum;
  title: string;
  placeholder: string;
  setValue: (e: any) => void;
  initValue?: any;
  className?: string;
  customRender?: ReactElement;
  selectProp?: selectPropType;
  serviceCode?: string[];
  providersNeedField?: string[];
  loanIDTypeNeedField?: string[];
  disabled?: boolean;
  needChangeInitValue?: boolean;
}

interface selectPropType {
  options: selectOptionType[];
  showSearch?: boolean;
  needFullHeight?: boolean;
}

export const logoedProviderService: string[] = [
  serviceTypeEnum["BAO-HIEM"],
  serviceTypeEnum["TAI-CHINH"],
  serviceTypeEnum["VAN-TAI"],
  serviceTypeEnum.EDU,
];
