import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../Redux/store";
import {
  IBillDetailViettel,
  getContractsResType,
  getInvoiceResType,
} from "../../Payment/services/payment.type";
import Util from "../../../Util/Util";
import { queryEnum } from "../../../Types/global.type";
import { TRUYEN_HINH_VIETTEL } from "../../Auth/services/auth.type";
import { paymentRowType } from "../../../Components/PaymentRow/PaymentRow";
import { subbillingServiceActions } from "../services/subBillingService.slice";
import {
  EnumTypeRow,
  EnumTypeScreen,
  subbillingServiceState,
} from "../services/subBillingService.type";

const useRenderData = () => {
  const dispatch = useAppDispatch();
  const [billDetail, setbillDetail] = useState<IBillDetailViettel[]>([]);
  const [dataContracts1, setDataContracts1] = useState<getContractsResType[]>(
    []
  ); // dùng hiển thị bảng cho màn đầu tiên khi truy vấn provider Viettel (số nhiều)
  const [headerDetailContracts, setHeaderDetailContracts] = useState<
    paymentRowType[]
  >([]);

  const [headerListContracts, setHeaderListContracts] = useState<
    paymentRowType[]
  >([]); // dùng hiển thị 2 dòng màn đầu tiên khi truy vấn provider Viettel (số nhiều)

  const invoice = useSelector<RootState, getInvoiceResType | null>(
    (state) => state.payment.invoice
  );

  const { provider, clientID } = useMemo(() => {
    return Util.getByQuery([
      queryEnum.provider,
      queryEnum.clientID,
      // queryEnum.service,
    ]);
  }, []);
  const { billingContracts, typeScreen, billingContractItem } = useSelector<
    RootState,
    subbillingServiceState
  >((state) => state.subbillingService);

  const handleSetFieldItemContract = (
    data: getContractsResType
  ): paymentRowType[] => {
    return [
      { text: data.cmkIsdn, title: "Thuê bao đại diện" },
      {
        text: Util.convertTeleComServiceType(data.teleComServiceType),
        title: "Dịch vụ",
      },
      {
        text: data.phoneNumber,
        title: "Số điện thoại liên hệ",
      },
      {
        text: `${Util.formatNumberWithFixedAndComma(data.debtCharges, 0)}`,
        title: "Nợ cước (VND)",
      },
    ];
  };

  const handleSetFieldItemContractDetail = (
    data: IBillDetailViettel
  ): paymentRowType[] => {
    return [
      {
        text: "data?.cmkIsdn",
        title: "Thuê bao đại diện",
      },
      {
        text: `${Util.formatNumberWithFixedAndComma(data.debtCharges, 0)}`,
        title: "Nợ cước",
      },
      {
        text: `${Util.formatNumberWithFixedAndComma(data.debtCharges, 0)}`,
        title: "Đóng cước trước",
        typeRowContract: EnumTypeRow.BUTTON,
      },
    ];
  };

  useEffect(() => {
    if (billingContracts.length > 1) {
      setDataContracts1(
        billingContracts.map((e) => ({ ...e, phoneNumber: clientID }))
      );
    }

    setHeaderListContracts([
      {
        title: "Nhà cung cấp",
        text:
          provider === TRUYEN_HINH_VIETTEL
            ? "Truyền hình Viettel"
            : "Internet Viettel",
      }, // hash tạm (nhớ lấy data từ BE để map lại)
      { title: "Số điện thoại", text: clientID },
    ]);
  }, [billingContracts]);

  useEffect(() => {
    dataContracts1.length !== 0 &&
      dispatch(
        subbillingServiceActions.setBillingContractItem(dataContracts1[0])
      );
  }, [dataContracts1]);

  useEffect(() => {
    if (typeScreen === EnumTypeScreen.DETAIL && billingContractItem) {
      setHeaderDetailContracts([
        {
          title: "Nhà cung cấp",
          text:
            provider === TRUYEN_HINH_VIETTEL
              ? "Truyền hình Viettel"
              : "Internet Viettel",
        }, // hash tạm (nhớ lấy data từ BE để map lại)
        {
          text: Util.convertTeleComServiceType(
            billingContractItem?.teleComServiceType
          ),
          title: "Dịch vụ",
        },
        {
          text: `${billingContractItem?.accountId}`,
          title: "Mã hợp đồng",
        },
        {
          text: `${invoice?.customerName}`,
          title: "Tên chủ hợp đồng",
        },
        {
          text: `${billingContractItem?.cmkIsdn}`,
          isShown: billDetail ? billDetail?.length > 1 : !!billDetail,
          title: "Thuê bao đại diện",
        },
        {
          text: `${Util.formatNumberWithFixedAndComma(invoice?.amount[0], 0)}`,
          title: "Nợ cước (VND)",
        },
      ]);
    }
  }, [typeScreen, invoice, billDetail]);

  useEffect(() => {
    setbillDetail(invoice?.billDetail as unknown as IBillDetailViettel[]);
  }, [invoice]);

  return {
    billDetail,
    dataContracts1,
    headerListContracts,
    headerDetailContracts,
    handleSetFieldItemContract,
    handleSetFieldItemContractDetail,
  };
};

export default useRenderData;
