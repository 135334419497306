import React from "react";
import "./PhoneTabs.scss";
import { RootState, useAppDispatch } from "../../../../Redux/store";
import Util from "../../../../Util/Util";
import { useSelector } from "react-redux";
import CustomButton from "../../../../Components/CustomButton/CustomButton";
import {
  dataComboEnum,
  dataComboTitleEnum,
} from "../../services/topupService.type";
import { topupServiceActions } from "../../services/topupService.slice";

const PhoneTabs = () => {
  const dispatch = useAppDispatch();
  const dataCombo = useSelector<RootState, dataComboEnum>(
    (state) => state.topupService.dataCombo
  );

  return (
    <div className="phone-data-tabs-container">
      {Object.keys(dataComboEnum).map((a, i) => (
        <CustomButton
          key={i}
          className={`phone-data-combo-button ${
            a === dataCombo ? "phone-data-combo-button-active" : ""
          }`}
          title={Util.convertTrans(a, dataComboTitleEnum)}
          onClick={() => {
            dispatch(
              topupServiceActions.setDataComboMethod(
                Util.convertTrans(a, dataComboEnum)
              )
            );
          }}
        />
      ))}
    </div>
  );
};

export default React.memo(PhoneTabs);
