import { useSelector } from "react-redux";
import {
  INTERNET_VIETTEL_TRA_SAU,
  TRUYEN_HINH_VIETTEL,
  serviceTypeEnum,
} from "../../Auth/services/auth.type";
import {
  IBillDetailViettel,
  paymentMethodCode,
  paymentState,
} from "../services/payment.type";
import { RootState } from "../../../Redux/store";
import { providerNeedProvince } from "../../BillingService/components/ProvinceField/hooks/useGetProvince";
import Util from "../../../Util/Util";
import { queryEnum } from "../../../Types/global.type";
import { LoanIDTypeCode } from "../../BillingService/components/LoanIDTypeField/services/loanIDType.type";

const useGetSubmitData = () => {
  const { invoice, amount, eduTotal } = useSelector<RootState, paymentState>(
    (state) => state.payment
  );

  const createSubmitData = (paymentMethod: paymentMethodCode) => {
    const {
      service,
      provider,
      clientID,
      loanIDType,
      province,
      accountId,
      addedAmount,
    } = Util.getByQuery([
      queryEnum.service,
      queryEnum.provider,
      queryEnum.clientID,
      queryEnum.loanIDType,
      queryEnum.province,
      queryEnum.accountId,
      queryEnum.addedAmount,
    ]);
    // Create normal submit data
    let submitData: any = {
      serviceCode: provider ? provider : "",
      billingCode: clientID ? clientID : "",
      paymentMethod: paymentMethod,
    };

    // Add province if available
    if (providerNeedProvince.includes(provider ? provider : ""))
      submitData = { ...submitData, provinceCode: province };

    if (loanIDType)
      submitData = {
        ...submitData,
        typeBillingCode: Util.convertTrans(loanIDType, LoanIDTypeCode),
      };

    if (
      provider === TRUYEN_HINH_VIETTEL ||
      provider === INTERNET_VIETTEL_TRA_SAU
    ) {
      let invoiceViettel: IBillDetailViettel[] =
        (invoice?.billDetail as unknown as IBillDetailViettel[]) ?? [];

      invoiceViettel = invoiceViettel.map((e) => {
        return {
          ...e,
          list: e.list.filter((el) =>
            addedAmount ? el.totalMoney === addedAmount : !el
          ),
        };
      });

      submitData = {
        ...submitData,
        amount: addedAmount
          ? invoice?.amount.reduce((a, b) => a + b, 0) ?? 0 + +addedAmount
          : invoice?.amount.reduce((a, b) => a + b, 0),
        accountId,
        billingDetail: invoiceViettel,
      };
    }

    // Other special case like Edu service
    switch (true) {
      case service === serviceTypeEnum.EDU:
        submitData = {
          ...submitData,
          billingDetail: eduTotal.map((a) => {
            return { id: a.id };
          }),
        };
        break;

      case !!amount && amount !== 0:
        submitData = { ...submitData, amount: amount };
        break;

      default:
        break;
    }

    return submitData;
  };

  return { createSubmitData };
};

export default useGetSubmitData;
