import React, { Fragment } from "react";
import { IBillDetailViettel } from "../../../../Payment/services/payment.type";
import useRenderData from "../../../hooks/useRenderData";
import RowItem from "../RowItem/RowItem";

interface IPropsItemContractDetail {
  data: IBillDetailViettel;
}

const ItemContractDetail = ({ data }: IPropsItemContractDetail) => {
  const { handleSetFieldItemContractDetail } = useRenderData();

  return (
    <div className="item-contract-detail">
      <div className="">
        <span className="contract-code-text">{data.isdn}</span>
      </div>

      {handleSetFieldItemContractDetail(data).map((e, i) => (
        <Fragment key={e.title}>
          <RowItem
            text={e.text ?? ""}
            title={e.title}
            key={i}
            type={e?.typeRowContract}
            clasName={"text-16px no-underline "}
            data={data}
          />
        </Fragment>
      ))}
    </div>
  );
};

export default ItemContractDetail;
