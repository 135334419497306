import React from "react";
import "./AllServices.scss";
import { Drawer } from "antd";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../../Redux/store";
import { invoicesActions } from "../../services/invoices.slice";
import CustomDrawerHeader from "../../../../Components/CustomSelect/components/CustomDrawer/CustomDrawerHeader/CustomDrawerHeader";
import ServiceIcon from "../ServiceIcon/ServiceIcon";
import { serviceTypeEnum } from "../../../Auth/services/auth.type";

interface PT {
  items: (serviceTypeEnum | null)[];
}

const AllServices: React.FC<PT> = ({ items }) => {
  const dispatch = useAppDispatch();

  const isOpenMore = useSelector<RootState, boolean>(
    (state) => state.invoices.isOpenMore
  );

  const onClose = () => {
    dispatch(invoicesActions.setOpenMore(false));
  };

  return (
    <Drawer
      closable={false}
      destroyOnClose
      height={window.innerHeight}
      title={null}
      placement="bottom"
      open={isOpenMore}
      rootClassName="customized-select-root-drawer"
      className="customized-select-drawer all-services-container"
    >
      <CustomDrawerHeader placeholder="Hóa đơn" close={onClose} />
      <div className="all-services-wrap">
        {items.map((a, i) => (
          <div key={i} className="services-containe-item">
            {a !== null && <ServiceIcon key={i} icon={a} />}
          </div>
        ))}
      </div>
    </Drawer>
  );
};

export default React.memo(AllServices);
