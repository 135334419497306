import { useEffect } from "react";
import { RootState, useAppDispatch } from "../../../Redux/store";
import {
  confirmActions,
  getInvoicePaymentStatus,
} from "../services/confirm.slice";
import { useNavigate, useParams } from "react-router-dom";
import {
  invoiceDataType,
  paymentStatusTransNumber,
} from "../services/confirm.type";
import { useSelector } from "react-redux";
import { MainRoutesEnum } from "../../../Types/routes.type";
import { serviceType } from "../../Auth/services/auth.type";
import { authActions } from "../../Auth/services/auth.slice";

const useGetPaymentStatus = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const services = useSelector<RootState, serviceType[]>(
    (state) => state.auth.services
  );

  useEffect(() => {
    const getInvoicePaymentStatusSuccess = (data: invoiceDataType) => {
      dispatch(confirmActions.setData(data));

      if (
        data.transactionStatus !== Number(paymentStatusTransNumber.SUCCESS) &&
        data.transactionStatus !==
          Number(paymentStatusTransNumber.THIRD_PARTY) &&
        data.transactionStatus !== Number(paymentStatusTransNumber.PENDING)
      ) {
        dispatch(authActions.setLoading(false));
        navigate(MainRoutesEnum.INVOICES);
      }
    };

    const getInvoicePaymentStatusError = () => {
      dispatch(authActions.setLoading(false));
      navigate(MainRoutesEnum.INVOICES);
    };

    if (services.length !== 0)
      if (params && params.invoiceID) {
        dispatch(authActions.setLoading(true));
        dispatch(
          getInvoicePaymentStatus(
            params.invoiceID,
            getInvoicePaymentStatusSuccess,
            getInvoicePaymentStatusError
          )
        );
      } else {
        navigate(MainRoutesEnum.INVOICES);
      }
  }, [params, services]);

  return {};
};

export default useGetPaymentStatus;
