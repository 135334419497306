import React from "react";
import "./DataPackageItem.scss";
import Util from "../../../../Util/Util";
import { dataPackageItemType } from "../../services/topupService.type";
import { useAppDispatch } from "../../../../Redux/store";
import { topupServiceActions } from "../../services/topupService.slice";

interface PT {
  isActive: boolean;
  data: dataPackageItemType;
  setItemActive: React.Dispatch<React.SetStateAction<string>>;
}

const DataPackageItem: React.FC<PT> = ({ data, isActive, setItemActive }) => {
  const dispatch = useAppDispatch();

  const setAmount = (e: number) => {
    dispatch(topupServiceActions.setAmountDataCombo(e));
  };

  return (
    <div
      className={`data-package-item-container ${
        isActive ? "data-package-item-active" : ""
      }`}
      onClick={() => {
        setItemActive(data.id ?? "");
        setAmount(data.amount);
      }}
    >
      <div className="data-package-item-info">
        <div className="data-package-item-size">{data.size}</div>
        <div className="data-package-item-duration">{data.duration}</div>
      </div>
      <div className="data-package-item-amount">
        {Util.formatNumberWithFixedAndComma(data.amount, 0)}
      </div>
    </div>
  );
};

export default React.memo(DataPackageItem);
