import React from "react";
import "./CustomIcon.scss";

interface PT {
  className: string;
  onClick?: () => void;
}

const CustomIcon: React.FC<PT> = ({ className, onClick }) => {
  return (
    <div
      className={`custom-icon-container ${className ? className : ""}`}
      onClick={() => {
        if (onClick) onClick();
      }}
    />
  );
};

export default React.memo(CustomIcon);
