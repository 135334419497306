import { useNavigate } from "react-router-dom";
import { MainRoutesEnum } from "../../../Types/routes.type";
import { queryEnum } from "../../../Types/global.type";
import { RootState, useAppDispatch } from "../../../Redux/store";
import { useSelector } from "react-redux";
import { billingServiceActions } from "../../BillingService/services/billingService.slice";
import { useEffect, useMemo, useState } from "react";
import Util from "../../../Util/Util";
import {
  INTERNET_VIETTEL_TRA_SAU,
  TRUYEN_HINH_VIETTEL,
} from "../../Auth/services/auth.type";
import { subbillingServiceActions } from "../services/subBillingService.slice";
import {
  EnumTypeScreen,
  subbillingServiceState,
} from "../services/subBillingService.type";
import { paymentActions } from "../../Payment/services/payment.slice";

const useSubmit = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [disable, setDiable] = useState(false);

  const { provider } = useMemo(() => {
    return Util.getByQuery([
      queryEnum.service,
      queryEnum.provider,
      queryEnum.clientID,
      queryEnum.phone,
    ]);
  }, []);

  const {
    addedAmount,
    typeScreen,
    isOpenAddedAmount,
    billingContractItem,
    billingContracts,
    idCheckboxContract
  } = useSelector<RootState, subbillingServiceState>(
    (state) => state.subbillingService
  );

  const onNavigate = (addedAmountPath: string) => {
    const params = new URLSearchParams(window.location.search);
    params.delete(queryEnum.addedAmount);

    dispatch(billingServiceActions.reset());
    navigate(
      `/${MainRoutesEnum.PAYMENT}?${params.toString()}${addedAmountPath}`
    );
  };

  const onSubmitData = () => {
    let addedAmountPath = "";

    const accountId = `&${queryEnum.accountId}=${encodeURIComponent(
      billingContractItem?.accountId ? billingContractItem?.accountId : ""
    )}`;

    // accountId
    const query = accountId;

    if (addedAmount && isOpenAddedAmount) {
      addedAmountPath = `&${queryEnum.addedAmount}=${encodeURIComponent(
        addedAmount
      )}`;
    }
    if (
      provider !== TRUYEN_HINH_VIETTEL &&
      provider !== INTERNET_VIETTEL_TRA_SAU
    ) {
      onNavigate(addedAmountPath);
    } else {
      if (typeScreen === EnumTypeScreen.LIST) {
        dispatch(subbillingServiceActions.setTypeScreen(EnumTypeScreen.DETAIL));
      } else {
        onNavigate(addedAmountPath + query);
      }
    }
  };

  const onNavigateBack = () => {
    dispatch(billingServiceActions.reset());
    setTimeout(() => {
      navigate(`/${MainRoutesEnum.BILLING}${window.location.search}`);
    }, 30);
  };

  const onBack = () => {
    if (
      provider !== TRUYEN_HINH_VIETTEL &&
      provider !== INTERNET_VIETTEL_TRA_SAU
    ) {
      onNavigateBack();
    } else {
      if (typeScreen === EnumTypeScreen.LIST) {
        onNavigateBack();
        dispatch(paymentActions.reset());
      } else if (typeScreen === EnumTypeScreen.DETAIL) {
        dispatch(paymentActions.reset());

        if (billingContracts.length === 1) {
          onNavigateBack();
        } else {
          dispatch(subbillingServiceActions.setTypeScreen(EnumTypeScreen.LIST));
        }
      }
    }
  };

  useEffect(() => {
    !billingContractItem || !idCheckboxContract ? setDiable(true) : setDiable(false);
  }, [billingContractItem]);

  return { onSubmitData, onBack, disable };
};

export default useSubmit;
