import Api from "../../../Providers/Api";
import { clearStatusResType, invoiceDataType } from "./confirm.type";

export default class confirmAPI {
  static getInvoicePaymentStatusAPI = async (orderCode: string) => {
    return await Api.get<invoiceDataType>(
      `/v1.0/api/payment/transactions/${orderCode}`
    );
  };

  static getClearStatusAPI = async (orderCode: string) => {
    return await Api.get<clearStatusResType>(
      `/v1.0/api/service/transactions/${orderCode}`
    );
  };
}
