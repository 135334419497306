import { selectOptionType } from "../../../Types/global.type";
import { paymentMethodCode } from "../../Payment/services/payment.type";

export interface topupServiceState {
  phone: string;

  denos: number[];
  denomination: number;
  denominationDownload: number;
  dataMethod: dataMethodEnum;
  dataCombo: dataComboEnum;
  quantity: number;
  selectedService: selectOptionType | null;

  amountDataCombo: number
  timePackage: string

  services: selectOptionType[];
  recentlyPhone: recentlyPhoneType[];
}


export const ALL = 'ALL'

export enum phoneServiceEnum {
  VINAPHONE = "VINAPHONE",
  VIETTEL = "VIETTEL",
  MOBIFONE = "MOBIFONE",
  VNMOBILE = "VNMOBILE",
  GMOBILE = "GMOBILE",
}

export enum availablePhoneServiceEnum {
  VIETTEL = phoneServiceEnum.VIETTEL,
  VINAPHONE = phoneServiceEnum.VINAPHONE,
  MOBIFONE = phoneServiceEnum.MOBIFONE,
  GMOBILE = phoneServiceEnum.GMOBILE,
  VNMOBILE = phoneServiceEnum.VNMOBILE,
}

export enum dataMethodEnum {
  TOPUP_DATA = "TOPUP_DATA",
  TOPUP_DOWNLOAD = "TOPUP_DOWNLOAD",
}

export enum dataComboEnum {
  COMBO = "COMBO",
  LOAD_DATA = "LOAD_DATA",
  DATA_CARD = "DATA_CARD",
}

export enum serviceDes {
  phone = "điện thoại",
  data = "Data 3G/4G",
}

export enum phoneMethodTitleEnum {
  TOPUP_DATA = "Nạp tiền điện thoại",
  TOPUP_DOWNLOAD = "Mua thẻ điện thoại",
}

export enum dataComboTitleEnum {
  COMBO = "Combo",
  LOAD_DATA = "Nạp data",
  DATA_CARD = "Thẻ data",
}

export enum dataMethodDesEnum {
  TOPUP_DATA = "Nạp tiền",
  TOPUP_DOWNLOAD = "Mã thẻ",
}

export interface phoneServiceType {
  service: string;
  telcom: phoneServiceEnum;
  amount: number;
}

export interface recentlyPhoneType {
  phoneNumber: string;
}

export interface getTopupServiceServiceReqType {
  telcom: phoneServiceEnum;
}

export interface createTopupServiceOrderReqType {
  serviceCode: string;
  phoneNumber: string;
  paymentMethod: paymentMethodCode;
}

export enum phoneServiceTrans {
  VINAPHONE = "Vinaphone",
  VIETTEL = "Viettel",
  MOBIFONE = "Mobifone",
  VNMOBILE = "Vietnamobile",
  GMOBILE = "Gmobile",
}

export const headPhoneNumber = [
  {
    serviceCode: phoneServiceEnum.VIETTEL,
    heads: [
      "086",
      "096",
      "097",
      "098",
      "032",
      "033",
      "034",
      "035",
      "036",
      "037",
      "038",
      "039",
    ],
  },
  {
    serviceCode: phoneServiceEnum.MOBIFONE,
    heads: ["089", "090", "093", "070", "076", "077", "078", "079"],
  },
  {
    serviceCode: phoneServiceEnum.VNMOBILE,
    heads: ["092", "052", "056", "058"],
  },
  {
    serviceCode: phoneServiceEnum.VINAPHONE,
    heads: ["091", "094", "081", "082", "083", "084", "085", "088"],
  },
  { serviceCode: phoneServiceEnum.GMOBILE, heads: ["099", "059"] },
];

export const phoneServiceByProviderArray = [
  {
    phoneService: phoneServiceEnum.MOBIFONE,
    provider: ["MOBIFONE-TRA-SAU"],
  },
  {
    phoneService: phoneServiceEnum.VIETTEL,
    provider: ["DI-DONG-TRA-SAU-VIETTEL"],
  },
  {
    phoneService: phoneServiceEnum.VINAPHONE,
    provider: ["DI-DONG-TRA-SAU-VNPT"],
  },
];

export const headLinePhoneNumber = [
  "0296",
  "0209",
  "0291",
  "0275",
  "0274",
  "0252",
  "0292",
  "0236",
  "0261",
  "0251",
  "0269",
  "0226",
  "0239",
  "0225",
  "028",
  "0221",
  "0297",
  "0213",
  "0205",
  "0272",
  "0238",
  "0259",
  "0257",
  "0232",
  "0255",
  "0233",
  "0212",
  "0227",
  "0237",
  "0273",
  "0207",
  "0211",
  "0254",
  "0204",
  "0222",
  "0256",
  "0271",
  "0290",
  "0206",
  "0262",
  "0215",
  "0277",
  "0219",
  "024",
  "0220",
  "0293",
  "0218",
  "0258",
  "0260",
  "0263",
  "0214",
  "0228",
  "0229",
  "0210",
  "0235",
  "0203",
  "0299",
  "0276",
  "0208",
  "0234",
  "0294",
  "0270",
  "0216",
];

export interface dataPackageItemType {
  amount: number;
  size: string;
  duration: string;
  active?: boolean;
  id?: string
}
