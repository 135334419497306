import { authReducer } from "../Containers/Auth/services/auth.slice";
import { billingServiceReducer } from "../Containers/BillingService/services/billingService.slice";
import { confirmReducer } from "../Containers/Confirm/services/confirm.slice";
import { invoicesReducer } from "../Containers/Invoices/services/invoices.slice";
import { paymentReducer } from "../Containers/Payment/services/payment.slice";
import { subbillingServiceReducer } from "../Containers/SubBillingService/services/subBillingService.slice";
import { topupServiceReducer } from "../Containers/TopupService/services/topupService.slice";

export const rootReducers = {
  auth: authReducer,
  invoices: invoicesReducer,
  billingService: billingServiceReducer,
  payment: paymentReducer,
  topupService: topupServiceReducer,
  confirm: confirmReducer,
  subbillingService: subbillingServiceReducer,
};
