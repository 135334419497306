import React from "react";
import useGetSavedInvoices from "../../hooks/useGetSavedInvoices";
import { useSelector } from "react-redux";
import { RootState } from "../../../../Redux/store";
import { savedInvoiceType } from "../../services/invoices.type";
import { serviceType } from "../../../Auth/services/auth.type";
import LazySavedInvoice from "../LazySavedInvoice/LazySavedInvoice";
import Slider from "react-slick";
import "./SavedInvoices.scss";
import SaveInvoiceBlock from "./components/SaveInvoiceBlock/SaveInvoiceBlock";

const sliderSetting = {
  infinite: true,
  dots: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  rows: 1,
};

const SavedInvoices = () => {
  const { renderItems } = useGetSavedInvoices();
  const savedInvoices = useSelector<RootState, savedInvoiceType[] | null>(
    (state) => state.invoices.savedInvoices
  );
  const services = useSelector<RootState, serviceType[]>(
    (state) => state.auth.services
  );
  const loadingGetSavedInvoices = useSelector<RootState, boolean>(
    (state) => state.auth.loadingGetSavedInvoices
  );

  return (
    <>
      {(loadingGetSavedInvoices ||
        (savedInvoices && savedInvoices.length !== 0)) && (
        <div className="saved-invoices-container">
          <div className="saved-invoices-title">Hóa đơn đã lưu</div>
          {loadingGetSavedInvoices || services.length === 0 ? (
            <LazySavedInvoice />
          ) : (
            <Slider {...sliderSetting}>
              {renderItems.map((a, i) => (
                <SaveInvoiceBlock data={a} key={i} />
              ))}
            </Slider>
          )}
        </div>
      )}
    </>
  );
};

export default React.memo(SavedInvoices);
