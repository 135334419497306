import React from "react";
import "./PhoneServices.scss";
import CustomSelect from "../../../../Components/CustomSelect/CustomSelect";
import { selectOptionType } from "../../../../Types/global.type";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../../Redux/store";
import { topupServiceActions } from "../../services/topupService.slice";

const PhoneServices = () => {
  const dispatch = useAppDispatch();
  const services = useSelector<RootState, selectOptionType[]>(
    (state) => state.topupService.services
  );
  const selectedService = useSelector<RootState, selectOptionType | null>(
    (state) => state.topupService.selectedService
  );

  const onSetPhoneService = (e: selectOptionType | null) => {
    dispatch(topupServiceActions.setSelectedService(e));
  };

  return (
    <CustomSelect
      value={selectedService}
      onSetValue={onSetPhoneService}
      options={services}
      className="phone-services-container"
      placeholder="Chọn nhà mạng"
      noted={
        <div className="phone-services-noted">
          Vui lòng chỉ chọn lại nhà mạng khi quý khách đã đăng ký giữ số chuyển
          mạng
        </div>
      }
      doNotNeedClosable
      buttonPlaceHolder={<div className="phone-services-none-selected"></div>}
      doNotNeedDropDownIcon
    />
  );
};

export default React.memo(PhoneServices);
