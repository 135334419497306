import React, { useEffect, useMemo, useState } from "react";
import "./TopupService.scss";
import { useAppDispatch } from "../../Redux/store";
import useGetPhoneServices from "./hooks/useGetPhoneServices";
import useSubmit from "./hooks/useSubmit";
import useGetRecentlyPhone from "./hooks/useGetRecentlyPhone";
import Util from "../../Util/Util";
import { topupServiceActions } from "./services/topupService.slice";
import { queryEnum } from "../../Types/global.type";
import { availableTopupService } from "../Auth/services/auth.type";
import Buttons from "../../Components/Buttons/Buttons";
import PhoneTabs from "./components/PhoneTabs/PhoneTabs";
import TopUpPhone from "./components/TopUpPhone/TopUpPhone";
import DataCombo from "./components/DataCombo/DataCombo";

const TopupService = () => {
  const dispatch = useAppDispatch();
  const [serviceType, setServiceType] = useState<string>("");
  const { service } = useMemo(() => {
    return Util.getByQuery([queryEnum.service]);
  }, []);

  useEffect(() => {
    if (service) setServiceType(service);
  }, []);

  const {
    onChangePhone,
    onSubmit,
    onBack,
    errorPhone,
    setErrorPhone,
    disabledSubmit,
  } = useSubmit();

  useGetRecentlyPhone();
  useGetPhoneServices();

  useEffect(() => {
    return () => {
      dispatch(topupServiceActions.reset());
    };
  }, []);

  return (
    <div className="phone-container">
      {serviceType === availableTopupService[1] && <PhoneTabs />}
      <div className="phone-main">
        {/* ========= nạp tiền điện thoại =============== */}
        <TopUpPhone
          service={service}
          errorPhone={errorPhone}
          onChangePhone={onChangePhone}
          setErrorPhone={setErrorPhone}
        />

        {/* ========= data combo (3g/4g) =============== */}
        <DataCombo
          service={service}
          errorPhone={errorPhone}
          onChangePhone={onChangePhone}
          setErrorPhone={setErrorPhone}
        />
      </div>

      {/* Button */}
      <Buttons onBack={onBack} onOk={onSubmit} disabledOk={disabledSubmit} />
    </div>
  );
};

export default React.memo(TopupService);
