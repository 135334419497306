import React, { useEffect, useState } from "react";
import "./DonotNeedPayment.scss";
import {
  serviceType,
  serviceTypeEnum,
} from "../../../../Containers/Auth/services/auth.type";
import CustomIcon from "../../../../Components/CustomIcon/CustomIcon";
import Util from "../../../../Util/Util";
import { queryEnum } from "../../../../Types/global.type";
import {
  LoanIDTypeEnum,
  LoanIDTypeTrans,
} from "../../../BillingService/components/LoanIDTypeField/services/loanIDType.type";

interface PT {
  providerData: serviceType | null;
}

const DonotNeedPayment: React.FC<PT> = ({ providerData }) => {
  const [clientIDTitle, setClientIDTitle] = useState<string>("");
  const { clientID, service, loanIDType, provider } = Util.getByQuery([
    queryEnum.clientID,
    queryEnum.service,
    queryEnum.loanIDType,
    queryEnum.provider,
  ]);

  useEffect(() => {
    switch (true) {
      case [
        serviceTypeEnum["VIEN-THONG-TRA-SAU"],
        serviceTypeEnum["VIEN-THONG-CO-DINH"],
      ].includes(service):
        setClientIDTitle("Số thuê bao");
        break;

      case service === serviceTypeEnum.EDU:
        setClientIDTitle("Mã học sinh / Số định danh");
        break;

      case provider === "THU-HO-LOTTE":
        setClientIDTitle(LoanIDTypeTrans.CONTRACT_CODE_LOAN);
        break;

      case loanIDType === LoanIDTypeEnum.CCCD:
        setClientIDTitle(LoanIDTypeTrans.CCCD);
        break;

      case loanIDType === LoanIDTypeEnum.CONTRACT_CODE_INS &&
        service === serviceTypeEnum["BAO-HIEM"]:
        setClientIDTitle(LoanIDTypeTrans.CONTRACT_CODE_INS);
        break;

      case loanIDType === LoanIDTypeEnum.CONTRACT_CODE_LOAN &&
        service === serviceTypeEnum["TAI-CHINH"]:
        setClientIDTitle(LoanIDTypeTrans.CONTRACT_CODE_LOAN);
        break;

      default:
        setClientIDTitle("Mã khách hàng");
        break;
    }
  }, [service, loanIDType, provider]);

  return (
    <div className="payment-do-not-need">
      <div className="payment-do-not-need-icon-container">
        <CustomIcon className="payment-do-not-need-icon-svg" />
      </div>

      <div className="payment-do-not-need-text">
        Hóa đơn chưa đến kỳ thanh toán
      </div>

      <div className="payment-do-not-need-item">
        <div className="payment-do-not-need-item-title">{clientIDTitle}</div>
        <div className="payment-do-not-need-item-value">{clientID}</div>
      </div>

      <div className="payment-do-not-need-item payment-do-not-need-item-last">
        <div className="payment-do-not-need-item-title">Dịch vụ</div>
        <div className="payment-do-not-need-item-value">
          {providerData?.name}
        </div>
      </div>
    </div>
  );
};

export default React.memo(DonotNeedPayment);
