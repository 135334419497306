import React, { useRef } from "react";
import "./HorizontalScrollList.scss";
import useScrollitem from "../../hooks/useScrollitem";

interface IProps {
  data?: Array<{ title: string; value: string }>;
}

const initData = [
  { title: "Tất cả", value: "All" },
  { title: "1 ngày", value: "1 day" },
  { title: "3 ngày", value: "3 day" },
  { title: "7 ngày", value: "7 day" },
  { title: "1 tháng", value: "1 month" },
  { title: "3 tháng", value: "3 month" },
  { title: "6 tháng", value: "6 month" },
  { title: "12 tháng", value: "12 month" },
];

const HorizontalScrollList: React.FC<IProps> = ({
  data = initData,
}: IProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { handleClick, activeIndex } = useScrollitem({ containerRef });

  return (
    <>
      <div className="title-list-package">Danh sách gói</div>
      <div className="container-horizontal-scroll">
        <div className="scroll-container" ref={containerRef}>
          {data.map((e, index) => (
            <div
              className={`item ${index === activeIndex ? "item-active" : ""}`}
              key={e.value}
              onClick={() => handleClick(index, e.value)}
            >
              {e.title}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default HorizontalScrollList;
