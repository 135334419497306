import React, { ReactNode, useEffect, useState } from "react";
import "./CustomInput.scss";
import { themeTrans } from "../../Types/global.type";
import useCountdown from "ahooks/lib/useCountDown";
import Util from "../../Util/Util";

interface PT {
  initValue?: any;
  onChange: (e: any) => void;
  placeholder?: string;
  title?: string;
  className?: string;
  prefix?: ReactNode;
  suffix?: ReactNode;
  allowClear?: boolean;
  onFocus?: () => void;
  onBlur?: () => void;
  onClear?: () => void;
  errroMess?: string;
  needUnaccentedVietnamese?: boolean;
  needChangeInitValue?: boolean;
}

const CustomInput: React.FC<PT> = ({
  initValue,
  onChange,
  placeholder,
  title,
  className,
  prefix,
  suffix,
  allowClear,
  onFocus,
  onBlur,
  onClear,
  errroMess,
  needUnaccentedVietnamese,
  needChangeInitValue,
}) => {
  const theme = document
    .getElementsByTagName("HTML")[0]
    .getAttribute("data-theme");

  const [targetDate, setTargetDate] = useState<number>();
  const [value, setValue] = useState<any>("");

  useCountdown({
    targetDate: targetDate,
    onEnd: () => {
      unaccentedVietnamese();
    },
  });

  useEffect(() => {
    if (initValue !== undefined) onHanldeChange(initValue);
  }, [initValue, needChangeInitValue]);

  const onHanldeChange = (e: string) => {
    if (e.indexOf("%") < 0) {
      if (needUnaccentedVietnamese) setTargetDate(Date.now() + 1000);
      onChange(e);
      setValue(e);
    }
  };

  const unaccentedVietnamese = () => {
    const unaccentedVietnamese = Util.convertToUnaccentedVietnamese(value);
    onChange(unaccentedVietnamese);
    setValue(unaccentedVietnamese);
  };

  const onBlurAction = () => {
    if (needUnaccentedVietnamese) unaccentedVietnamese();
    if (onBlur) onBlur();
  };

  return (
    <>
      {title && <div className="customized-input-title">{title}</div>}

      <div
        className={`customized-number-input-container customized-input-container ${
          className ? className : ""
        }`}
      >
        {/* Tiền tố */}
        {prefix && <div className="custom-input-fixes prefixes">{prefix}</div>}

        {/* Input chính */}
        <input
          onFocus={onFocus}
          onBlur={onBlurAction}
          className={`customized-number-input-main ${
            theme === themeTrans.EPAY_PARTNER ? "epay-input" : ""
          }`}
          placeholder={placeholder}
          value={value}
          onChange={(e) => {
            onHanldeChange(e.target.value);
          }}
        />

        {/* Hậu tố */}
        {allowClear && (
          <div className="custom-input-fixes suffixes">
            {value && (
              <div
                className="customized-input-clear-icon"
                onClick={() => {
                  onHanldeChange("");
                  if (onClear) onClear();
                }}
              ></div>
            )}
            {suffix && suffix}
          </div>
        )}
      </div>

      {errroMess && (
        <div className="customized-input-errroMess">{errroMess}</div>
      )}
    </>
  );
};

export default React.memo(CustomInput);
