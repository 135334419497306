import { serviceTypeEnum } from "../../../../Auth/services/auth.type";

export enum LoanIDTypeEnum {
  CONTRACT_CODE_LOAN = "CONTRACT_CODE_LOAN",
  CONTRACT_CODE_INS = "CONTRACT_CODE_INS",
  CCCD = "CCCD",
}

export enum LoanIDTypeTrans {
  CONTRACT_CODE_LOAN = "Số hợp đồng / Mã khoản vay",
  CONTRACT_CODE_INS = "Số hợp đồng / Số HSYCBH",
  CCCD = "Số CMND / CCCD",
}

export enum LoanIDTypeCode {
  CONTRACT_CODE_LOAN = "0",
  CONTRACT_CODE_INS = "0",
  CCCD = "1",
}

export enum ViettelIDTypeCode {
  'TRUYEN-HINH-VIETTEL' = "0",
  'INTERNET-VIETTEL-TRA-SAU' = "0",
}

export const providerNeedBothLoadIDType = [
  "THU-HO-FCCOM",
  "THU-HO-TPFICO",
  "FE-CREDIT",
  "THU-HO-OCB",
  "THU-HO-PTFINANCE",
  "THU-HO-PRUDENTIAL",
  "THU-HO-MSB",
];

export const servicesAutoNeedLoanIDType: {
  service: string;
  fields: string[];
}[] = [
  {
    service: serviceTypeEnum["TAI-CHINH"],
    fields: [LoanIDTypeEnum.CONTRACT_CODE_LOAN, LoanIDTypeEnum.CCCD],
  },
  {
    service: serviceTypeEnum["BAO-HIEM"],
    fields: [LoanIDTypeEnum.CONTRACT_CODE_INS, LoanIDTypeEnum.CCCD],
  },
];
