import { useNavigate } from "react-router-dom";
import { RootState, useAppDispatch } from "../../../../../Redux/store";
import { useEffect, useState } from "react";
import { queryEnum, selectOptionType } from "../../../../../Types/global.type";
import { useSelector } from "react-redux";
import { billingServiceActions } from "../../../services/billingService.slice";
import Util from "../../../../../Util/Util";
import { MainRoutesEnum } from "../../../../../Types/routes.type";
import { authActions } from "../../../../Auth/services/auth.slice";
import {
  LoanIDTypeEnum,
  LoanIDTypeTrans,
  providerNeedBothLoadIDType,
} from "../services/loanIDType.type";
import { serviceTypeEnum } from "../../../../Auth/services/auth.type";

const useGetloanIDType = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [needChangeInitValue, setNeedChangeInitValue] =
    useState<boolean>(false);
  const [initLoanIDType, setInitLoanIDType] = useState<selectOptionType | null>(
    null
  );

  const provider = useSelector<RootState, selectOptionType | null>(
    (state) => state.billingService.provider
  );

  const setLoanIDType = (e: selectOptionType | null) => {
    dispatch(billingServiceActions.setLoanIDType(e));
  };

  const onSetInitLoanIDType = (e: string | null) => {
    setNeedChangeInitValue((prev) => !prev);
    if (e === null) {
      setLoanIDType(null);
      setInitLoanIDType(null);
    } else {
      const foundData = {
        value: e,
        label: Util.convertTrans(e, LoanIDTypeTrans),
      };
      setLoanIDType(foundData);
      setInitLoanIDType(foundData);
    }
  };

  const setupLoanIDType = (provider: selectOptionType | null) => {
    const { loanIDType, service } = Util.getByQuery([
      queryEnum.loanIDType,
      queryEnum.service,
    ]);

    switch (true) {
      case !!provider &&
        !providerNeedBothLoadIDType.includes(provider.value) &&
        service === serviceTypeEnum["TAI-CHINH"]:
        onSetInitLoanIDType(LoanIDTypeEnum.CONTRACT_CODE_LOAN);
        break;

      case !!provider &&
        !providerNeedBothLoadIDType.includes(provider.value) &&
        service === serviceTypeEnum["BAO-HIEM"]:
        onSetInitLoanIDType(LoanIDTypeEnum.CONTRACT_CODE_INS);
        break;

      case !!provider && !!loanIDType:
        switch (true) {
          case loanIDType === LoanIDTypeEnum.CONTRACT_CODE_INS &&
            service === serviceTypeEnum["BAO-HIEM"]:
            onSetInitLoanIDType(LoanIDTypeEnum.CONTRACT_CODE_INS);
            break;

          case loanIDType === LoanIDTypeEnum.CONTRACT_CODE_LOAN &&
            service === serviceTypeEnum["TAI-CHINH"]:
            onSetInitLoanIDType(LoanIDTypeEnum.CONTRACT_CODE_LOAN);
            break;

          case loanIDType === LoanIDTypeEnum.CCCD:
            onSetInitLoanIDType(LoanIDTypeEnum.CCCD);
            break;

          default:
            navigate(MainRoutesEnum.INVOICES);
            dispatch(
              authActions.setErrorMess("Không tìm thấy hình thức thanh toán")
            );
            break;
        }

        break;

      case !!provider:
        onSetInitLoanIDType(null);
        break;

      default:
        onSetInitLoanIDType(null);
        break;
    }
  };

  useEffect(() => {
    setupLoanIDType(provider);
  }, [provider]);

  return { initLoanIDType, needChangeInitValue, setLoanIDType };
};

export default useGetloanIDType;
