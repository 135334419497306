import SelectableItem from "../../../../../Components/SelectableItem/SelectableItem";
import { ProvinceCode } from "../../../../../Config/global.config";
import Util from "../../../../../Util/Util";

const useGetProvinceList = () => {
  const provinces = Object.keys(ProvinceCode).map((a) => {
    return {
      value: a,
      label: Util.convertTrans(a, ProvinceCode),
      render: <SelectableItem label={Util.convertTrans(a, ProvinceCode)} />,
      needPhone: false,
    };
  });

  return { provinces };
};

export default useGetProvinceList;
