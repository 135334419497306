import React, { useEffect, useState } from "react";
import "./ElectricProvider.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../../../Redux/store";
import { serviceType } from "../../../Auth/services/auth.type";

const ElectricProvider = () => {
  const [provider, setProvider] = useState<serviceType | null>(null);

  const serviceList = useSelector<RootState, serviceType[]>(
    (state) => state.auth.services
  );

  useEffect(() => {
    const found = serviceList.find((a) => a.code === "DIEN-VIET-NAM");
    if (found) setProvider(found);
  }, []);

  return (
    <>
      <div className="customized-input-title">Nhà cung cấp</div>
      <div className="electric-provider-manual">{provider?.name}</div>
    </>
  );
};

export default React.memo(ElectricProvider);
