export interface invoicesState {
  refresh: boolean;

  savedInvoices: savedInvoiceType[] | null;
  isOpenMore: boolean;
}

export interface savedInvoiceType {
  serviceCode: string;
  serviceName: string;
  billingCode: string;

  fullName: string | null;
  provinceCode: string | null;
  typeBillingCode: string | null;
}

export interface redirectToTrainResType {
  kiosUrl: string;
}

export const trainServiceCode = "KIOSK_DSVN";
