import { useNavigate } from "react-router-dom";
import { RootState, useAppDispatch } from "../../../Redux/store";
import { MainRoutesEnum } from "../../../Types/routes.type";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { queryEnum, selectOptionType } from "../../../Types/global.type";
import Util from "../../../Util/Util";
import {
  dataComboEnum,
  dataMethodEnum,
  headPhoneNumber,
  phoneServiceTrans,
} from "../services/topupService.type";
import { topupServiceActions } from "../services/topupService.slice";
import { serviceTypeEnum } from "../../Auth/services/auth.type";

const useSubmit = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [errorPhone, setErrorPhone] = useState<boolean>(false);
  const [finalQuery, setFinalQuery] = useState<string>("");
  const [disabledSubmit, setDisabledSubmit] = useState<boolean>(true);
  const { service } = useMemo(() => {
    return Util.getByQuery([queryEnum.service]);
  }, []);

  const selectedService = useSelector<RootState, selectOptionType | null>(
    (state) => state.topupService.selectedService
  );
  const phone = useSelector<RootState, string>(
    (state) => state.topupService.phone
  );
  const denomination = useSelector<RootState, number>(
    (state) => state.topupService.denomination
  );
  const amountDataCombo = useSelector<RootState, number>(
    (state) => state.topupService.amountDataCombo
  );
  const denominationDownload = useSelector<RootState, number>(
    (state) => state.topupService.denominationDownload
  );
  const dataMethod = useSelector<RootState, dataMethodEnum>(
    (state) => state.topupService.dataMethod
  );
  // ======== DATA COMBO ============ //
  const dataCombo = useSelector<RootState, dataComboEnum>(
    (state) => state.topupService.dataCombo
  );
  const quantity = useSelector<RootState, number>(
    (state) => state.topupService.quantity
  );

  useEffect(() => {
    if (
      denomination &&
      denominationDownload &&
      service === serviceTypeEnum.PHONE
    ) {
      const servicePath = `${queryEnum.service}=${service}`;
      const minorServicePath = `&${queryEnum.minorService}=${dataMethod}`;
      let query = `?${servicePath}${minorServicePath}`;

      switch (dataMethod) {
        case dataMethodEnum.TOPUP_DATA:
          if (selectedService && phone && !errorPhone) {
            const providerPath = `&${queryEnum.provider}=${selectedService.value}`;
            const denoPath = `&${queryEnum.amount}=${denomination}`;
            const phonePath = `&${queryEnum.phone}=${phone}`;
            query += `${denoPath}${providerPath}${phonePath}`;
            setDisabledSubmit(false);
          } else setDisabledSubmit(true);
          break;

        case dataMethodEnum.TOPUP_DOWNLOAD:
          if (selectedService && quantity) {
            const providerPath = `&${queryEnum.provider}=${selectedService.value}`;
            const denoPath = `&${queryEnum.amount}=${denominationDownload}`;
            const quantityPath = `&${queryEnum.quantity}=${quantity}`;
            query += `${denoPath}${providerPath}${quantityPath}`;
            setDisabledSubmit(false);
          } else setDisabledSubmit(true);
          break;

        default:
          setDisabledSubmit(true);
          break;
      }

      setFinalQuery(query);
    } else setDisabledSubmit(true);
  }, [
    phone,
    denomination,
    denominationDownload,
    selectedService,
    errorPhone,
    quantity,
    dataMethod,
  ]);

  useEffect(() => {
    if (service === serviceTypeEnum.DATA && amountDataCombo) {
      console.log(dataCombo)

      const servicePath = `${queryEnum.service}=${service}`;
      const minorServicePath = `&${queryEnum.minorService}=${dataMethod}`;
      let query = `?${servicePath}${minorServicePath}`;

      if (selectedService && phone && !errorPhone) {
        const providerPath = `&${queryEnum.provider}=${selectedService.value}`;
        const denoPath = `&${queryEnum.amount}=${amountDataCombo}`;
        const phonePath = `&${queryEnum.phone}=${phone}`;
        query += `${denoPath}${providerPath}${phonePath}`;
        setDisabledSubmit(false);
      } else setDisabledSubmit(true);

      setFinalQuery(query);
    } else setDisabledSubmit(true);
  }, [phone, amountDataCombo, selectedService, errorPhone, dataMethod]);

  const onChangePhone = (e: string) => {
    dispatch(topupServiceActions.setPhone(e));

    // Setup phone provider
    if (e.length > 2) {
      const head = e.slice(0, 3);
      const foundService = headPhoneNumber.find((a) => a.heads.includes(head));

      if (foundService) {
        dispatch(
          topupServiceActions.setSelectedService({
            value: foundService.serviceCode,
            label: phoneServiceTrans[foundService.serviceCode],
            selectedRender: (
              <div
                className={`selected-service-icon selected-service-icon-${foundService.serviceCode}`}
              ></div>
            ),
          })
        );
      } else {
        dispatch(topupServiceActions.setSelectedService(null));
      }
    } else dispatch(topupServiceActions.setSelectedService(null));

    // Check phone validate
    if (Util.checkValidatedPhone(e)) setErrorPhone(false);
    else setErrorPhone(true);
  };

  const onSubmit = () => {
    if (!disabledSubmit) {
      navigate(
        `/${MainRoutesEnum.TOPUP}/${MainRoutesEnum.PAYMENT}${finalQuery}`
      );
    }
  };

  const onBack = () => {
    setTimeout(() => {
      navigate(MainRoutesEnum.INVOICES);
    }, 30);
  };

  return {
    onChangePhone,
    onSubmit,
    onBack,
    errorPhone,
    setErrorPhone,
    disabledSubmit,
  };
};

export default useSubmit;
