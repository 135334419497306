import React from "react";
import "./EduPaymentBody.scss";
import EduPaymentBodyItem from "../EduPaymentBodyItem/EduPaymentBodyItem";
import { eduItemType } from "../../../../services/payment.type";

interface PT {
  options: eduItemType[];
}

const EduPaymentBody: React.FC<PT> = ({ options }) => {
  return (
    <div className="edu-payment-body-container">
      {options.map((a, i) => (
        <EduPaymentBodyItem key={i} value={a.d_name} amount={a.d_amount} />
      ))}
    </div>
  );
};

export default React.memo(EduPaymentBody);
