import React from "react";

import { IBillDetailViettel } from "../../../../Payment/services/payment.type";
import CollapseViettel from "../CollapseViettel/CollapseViettel";

interface IPropsRowItem {
  title: string;
  text: string | number;
  data?: IBillDetailViettel;
  clasName?: string;
  type?: "nomal" | "button";
}

const RowItem = ({
  text,
  title,
  type = "nomal",
  clasName,
  data,
}: IPropsRowItem) => {
  const rendderRowItem = () => {
    if (type === "nomal") {
      return (
        <div className={`payment-item ${clasName ? clasName : ""}`}>
          <div className="payment-title">{title}</div>
          <div className="payment-text">{text}</div>
        </div>
      );
    } else {
      return (
        <div className={`payment-item ${clasName ? clasName : ""}`}>
          {<CollapseViettel data={data} />}
        </div>
      );
    }
  };

  return <>{rendderRowItem()}</>;
};

export default RowItem;
