import { useSelector } from "react-redux";
import SelectableItem from "../../../../../Components/SelectableItem/SelectableItem";
import Util from "../../../../../Util/Util";
import { RootState } from "../../../../../Redux/store";
import { queryEnum, selectOptionType } from "../../../../../Types/global.type";
import { useEffect, useState } from "react";
import {
  LoanIDTypeTrans,
  providerNeedBothLoadIDType,
  servicesAutoNeedLoanIDType,
} from "../services/loanIDType.type";

const useGetLoanIDTypeList = () => {
  const [disabled, setDisabled] = useState<boolean>(false);
  const [loanIDTypes, setLoanIDTypes] = useState<selectOptionType[]>([]);
  const provider = useSelector<RootState, selectOptionType | null>(
    (state) => state.billingService.provider
  );

  useEffect(() => {
    setDisabled(!providerNeedBothLoadIDType.includes(provider?.value));
    const { service } = Util.getByQuery([queryEnum.service]);
    const found = servicesAutoNeedLoanIDType.find((a) => a.service === service);
    if (found)
      setLoanIDTypes(
        found.fields.map((a) => {
          return {
            value: a,
            label: Util.convertTrans(a, LoanIDTypeTrans),
            render: (
              <SelectableItem label={Util.convertTrans(a, LoanIDTypeTrans)} />
            ),
            needPhone: false,
          };
        })
      );
  }, [provider]);

  return { loanIDTypes, disabled };
};

export default useGetLoanIDTypeList;
