import { createSlice, Dispatch } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  clearStatusResType,
  confirmState,
  invoiceDataType,
} from "./confirm.type";
import confirmAPI from "./confirm.api";
import Util from "../../../Util/Util";

const initialState: confirmState = {
  refresh: false,

  clearErrorMess: "",

  data: null,
  clearData: null,
};

export const confirmSlice = createSlice({
  name: "confirm",
  initialState,
  reducers: {
    reset: () => initialState,

    setRefresh: (state, action: PayloadAction<boolean>) => {
      state.refresh = action.payload;
    },

    setClearErrorMess: (state, action: PayloadAction<string>) => {
      state.clearErrorMess = action.payload;
    },

    setData: (state, action: PayloadAction<invoiceDataType>) => {
      state.data = action.payload;
    },

    setClearData: (state, action: PayloadAction<clearStatusResType | null>) => {
      state.clearData = action.payload;
    },
  },
});

export const confirmReducer = confirmSlice.reducer;
export const confirmActions = confirmSlice.actions;

export const resetConfirmState = () => (dispatch: Dispatch) => {
  dispatch(confirmActions.reset());
  dispatch(confirmActions.setRefresh(true));
};

export const getInvoicePaymentStatus =
  (
    orderCode: string,
    onSuccess: (e: invoiceDataType) => void,
    onError: () => void
  ) =>
  async (dispatch: Dispatch) => {
    try {
      const res = await confirmAPI.getInvoicePaymentStatusAPI(orderCode);

      if (res.data) onSuccess(res.data);
      else {
        onError();
        Util.showCodeMessWithModal(res, dispatch);
      }
    } catch {
      onError();
    }
  };

export const getClearStatus =
  (
    orderCode: string,
    onSuccess: (e: clearStatusResType) => void,
    onError: () => void
  ) =>
  async (dispatch: Dispatch) => {
    try {
      const res = await confirmAPI.getClearStatusAPI(orderCode);

      if (res.data) onSuccess(res.data);
      else {
        Util.showCodeMessWithModal(res, dispatch);
        onError();
      }
    } catch {
      onError();
    }
  };
