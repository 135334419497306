import React, { ReactNode, useState } from "react";
import "./CustomDrawer.scss";
import { selectOptionType } from "../../../../Types/global.type";
import CustomInput from "../../../CustomInput/CustomInput";
import CustomDrawerHeader from "./CustomDrawerHeader/CustomDrawerHeader";
import ItemsList from "./ItemsList/ItemsList";
import CustomModal from "../../../CustomModal/CustomModal";

interface PT {
  isOpen: boolean;
  options: selectOptionType[];
  placeholder?: string;
  onClose: () => void;
  onChange: (e: selectOptionType) => void;
  showSearch?: boolean;
  noted?: ReactNode;
  needFullHeight?: boolean;
  onFocusSearch?: () => void;
  onBlurSearch?: () => void;
  className?: string;
}

const CustomDrawer: React.FC<PT> = ({
  isOpen,
  options,
  placeholder,
  onClose,
  onChange,
  showSearch,
  noted,
  needFullHeight,
  onFocusSearch,
  onBlurSearch,
  className,
}) => {
  const [search, setSearch] = useState<string>("");

  const close = () => {
    setSearch("");
    onClose();
  };

  const change = (e: selectOptionType) => {
    onChange(e);
    close();
  };

  return (
    <CustomModal
      isShowing={isOpen}
      onCancel={close}
      className={`customized-select-drawer${className ? " " + className : ""} ${
        needFullHeight
          ? "customized-select-drawer-full"
          : "customized-select-drawer-half"
      }`}
    >
      <div className="customized-select-drawer-wrap">
        <CustomDrawerHeader placeholder={placeholder} close={close} />
        {noted && noted}
        {showSearch && (
          <CustomInput
            onFocus={onFocusSearch}
            onBlur={onBlurSearch}
            onChange={setSearch}
            placeholder="Tìm kiếm"
            className="customized-select-drawer-search"
            prefix={
              <div className="customized-select-drawer-search-icon"></div>
            }
            allowClear
          />
        )}

        <ItemsList options={options} search={search} onChange={change} />
      </div>
    </CustomModal>
  );
};

export default React.memo(CustomDrawer);
