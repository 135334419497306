import { useNavigate } from "react-router-dom";
import { MainRoutesEnum } from "../../../Types/routes.type";
import { paymentActions } from "../services/payment.slice";
import { useAppDispatch } from "../../../Redux/store";
import Util from "../../../Util/Util";
import { queryEnum } from "../../../Types/global.type";
// import { serviceTypeEnum } from "../../Auth/services/auth.type";

const useGetBack = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const onBack = () => {
    dispatch(paymentActions.reset());

    const { service } = Util.getByQuery([queryEnum.service]);

    let mainPath = "";

    switch (service) {
      // case serviceTypeEnum["TRUYEN-HINH"]:
      //   mainPath += MainRoutesEnum.BILLING + "/" + MainRoutesEnum.TV_INTERNET;
      //   break;

      // case serviceTypeEnum.INTERNET:
      //   mainPath += MainRoutesEnum.BILLING + "/" + MainRoutesEnum.TV_INTERNET;
      //   break;

      default:
        mainPath += MainRoutesEnum.BILLING;
        break;
    }

    navigate(`/${mainPath}${window.location.search}`);
  };

  const onBackToHomePage = () => {
    dispatch(paymentActions.reset());
    navigate(MainRoutesEnum.INVOICES);
  };

  return { onBack, onBackToHomePage };
};

export default useGetBack;
