import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../Redux/store";
import { authActions } from "../../../Containers/Auth/services/auth.slice";
import { createOrder } from "../../../Containers/Payment/services/payment.slice";
import {
  createOrderResType,
  paymentMethodCode,
  paymentMethodEnum,
} from "../../../Containers/Payment/services/payment.type";
import { MainRoutesEnum } from "../../../Types/routes.type";
import Util from "../../../Util/Util";
import { useState } from "react";
import { selectOptionType, themeTrans } from "../../../Types/global.type";
import useGetSubmitData from "./useGetSubmitData";

const usePayment = (isNeedPayment: boolean) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isOpen, setOpen] = useState<boolean>(false);
  const [isOpenPaymentList, setOpenPaymentList] = useState<boolean>(false);
  const { createSubmitData } = useGetSubmitData();

  const onSubmit = () => {
    if (isNeedPayment) {
      const theme = document
        .getElementsByTagName("HTML")[0]
        .getAttribute("data-theme");

      if (theme === themeTrans.EPAY_PARTNER) onPayment();
      else setOpenPaymentList(true);
    } else navigate(MainRoutesEnum.INVOICES);
  };

  const createOrderSuccess = (data: createOrderResType) => {
    if (data.deepLink) window.location.href = data.deepLink;
    else window.location.href = data.paymentUrl;
  };

  const createOrderError = () => {
    dispatch(authActions.setLoading(false));
  };

  const onPayment = () => {
    dispatch(authActions.setLoading(true));
    dispatch(
      createOrder(
        createSubmitData(paymentMethodCode.WALLET),
        createOrderSuccess,
        createOrderError
      )
    );
  };

  const onChangeValue = (e: selectOptionType) => {
    if (e.value === paymentMethodEnum.OTHER) setOpen(true);
    else {
      onClose();
      // dispatch(authActions.setLoading(true));
      dispatch(
        createOrder(
          createSubmitData(Util.convertTrans(e.value, paymentMethodCode)),
          createOrderSuccess,
          createOrderError
        )
      );
    }
  };

  const onClose = () => {
    setOpenPaymentList(false);
  };

  return {
    onSubmit,
    onChangeValue,
    onClose,
    isOpenPaymentList,
    isOpen,
    setOpen,
  };
};

export default usePayment;
