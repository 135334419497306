import React from "react";
import "./PhoneProviders.scss";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../../Redux/store";
import CustomButton from "../../../../Components/CustomButton/CustomButton";
import LayoutWithTitle from "../../../../Components/ErrorMessModal/LayoutWithTitle/LayoutWithTitle";
import { selectOptionType } from "../../../../Types/global.type";
import { availablePhoneServiceEnum } from "../../services/topupService.type";
import { topupServiceActions } from "../../services/topupService.slice";

const PhoneProviders = () => {
  const dispatch = useAppDispatch();
  const selectedService = useSelector<RootState, selectOptionType | null>(
    (state) => state.topupService.selectedService
  );

  const onChange = (a: availablePhoneServiceEnum | string) => {
    dispatch(
      topupServiceActions.setSelectedService({
        value: a,
        label: a,
        selectedRender: (
          <div
            className={`selected-service-icon selected-service-icon-${a}`}
          ></div>
        ),
      })
    );
  };

  return (
    <LayoutWithTitle
      title="Chọn nhà mạng"
      className="phone-providers-container"
    >
      {Object.values(availablePhoneServiceEnum).map((a, i) => (
        <CustomButton
          key={i}
          className={`selected-service-icon selected-service-icon-${a} ${
            selectedService?.value === a ? "phone-providers-active" : ""
          }`}
          onClick={() => {
            onChange(a);
          }}
          title=""
        />
      ))}
    </LayoutWithTitle>
  );
};

export default React.memo(PhoneProviders);
