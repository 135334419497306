import { createSlice, Dispatch } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  ALL,
  createTopupServiceOrderReqType,
  dataComboEnum,
  dataMethodEnum,
  recentlyPhoneType,
  topupServiceState,
} from "./topupService.type";
import topupServiceAPI from "./topupService.api";
import Util from "../../../Util/Util";
import { selectOptionType } from "../../../Types/global.type";
import { createOrderResType } from "../../Payment/services/payment.type";

const initialState: topupServiceState = {
  phone: "",

  denos: [],
  denomination: 0,
  denominationDownload: 0,
  dataMethod: dataMethodEnum.TOPUP_DATA,
  dataCombo: dataComboEnum.COMBO,
  quantity: 1,
  selectedService: null,

  amountDataCombo: 0,
  timePackage: ALL,

  services: [],
  recentlyPhone: [],
};

export const topupServiceSlice = createSlice({
  name: "topupService",
  initialState,
  reducers: {
    reset: () => initialState,

    setPhone: (state, action: PayloadAction<string>) => {
      state.phone = action.payload;
    },

    setDenos: (state, action: PayloadAction<number[]>) => {
      state.denos = action.payload;
    },

    setDenomination: (state, action: PayloadAction<number>) => {
      state.denomination = action.payload;
    },

    setDenominationDownload: (state, action: PayloadAction<number>) => {
      state.denominationDownload = action.payload;
    },

    setQuantity: (state, action: PayloadAction<number>) => {
      state.quantity = action.payload;
    },

    setDataMethod: (state, action: PayloadAction<dataMethodEnum>) => {
      state.dataMethod = action.payload;
    },

    setDataComboMethod: (state, action: PayloadAction<dataComboEnum>) => {
      state.dataCombo = action.payload;
    },

    setAmountDataCombo: (state, action: PayloadAction<number>) => {
      state.amountDataCombo = action.payload;
    },

    setTimePackage: (state, action: PayloadAction<string>) => {
      state.timePackage = action.payload;
    },

    setServices: (state, action: PayloadAction<selectOptionType[]>) => {
      state.services = action.payload;
    },

    setSelectedService: (
      state,
      action: PayloadAction<selectOptionType | null>
    ) => {
      state.selectedService = action.payload;
    },

    setRecentlyPhone: (state, action: PayloadAction<recentlyPhoneType[]>) => {
      state.recentlyPhone = action.payload;
    },
  },
});

export const topupServiceReducer = topupServiceSlice.reducer;
export const topupServiceActions = topupServiceSlice.actions;

export const resetTopupServiceState = () => (dispatch: Dispatch) => {
  dispatch(topupServiceActions.reset());
};

export const createTopupServiceOrder =
  (
    data: createTopupServiceOrderReqType,
    onSuccess: (e: createOrderResType) => void,
    onError: () => void
  ) =>
  async (dispatch: Dispatch) => {
    try {
      const res = await topupServiceAPI.createTopupServiceOrderAPI(data);

      if (res.data) onSuccess(res.data);
      else {
        Util.showCodeMessWithModal(res, dispatch);
        onError();
      }
    } catch {
      onError();
    }
  };

export const getRecentlyPhone =
  (onSuccess: (e: recentlyPhoneType[]) => void, onError: () => void) =>
  async (dispatch: Dispatch) => {
    try {
      const res = await topupServiceAPI.getRecentlyPhoneAPI();

      if (res.data) onSuccess(res.data);
      else {
        Util.showCodeMessWithModal(res, dispatch);
        onError();
      }
    } catch {
      onError();
    }
  };
