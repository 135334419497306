import React, { useEffect } from "react";
import "./Invoices.scss";
import useGetServices from "./hooks/useGetServices";
import RegularServices from "./components/RegularServices/RegularServices";
import SavedInvoices from "./components/SavedInvoices/SavedInvoices";
import AllServices from "./components/AllServices/AllServices";
import { useAppDispatch } from "../../Redux/store";
import { invoicesActions } from "./services/invoices.slice";
import { themeTrans } from "../../Types/global.type";

const Invoices = () => {
  const dispatch = useAppDispatch();
  const {
    renderServiceItems,
    renderTOPUPServiceItems,
    renderServiceItemsFull,
  } = useGetServices();

  useEffect(() => {
    return () => {
      dispatch(invoicesActions.setOpenMore(false));
    };
  }, []);

  const theme = document
    .getElementsByTagName("HTML")[0]
    .getAttribute("data-theme");

  return (
    <div className="invoices-warp">
      <RegularServices items={renderServiceItems} />
      {theme !== themeTrans.EPAY_PARTNER && (
        <RegularServices items={renderTOPUPServiceItems} isTopup />
      )}

      <SavedInvoices />
      <AllServices items={renderServiceItemsFull} />
    </div>
  );
};

export default React.memo(Invoices);
