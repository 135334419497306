import React, { useEffect, useState } from "react";
import "./DataPackge.scss";
import LayoutWithTitle from "../../../../Components/ErrorMessModal/LayoutWithTitle/LayoutWithTitle";
import DataPackageItem from "../DataPackageItem/DataPackageItem";
import { topupServiceActions } from "../../services/topupService.slice";
import { useAppDispatch } from "../../../../Redux/store";

const fakeData = [
  {
    title: "Gói 30 ngày",
    packages: [
      {
        amount: 70000,
        size: "500MB",
        duration: "1 ngày",
        id: "dashdkjash",
      },
      {
        amount: 90000,
        size: "10GB",
        duration: "1 ngày",
        id: "dashdkjas2h",
      },
      {
        amount: 30000,
        size: "100MB",
        duration: "3 ngày",
        id: "dashdkjasdsh",
      },
    ],
  },
  {
    title: "Gói 7 ngày",
    packages: [
      {
        amount: 70000,
        size: "500MB",
        duration: "1 ngày",
        id: "dashdkjashdsds",
      },
      {
        amount: 90000,
        size: "10GB",
        duration: "1 ngày",
        id: "dashdkjasdsdsh",
      },
      {
        amount: 30000,
        size: "100MB",
        duration: "3 ngày",
        id: "dashdkjassasash",
      },
      {
        amount: 300000,
        size: "700MB",
        duration: "10 ngày",
        id: "dashdkjasdsdsdh",
      },
    ],
  },
  {
    title: "Gói 10 ngày",
    packages: [
      {
        amount: 70000,
        size: "500MB",
        duration: "1 ngày",
        id: "dashdkjassdasdash",
      },
      {
        amount: 90000,
        size: "10GB",
        duration: "1 ngày",
        id: "dashdkjasafdfdsh",
      },
      {
        amount: 30000,
        size: "100MB",
        duration: "3 ngày",
        id: "dashasdwdkjash",
      },
      {
        amount: 300000,
        size: "700MB",
        duration: "10 ngày",
        id: "eqwecdashdkjash",
      },
      {
        amount: 300000,
        size: "700MB",
        duration: "10 ngày",
        id: "dashdkjashsdmi3uu8",
      },
    ],
  },
];

const DataPackge = () => {
  const dispatch = useAppDispatch();
  const [itemActive, setItemActive] = useState("dashdkjash");

  useEffect(() => {
    dispatch(
      topupServiceActions.setAmountDataCombo(fakeData[0].packages[0].amount)
    );
  }, []);

  return (
    <div className="data-package-container">
      {fakeData.map((a, i) => (
        <LayoutWithTitle title={a.title} key={i}>
          {a.packages.map((b, j) => (
            <DataPackageItem
              key={j}
              data={b}
              isActive={itemActive === b.id}
              setItemActive={setItemActive}
            />
          ))}
        </LayoutWithTitle>
      ))}
    </div>
  );
};

export default React.memo(DataPackge);
