import { createSlice, Dispatch } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { ReactNode } from "react";
import {
  authState,
  getContractsReqType,
  getInvoiceReqType,
  serviceType,
  VNeIDSessionResType,
  walletStatusEnum,
  walletStatusResType,
} from "./auth.type";
import authAPI from "./auth.api";
import Util from "../../../Util/Util";
import { getContractsResType, getInvoiceResType } from "../../Payment/services/payment.type";

const initialState: authState = {
  isAuth: false,
  loading: false,

  loadingGetService: false,
  loadingGetSavedInvoices: false,

  errorMess: "",
  isErrorModalOpen: false,

  services: [],
  existedEpay: null,
  existedTrain: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuth: (state, action: PayloadAction<boolean>) => {
      state.isAuth = action.payload;
    },

    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    setLoadingGetService: (state, action: PayloadAction<boolean>) => {
      state.loadingGetService = action.payload;
    },

    setLoadingGetSavedInvoices: (state, action: PayloadAction<boolean>) => {
      state.loadingGetSavedInvoices = action.payload;
    },

    setErrorMess: (state, action: PayloadAction<ReactNode>) => {
      state.errorMess = action.payload;
      state.isErrorModalOpen = true;
    },

    setErrorModalClose: (state) => {
      state.isErrorModalOpen = false;
    },

    setServices: (state, action: PayloadAction<serviceType[]>) => {
      state.services = action.payload;
    },

    setExistedEpay: (state, action: PayloadAction<walletStatusEnum>) => {
      state.existedEpay = action.payload;
    },

    setExistedTrain: (state, action: PayloadAction<boolean>) => {
      state.existedTrain = action.payload;
    },
  },
});

export const authReducer = authSlice.reducer;
export const authActions = authSlice.actions;

export const getServiceList =
  (onSuccess: (e: serviceType[]) => void, onError: () => void) =>
  async (dispatch: Dispatch) => {
    try {
      const res = await authAPI.getServiceListAPI();

      if (res.data) onSuccess(res.data);
      else {
        onError();
        Util.showCodeMessWithModal(res, dispatch);
      }
    } catch {
      onError();
    }
  };

export const getInvoice =
  (
    data: getInvoiceReqType,
    onSuccess: (e: getInvoiceResType) => void,
    onError: (res?: any) => void
  ) =>
  async () => {
    try {
      const res = await authAPI.getInvoiceAPI(data);

      if (res.data) onSuccess(res.data);
      else onError(res);
    } catch {
      onError();
    }
  };

// [BILLING] - Lấy danh sách hợp đòng theo số điện thoại
export const getContracts =
  (
    data: getContractsReqType,
    onSuccess: (e: getContractsResType[]) => void,
    onError: (res?: any) => void
  ) =>
  async () => {
    try {
      const res = await authAPI.getContractsAPI(data);

      if (res.data) onSuccess(res.data);
      else onError(res);
    } catch {
      onError();
    }
  };

export const getSessionFromSSOC =
  (
    ssoCode: string,
    onSuccess: (e: VNeIDSessionResType) => void,
    onError: () => void
  ) =>
  async () => {
    try {
      const res = await authAPI.getSessionFromSSOCAPI(ssoCode);

      if (res.data) onSuccess(res.data);
      else {
        onError();
      }
    } catch {
      onError();
    }
  };

export const getWalletStatus =
  (onSuccess: (e: walletStatusResType) => void, onError: () => void) =>
  async (dispatch: Dispatch) => {
    try {
      const res = await authAPI.getWalletStatusAPI();

      if (res.data) onSuccess(res.data);
      else {
        Util.showCodeMessWithModal(res, dispatch);
        onError();
      }
    } catch {
      onError();
    }
  };
