import { useEffect, useState } from "react";
import {
  availableService,
  availableTopupService,
  serviceType,
  serviceTypeEnum,
} from "../../../Containers/Auth/services/auth.type";
import { useSelector } from "react-redux";
import { RootState } from "../../../Redux/store";
import Util from "../../../Util/Util";

// Tính toán thêm lượng null items
const countNumberOfNullItems = (filteredArray: serviceTypeEnum[]) => {
  const filteredArrayLength = filteredArray.length;
  const numberOfNullItems =
    Math.ceil(filteredArrayLength / 4) * 4 - filteredArrayLength;
  const nullItems = new Array(numberOfNullItems).fill(null);
  return nullItems;
};

const useGetServices = () => {
  const services = useSelector<RootState, serviceType[]>(
    (state) => state.auth.services
  );
  const existedTrain = useSelector<RootState, boolean>(
    (state) => state.auth.existedTrain
  );
  const [renderServiceItems, setRenderServiceItems] = useState<
    (serviceTypeEnum | null)[]
  >([]);
  const [renderServiceItemsFull, setRenderServiceItemsFull] = useState<
    (serviceTypeEnum | null)[]
  >([]);
  const [renderTOPUPServiceItems, setRenderTOPUPServiceItems] = useState<
    (serviceTypeEnum | null)[]
  >([]);

  useEffect(() => {
    // Lấy các dịch vụ thanh toán hóa đơn
    const activeServices = Util.getUniqueData(services, "serviceType").map(
      (a) => a.serviceType
    );
    let filteredArray = [
      ...Object.values(serviceTypeEnum)
        .filter((a) => availableService.includes(a))
        .filter((a) => activeServices.includes(a)),
    ];

    // Thêm riêng DV vé tàu
    if (existedTrain) filteredArray = [...filteredArray, serviceTypeEnum.TRAIN];

    // Lấy full
    setRenderServiceItemsFull([
      ...filteredArray,
      ...countNumberOfNullItems(filteredArray),
    ]);

    // Cắt ngắn để thêm nút see more
    if (filteredArray.length > 8)
      filteredArray = [...filteredArray.slice(0, 7), serviceTypeEnum.MORE];

    setRenderServiceItems([
      ...filteredArray,
      ...countNumberOfNullItems(filteredArray),
    ]);
  }, [services, existedTrain]);

  useEffect(() => {
    // Chỉ lấy các dịch vụ TOPUP
    const filteredArray = Object.values(serviceTypeEnum).filter((a) =>
      availableTopupService.includes(a)
    );

    setRenderTOPUPServiceItems([
      ...filteredArray,
      ...countNumberOfNullItems(filteredArray),
    ]);
  }, []);

  return {
    renderServiceItems,
    renderTOPUPServiceItems,
    renderServiceItemsFull,
  };
};

export default useGetServices;
