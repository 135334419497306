import React, { useEffect } from "react";
import useRenderData from "../../../hooks/useRenderData";
import RowItem from "../RowItem/RowItem";
import ItemContract from "../ItemContract/ItemContract";
import { useAppDispatch } from "../../../../../Redux/store";
import { subbillingServiceActions } from "../../../services/subBillingService.slice";

const ListContracts = () => {
  const { headerListContracts, dataContracts1 } = useRenderData();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(subbillingServiceActions.setIdCheckboxContract(''));
  }, []);

  return (
    <div>
      {headerListContracts.map((e, i) => (
        <RowItem
          text={e.text ?? ""}
          title={e.title}
          key={i}
          clasName={headerListContracts.length - 1 === i ? "no-underline" : ""}
        />
      ))}

      <>
        <div className="contracts-title">Danh sách hợp đồng</div>

        {dataContracts1.map((e, i) => (
          <ItemContract key={i} data={e} />
        ))}
      </>
    </div>
  );
};

export default ListContracts;
