import { serviceTypeEnum } from "../../../Auth/services/auth.type";
import { dataMethodEnum } from "../../services/topupService.type";
import PhoneProviders from "../PhoneProviders/PhoneProviders";
import PhoneInput from "../PhoneInput/PhoneInput";
import { useSelector } from "react-redux";
import { RootState } from "../../../../Redux/store";
import Denominations from "../Denominations/Denominations";

interface IPropsTopUpPhone {
  errorPhone: boolean;
  service: serviceTypeEnum;
  setErrorPhone: React.Dispatch<React.SetStateAction<boolean>>;
  onChangePhone: (e: string) => void;
}

const TopUpPhone = ({
  service,
  errorPhone,
  onChangePhone,
  setErrorPhone,
}: IPropsTopUpPhone) => {
  const dataMethod = useSelector<RootState, dataMethodEnum>(
    (state) => state.topupService.dataMethod
  );

  return (
    <>
      {service === serviceTypeEnum.PHONE && (
        <>
          {dataMethod === dataMethodEnum.TOPUP_DOWNLOAD && <PhoneProviders />}
          {dataMethod === dataMethodEnum.TOPUP_DATA && (
            <PhoneInput
              errorPhone={errorPhone}
              setErrorPhone={setErrorPhone}
              setPhone={onChangePhone}
            />
          )}

          <Denominations />
        </>
      )}
    </>
  );
};

export default TopUpPhone;
