import { useEffect, useState } from "react";
import { useAppDispatch } from "../../../Redux/store";
import { topupServiceActions } from "../services/topupService.slice";

interface IPropsUseScrollitem {
  containerRef: React.RefObject<HTMLDivElement>;
}

const useScrollitem = ({ containerRef }: IPropsUseScrollitem) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (containerRef.current) {
      const container = containerRef.current;
      const activeItem = container.children[activeIndex] as HTMLElement;
      if (activeItem) {
        const containerWidth = container.offsetWidth;
        const itemWidth = activeItem.offsetWidth;
        const scrollLeft =
          activeItem.offsetLeft - (containerWidth / 2 - itemWidth / 2);
        container.scrollTo({ left: scrollLeft, behavior: "smooth" });
      }
    }
  }, [activeIndex]);

  const handleClick = (index: number, value: string) => {
    setActiveIndex(index);
    dispatch(topupServiceActions.setTimePackage(value));
  };

  return { activeIndex, handleClick };
};

export default useScrollitem;
