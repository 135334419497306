import React, { useEffect } from "react";
import useRenderData from "../../../hooks/useRenderData";
import RowItem from "../RowItem/RowItem";
import ItemContractDetail from "../ItemContractDetail/ItemContractDetail";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../../../Redux/store";
import useGetInvoice from "../../../../Payment/hooks/useGetInvoice";
import { getInvoiceResType } from "../../../../Payment/services/payment.type";
import CollapseViettel from "../CollapseViettel/CollapseViettel";
import { subbillingServiceActions } from "../../../services/subBillingService.slice";
import { EnumTypeScreen } from "../../../services/subBillingService.type";

interface IPropsContractDetail {
  onBack: () => void;
}

const ContractDetail = ({ onBack }: IPropsContractDetail) => {
  const dispatch = useAppDispatch();
  const { billDetail, headerDetailContracts } = useRenderData();
  const invoice = useSelector<RootState, getInvoiceResType | null>(
    (state) => state.payment.invoice
  );

  useGetInvoice(onBack);

  useEffect(() => {
    dispatch(subbillingServiceActions.setTypeScreen(EnumTypeScreen.DETAIL));
  }, []);

  return (
    <div>
      {invoice && (
        <>
          {headerDetailContracts
            .filter((e) => !e.isShown)
            .map((e, i) => (
              <RowItem
                text={e.text ?? ""}
                title={e.title}
                key={i}
                clasName={
                  headerDetailContracts.length - 1 === i
                    ? "no-underline text-red"
                    : ""
                }
              />
            ))}

          {billDetail && (
            <>
              {billDetail.length > 1 &&
                billDetail.map((e, i: number) => (
                  <ItemContractDetail key={i} data={e} />
                ))}

              {billDetail.length === 1 && (
                <div className="border-t mt-2">
                  <CollapseViettel data={billDetail[0]} />
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default ContractDetail;
