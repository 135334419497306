import React from "react";
import { serviceTypeEnum } from "../../../Auth/services/auth.type";
import { useSelector } from "react-redux";
import { RootState } from "../../../../Redux/store";
import { dataComboEnum } from "../../services/topupService.type";
import PhoneProviders from "../PhoneProviders/PhoneProviders";
import PhoneInput from "../PhoneInput/PhoneInput";
import DataPackge from "../DataPackge/DataPackge";
import HorizontalScrollList from "../HorizontalScrollList/HorizontalScrollList";
import ConponItem from "../ComboPackage/ConponItem";
import Quantity from "../Quantity/Quantity";

interface IPropsDataCombo {
  errorPhone: boolean;
  service: serviceTypeEnum;
  setErrorPhone: React.Dispatch<React.SetStateAction<boolean>>;
  onChangePhone: (e: string) => void;
}

const DataCombo = ({
  service,
  errorPhone,
  onChangePhone,
  setErrorPhone,
}: IPropsDataCombo) => {
  const dataCombo = useSelector<RootState, dataComboEnum>(
    (state) => state.topupService.dataCombo
  );

  return (
    <>
      {service === serviceTypeEnum.DATA && (
        <>
          {dataCombo === dataComboEnum.DATA_CARD && <PhoneProviders />}
          {dataCombo === dataComboEnum.COMBO && (
            <PhoneInput
              errorPhone={errorPhone}
              setErrorPhone={setErrorPhone}
              setPhone={onChangePhone}
            />
          )}
          {dataCombo === dataComboEnum.LOAD_DATA && (
            <PhoneInput
              errorPhone={errorPhone}
              setErrorPhone={setErrorPhone}
              setPhone={onChangePhone}
            />
          )}

          {dataCombo !== dataComboEnum.COMBO && <HorizontalScrollList />}

          {dataCombo === dataComboEnum.LOAD_DATA && <DataPackge />}
          {dataCombo === dataComboEnum.DATA_CARD && <DataPackge />}

          {dataCombo === dataComboEnum.COMBO && <ConponItem />}

          {dataCombo === dataComboEnum.DATA_CARD && <Quantity />}
        </>
      )}
    </>
  );
};

export default DataCombo;
