import { createSlice, Dispatch } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  createOrderReqType,
  createOrderResType,
  eduBillDetailType,
  getInvoiceResType,
  paymentState,
} from "./payment.type";
import paymentAPI from "./payment.api";
import Util from "../../../Util/Util";

const initialState: paymentState = {
  eduTotal: [],
  amount: 0,
  invoice: null,
  disabledSubmit: false,
  amountError: false,
};

export const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    reset: () => initialState,

    setEduTotal: (state, action: PayloadAction<eduBillDetailType[]>) => {
      state.eduTotal = action.payload;
    },

    setAmount: (state, action: PayloadAction<number>) => {
      state.amount = action.payload;
    },

    setInvoice: (state, action: PayloadAction<getInvoiceResType | null>) => {
      state.invoice = action.payload;
    },

    setDisabledSubmit: (state, action: PayloadAction<boolean>) => {
      state.disabledSubmit = action.payload;
    },

    setAmountError: (state, action: PayloadAction<boolean>) => {
      state.amountError = action.payload;
    },
  },
});

export const paymentReducer = paymentSlice.reducer;
export const paymentActions = paymentSlice.actions;

export const createOrder =
  (
    data: createOrderReqType,
    onSuccess: (e: createOrderResType) => void,
    onError: () => void
  ) =>
  async (dispatch: Dispatch) => {
    try {
      const res = await paymentAPI.createOrderAPI(data);

      if (res.data) onSuccess(res.data);
      else {
        Util.showCodeMessWithModal(res, dispatch);
        onError();
      }
    } catch {
      onError();
    }
  };
