import { useEffect, useState } from "react";
import { RootState } from "../../../Redux/store";
import { useSelector } from "react-redux";
import { savedInvoiceType } from "../services/invoices.type";
import { themeTrans } from "../../../Types/global.type";

const useGetSavedInvoices = () => {
  const [renderItems, setRenderItems] = useState<(savedInvoiceType | null)[][]>(
    []
  );
  const savedInvoices = useSelector<RootState, savedInvoiceType[] | null>(
    (state) => state.invoices.savedInvoices
  );

  const theme = document
    .getElementsByTagName("HTML")[0]
    .getAttribute("data-theme");

  const numberOfServiceBlock = theme === themeTrans.EPAY_PARTNER ? 1 : 2;

  const setupRenderItems = (rowRaw: number, rawData: savedInvoiceType[]) => {
    const row = Math.max(1, rowRaw);
    const numberItemPerPage = row * 2;

    const numberOfAddedItems =
      numberItemPerPage -
      (rawData.length % numberItemPerPage !== 0
        ? rawData.length % numberItemPerPage
        : numberItemPerPage);

    const addedItems = new Array(numberOfAddedItems).fill(null);
    const totalItem = [...rawData, ...addedItems];
    let convertedItems: (savedInvoiceType | null)[][] = [];
    for (let i = 0; i < totalItem.length / numberItemPerPage; i++) {
      convertedItems = [
        ...convertedItems,
        totalItem.slice(i * numberItemPerPage, (i + 1) * numberItemPerPage),
      ];
    }
    setRenderItems(convertedItems);
  };

  const getRowsPerPage = () => {
    const vh = window.innerHeight;
    const vw = window.innerWidth;
    const adjustVw = vw / 414;
    const serviceItem =
      18 + // margin bot service item
      8 + // margin bot service icon
      (50 + // size service icon
        32) * // font-size service item
        adjustVw;

    const serviceTitle =
      20 + // margin bot service title
      20 * // font-size service title
        adjustVw;

    const totalHeight =
      vh -
      35 - // padding top overall
      13 * numberOfServiceBlock - // margin bot service block
      serviceItem * (numberOfServiceBlock + 1) -
      serviceTitle * numberOfServiceBlock;

    const itemHeight = 10 + 10 + 10 + 6 + 6 + (16 + 16 + 17) * adjustVw;
    return Math.max(1, Math.floor(totalHeight / itemHeight)) - 1;
  };

  useEffect(() => {
    if (savedInvoices && savedInvoices.length !== 0) {
      const row = getRowsPerPage();
      setupRenderItems(row, savedInvoices);
    }
  }, [savedInvoices]);

  return { renderItems };
};

export default useGetSavedInvoices;
