import React from "react";
import "./ProvinceField.scss";
import CustomField from "../CustomField/CustomField";
import useGetProvinceList from "./hooks/useGetProvinceList";
import { RootState } from "../../../../Redux/store";
import { useSelector } from "react-redux";
import useGetProvince from "./hooks/useGetProvince";
import { fieldEnum } from "../../services/billingService.type";


const ProvinceField = () => {
  const needProvince = useSelector<RootState, boolean>(
    (state) => state.billingService.needProvince
  );
  const { provinces } = useGetProvinceList();
  const { initProvince, setProvince } = useGetProvince();

  return needProvince ? (
    <CustomField
      type={fieldEnum.SELECT}
      title="Tỉnh thành"
      placeholder="Chọn tỉnh thành"
      setValue={setProvince}
      initValue={initProvince}
      selectProp={{
        showSearch: true,
        needFullHeight: true,
        options: provinces,
      }}
    />
  ) : (
    <></>
  );
};

export default React.memo(ProvinceField);
