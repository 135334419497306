import { ReactNode } from "react";

export interface authState {
  isAuth: boolean;
  loading: boolean;

  loadingGetService: boolean;
  loadingGetSavedInvoices: boolean;

  errorMess: ReactNode;
  isErrorModalOpen: boolean;

  services: serviceType[];
  existedEpay: walletStatusEnum | null;
  existedTrain: boolean;
}

export interface serviceType {
  category: serviceCategoryEnum;
  code: string;
  id: number;
  name: string;
  type: serviceTypeEnum;
  serviceType: serviceTypeEnum;
}

export interface getInvoiceReqType {
  serviceCode: string;
  billingCode: string;
  provinceCode?: string;
}

export interface getContractsReqType {
  phoneNumber: string;
}

export interface VNeIDSessionResType {
  session: string;
  walletStatus: walletStatusEnum;
}

export const TRUYEN_HINH_VIETTEL = "TRUYEN-HINH-VIETTEL";
export const INTERNET_VIETTEL_TRA_SAU = "INTERNET-VIETTEL-TRA-SAU";

export enum walletStatusEnum {
  "EXIST" = 2000,
  "NOT-EXIST" = 2001,
  "NOT-VERIFIED" = 2002,
  "NOT-MATCH-ID" = 2003,
  "LOCKED" = 2004,
  "NOT-LINKED-BANK" = 2005,
  "NOT-OTP-CONFIRM" = 2006,
  "PLEASE-CHECK-ID" = 2009,
  "NOT-EXIST-PLEASE-CHECK-ID" = 2010,
}

export enum walletStatusTrans {
  "EXIST" = "Tài khoản đã tồn tại",
  "NOT-EXIST" = "Tài khoản chưa tồn tại",
  "NOT-VERIFIED" = "Tài khoản chưa được xác thực",
  "NOT-MATCH-ID" = "CCCD và số điện thoại không trùng khớp",
  "LOCKED" = "Tài khoản ví bị khóa",
  "NOT-LINKED-BANK" = "Tài khoản ví chưa liên kết ngân hàng",
  "NOT-OTP-CONFIRM" = "Tài khoản ví chưa kích hoạt SmartOTP",
  "PLEASE-CHECK-ID" = "Đã tồn tại tài khoản ví. Vui lòng kiểm tra lại thông tin căn cước công dân",
  "NOT-EXIST-PLEASE-CHECK-ID" = "Chưa tồn tại tài khoản ví. Vui lòng kiểm tra lại thông tin căn cước công dân",
}

export enum serviceCategoryEnum {
  BILLING = "BILLING",
  TOPUP = "TOPUP",
  TOPUP_DOWNLOAD = "TOPUP_DOWNLOAD",
  TOPUP_DATA = "TOPUP_DATA",
}

export enum serviceTypeEnum {
  DIEN = "DIEN",
  NUOC = "NUOC",
  "TRUYEN-HINH" = "TRUYEN-HINH",
  INTERNET = "INTERNET",

  "VIEN-THONG-TRA-SAU" = "VIEN-THONG-TRA-SAU",
  "VIEN-THONG-CO-DINH" = "VIEN-THONG-CO-DINH",
  "TAI-CHINH" = "TAI-CHINH",

  "VAN-TAI" = "VAN-TAI",
  EDU = "EDU",
  "BAO-HIEM" = "BAO-HIEM",
  TRAIN = "TRAIN",

  PHONE = "PHONE",
  DATA = "DATA",

  MORE = "MORE",
}

export enum serviceTrans {
  DIEN = "Điện",
  NUOC = "Nước",
  "TRUYEN-HINH" = "Truyền hình",
  INTERNET = "Internet",

  "VIEN-THONG-TRA-SAU" = "Di động trả sau",
  "VIEN-THONG-CO-DINH" = "Điện thoại cố định",
  "TAI-CHINH" = "Khoản vay",

  "VAN-TAI" = "Vé máy bay",
  EDU = "Thanh toán học phí",
  "BAO-HIEM" = "Phí bảo hiểm",
  TRAIN = "Vé tàu hỏa",

  PHONE = "Nạp tiền điện thoại",
  DATA = "3G/4G",

  MORE = "Xem thêm",
}

export enum serviceTransFull {
  DIEN = "Điện",
  NUOC = "Nước",
  "TRUYEN-HINH" = "Truyền hình",
  INTERNET = "Internet",

  "VIEN-THONG-TRA-SAU" = "Di động trả sau",
  "VIEN-THONG-CO-DINH" = "Điện thoại cố định",
  "TAI-CHINH" = "Thanh toán khoản vay",

  "VAN-TAI" = "Thanh toán vé máy bay",
  EDU = "Thanh toán học phí",
  "BAO-HIEM" = "Thanh toán phí bảo hiểm",
  TRAIN = "Thanh toán vé tàu hỏa",

  PHONE = "Nạp tiền điện thoại",
  DATA = "3G/4G",

  MORE = "Xem thêm",
}

export const availableTopupService = [
  serviceTypeEnum.PHONE,
  serviceTypeEnum.DATA,
];

export const availableService = [
  serviceTypeEnum.DIEN,
  serviceTypeEnum.NUOC,
  serviceTypeEnum["TRUYEN-HINH"],
  serviceTypeEnum.INTERNET,
  serviceTypeEnum["VIEN-THONG-TRA-SAU"],
  serviceTypeEnum["VIEN-THONG-CO-DINH"],
  serviceTypeEnum["TAI-CHINH"],
  serviceTypeEnum["VAN-TAI"],
  serviceTypeEnum.EDU,
  serviceTypeEnum["BAO-HIEM"],
];

export interface walletStatusResType {
  walletStatus: walletStatusEnum;
}
