import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../Redux/store";
import {
  clearStatusEnum,
  clearStatusResType,
  clearStatusTrans,
} from "../services/confirm.type";
import Util from "../../../Util/Util";
import { serviceCategoryEnum } from "../../Auth/services/auth.type";

const useGetTextConfirmFirst = () => {
  const [transType, setTransType] = useState<string>("");
  const [clearStatus, setClearStatus] = useState<string>("");
  const clearData = useSelector<RootState, clearStatusResType | null>(
    (state) => state.confirm.clearData
  );

  useEffect(() => {
    if (clearData) {
      const successOrFailedClearStatus = [
        clearStatusEnum.SUCCESS,
        clearStatusEnum.FAIL,
      ].includes(clearData.transStatus);

      setTransType(
        successOrFailedClearStatus
          ? clearData.categoryType !== serviceCategoryEnum.BILLING
            ? "Nạp tiền ĐT"
            : "Giao dịch"
          : ""
      );

      const translatedTransStatus = Util.convertTrans(
        Util.convertTransBackVer(
          clearData.transStatus.toString(),
          clearStatusEnum
        ),
        clearStatusTrans
      );

      setClearStatus(
        successOrFailedClearStatus
          ? translatedTransStatus.toLowerCase()
          : translatedTransStatus
      );
    }
  }, [clearData]);

  return { transType, clearStatus };
};

export default useGetTextConfirmFirst;
