import React from "react";
import { Checkbox as CheckBoxCustom } from "antd";

interface IPropsCheckBox {
  checked: boolean;
  // setValue: () => void;
}

const CheckBox = ({ checked }: IPropsCheckBox) => {
  return (
    <CheckBoxCustom
      checked={checked}
      className="checkbox"
      // onChange={() => setValue()}
    />
  );
};

export default CheckBox;
