import React, { useEffect, useState } from "react";
import "./NumberPaymentRow.scss";
import { paymentRowType } from "../../../../Components/PaymentRow/PaymentRow";
import CustomNumberInput from "../../../../Components/CustomNumberInput/CustomNumberInput";
import { RootState, useAppDispatch } from "../../../../Redux/store";
import { paymentActions } from "../../services/payment.slice";
import { useSelector } from "react-redux";
import { getInvoiceResType } from "../../services/payment.type";

interface PT {
  data: paymentRowType;
}

const NumberPaymentRow: React.FC<PT> = ({ data }) => {
  const dispatch = useAppDispatch();

  const [init, setInit] = useState<number>(0);
  const [initAmount, setInitAmount] = useState<number>(0);

  const invoice = useSelector<RootState, getInvoiceResType | null>(
    (state) => state.payment.invoice
  );
  const amount = useSelector<RootState, number>(
    (state) => state.payment.amount
  );
  const amountError = useSelector<RootState, boolean>(
    (state) => state.payment.amountError
  );

  useEffect(() => {
    setInitAmount(amount);
  }, []);

  useEffect(() => {
    let check = false;
    if (
      invoice &&
      invoice.addInfo &&
      invoice.addInfo.FeInfo &&
      invoice.addInfo.FeInfo.minPay &&
      amount < invoice.addInfo.FeInfo.minPay
    )
      check = true;

    if (
      invoice &&
      invoice.addInfo &&
      invoice.addInfo.InsuranceInfo &&
      invoice.addInfo.InsuranceInfo.minPay &&
      amount < invoice.addInfo.InsuranceInfo.minPay
    )
      check = true;

    if (
      invoice &&
      invoice.addInfo &&
      invoice.addInfo.InsuranceInfo &&
      invoice.addInfo.InsuranceInfo.maxPay &&
      amount > invoice.addInfo.InsuranceInfo.maxPay
    )
      check = true;

    if (
      invoice &&
      invoice.addInfo &&
      invoice.addInfo.FeInfo &&
      invoice.addInfo.FeInfo.maxPay &&
      amount > invoice.addInfo.FeInfo.maxPay
    )
      check = true;

    if (
      invoice &&
      invoice.addInfo &&
      invoice.addInfo.FeInfo &&
      !invoice.addInfo.FeInfo.maxPay
    ) {
      if (
        invoice &&
        amount > invoice.amount.reduce((pre, curr) => curr + pre)
      ) {
        check = true;
      }
    }

    if (amount <= 0) check = true;

    dispatch(paymentActions.setAmountError(check));
  }, [amount, invoice]);

  const onChange = (e: any) => {
    if (init < 2) setInit((pre) => pre + 1);
    dispatch(paymentActions.setAmount(Number(e)));
  };

  return (
    <div className={`payment-item number-payment-row`}>
      <div className="payment-title">{data.title}</div>
      <CustomNumberInput
        initValue={initAmount}
        onChange={onChange}
        placeholder="Nhập số tiền"
        errroMess={init > 1 && amountError ? "Số tiền không hợp lệ" : ""}
      />
    </div>
  );
};

export default React.memo(NumberPaymentRow);
