import React from "react";
import "./EduPaymentFooter.scss";
import Util from "../../../../../../Util/Util";
import { eduItemType } from "../../../../services/payment.type";

interface PT {
  options: eduItemType[];
}

const EduPaymentFooter: React.FC<PT> = ({ options }) => {
  return (
    <div className="edu-payment-footer-container">
      <div className="edu-payment-footer-title">Tổng (VND)</div>
      <div className="edu-payment-footer-value">
        {Util.formatNumberWithFixedAndComma(
          options.reduce((a, b) => {
            return a + Number(b.d_amount);
          }, 0),
          0
        )}
      </div>
    </div>
  );
};

export default React.memo(EduPaymentFooter);
