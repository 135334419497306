import React from "react";
import "./ConfirmSecond.scss";
import {
  clearStatusEnum,
  clearStatusResType,
} from "../../services/confirm.type";
import { useSelector } from "react-redux";
import { RootState } from "../../../../Redux/store";
import Clear from "../Clear/Clear";
import PhoneClear from "../PhoneClear/PhoneClear";
import { serviceCategoryEnum } from "../../../Auth/services/auth.type";

const ConfirmSecond = () => {
  const clearData = useSelector<RootState, clearStatusResType | null>(
    (state) => state.confirm.clearData
  );

  return (
    <div className="confirm-container-second">
      <div className="confirm-second-main">
        {clearData ? (
          clearData.transStatus !== clearStatusEnum.INIT &&
          clearData.categoryType !== serviceCategoryEnum.BILLING ? (
            <PhoneClear />
          ) : (
            <Clear />
          )
        ) : (
          <></>
        )}
      </div>
      {clearData ? (
        clearData.transStatus !== clearStatusEnum.INIT && (
          <div className="confirm-second-ref">
            Số tham chiếu:<span> {clearData.transCode}</span>
          </div>
        )
      ) : (
        <></>
      )}
      {clearData ? (
        clearData.transStatus === clearStatusEnum.PENDING && (
          <div className="confirm-second-hotline-text">
            Vui lòng liên hệ Hotline<span> 024 3225 2338</span> để được hỗ trợ
          </div>
        )
      ) : (
        <></>
      )}
    </div>
  );
};

export default React.memo(ConfirmSecond);
