import { useEffect } from "react";
import { useAppDispatch } from "../../../Redux/store";
import {
  getRecentlyPhone,
  topupServiceActions,
} from "../services/topupService.slice";
import { authActions } from "../../Auth/services/auth.slice";
import { recentlyPhoneType } from "../services/topupService.type";

const useGetRecentlyPhone = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const getRecentlyPhoneSuccess = (data: recentlyPhoneType[]) => {
      dispatch(topupServiceActions.setRecentlyPhone(data.slice(0, 5)));
      dispatch(authActions.setLoading(false));
    };

    const getRecentlyPhoneError = () => {
      dispatch(authActions.setLoading(false));
    };

    dispatch(authActions.setLoading(true));
    dispatch(getRecentlyPhone(getRecentlyPhoneSuccess, getRecentlyPhoneError));
  }, []);

  return {};
};

export default useGetRecentlyPhone;
