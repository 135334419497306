import React, { useEffect } from "react";
import "./ConfirmFirst.scss";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../../Redux/store";
import {
  clearStatusEnum,
  clearStatusResType,
  invoiceDataType,
  paymentStatusTransNumber,
} from "../../services/confirm.type";
import { authActions } from "../../../Auth/services/auth.slice";
import useGetTextConfirmFirst from "../../hooks/useGetTextConfirmFirst";

const ConfirmFirst = () => {
  const dispatch = useAppDispatch();
  const clearData = useSelector<RootState, clearStatusResType | null>(
    (state) => state.confirm.clearData
  );
  const data = useSelector<RootState, invoiceDataType | null>(
    (state) => state.confirm.data
  );
  const clearErrorMess = useSelector<RootState, string>(
    (state) => state.confirm.clearErrorMess
  );

  const { transType, clearStatus } = useGetTextConfirmFirst();

  useEffect(() => {
    if (clearData && clearData.transStatus && data) {
      dispatch(authActions.setLoading(false));
    } else if (
      data &&
      data.transactionStatus !== Number(paymentStatusTransNumber.SUCCESS) &&
      data.transactionStatus !== Number(paymentStatusTransNumber.THIRD_PARTY) &&
      data.transactionStatus !== Number(paymentStatusTransNumber.PENDING)
    )
      dispatch(authActions.setLoading(false));
    else dispatch(authActions.setLoading(true));
  }, [clearData, data]);

  return (
    <div className="confirm-container-first">
      {/* Icon */}
      <div
        className={`confirm-first-icon confirm-first-${
          clearData &&
          (clearData.transStatus !== clearStatusEnum.INIT
            ? clearData.transStatus
            : "")
        }-icon`}
      ></div>

      {/* Title under icon */}
      <div className="confirm-first-title">
        {clearData && (
          <div className="confirm-first-title-text">
            {transType}{" "}
            <span
              style={{
                color:
                  clearData && clearData.transStatus === clearStatusEnum.FAIL
                    ? "var(--text-color-error)"
                    : "",
              }}
            >
              {clearStatus}
            </span>
          </div>
        )}
      </div>

      {/* Error mess for failed status */}
      {clearData && clearData.transStatus === clearStatusEnum.FAIL && (
        <div className="confirm-first-error-mess">{clearErrorMess}</div>
      )}
    </div>
  );
};

export default React.memo(ConfirmFirst);
