import { useEffect } from "react";
import { queryEnum, selectOptionType } from "../../../Types/global.type";
import { RootState, useAppDispatch } from "../../../Redux/store";
import {
  dataMethodEnum,
  phoneServiceEnum,
  phoneServiceTrans,
} from "../services/topupService.type";
import { authActions } from "../../Auth/services/auth.slice";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import ServiceItem from "../components/ServiceItem/ServiceItem";
import { MainRoutesEnum } from "../../../Types/routes.type";
import Util from "../../../Util/Util";
import { topupServiceActions } from "../services/topupService.slice";

const useGetPhoneServices = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const services = useSelector<RootState, selectOptionType[]>(
    (state) => state.topupService.services
  );
  const denos = useSelector<RootState, number[]>(
    (state) => state.topupService.denos
  );

  // Set data list
  useEffect(() => {
    const { minorService, quantity } = Util.getByQuery([
      queryEnum.minorService,
      queryEnum.quantity,
    ]);

    // Set services
    dispatch(
      topupServiceActions.setServices(
        Object.values(phoneServiceEnum).map((a) => {
          return {
            value: a,
            label: phoneServiceTrans[a],
            render: <ServiceItem label={phoneServiceTrans[a]} />,
            selectedRender: (
              <div
                className={`selected-service-icon selected-service-icon-${a}`}
              ></div>
            ),
          };
        })
      )
    );

    // Set denos
    const denos = [10000, 20000, 30000, 50000, 100000, 200000, 300000, 500000];
    dispatch(topupServiceActions.setDenos(denos));

    // Set method
    if (minorService)
      dispatch(
        topupServiceActions.setDataMethod(
          Util.convertTrans(minorService, dataMethodEnum)
        )
      );
    else dispatch(topupServiceActions.setDataMethod(dataMethodEnum.TOPUP_DATA));

    // Set quantity
    if (quantity && !isNaN(Number(quantity)))
      dispatch(topupServiceActions.setQuantity(Number(quantity)));
    else dispatch(topupServiceActions.setQuantity(1));
  }, []);

  // Set service from query
  useEffect(() => {
    if (services.length !== 0) {
      const { provider } = Util.getByQuery([queryEnum.provider]);
      if (provider) {
        const found = services.find((a) => a.value === provider);
        if (found) {
          dispatch(topupServiceActions.setSelectedService(found));
        } else {
          navigate(MainRoutesEnum.INVOICES);
          dispatch(authActions.setErrorMess("Không tìm thấy dịch vụ"));
        }
      }
    }
  }, [services]);

  // Set denomination
  useEffect(() => {
    if (denos.length !== 0) {
      const { amount, minorService } = Util.getByQuery([
        queryEnum.amount,
        queryEnum.minorService,
      ]);

      if (amount) {
        const found = denos.find((a) => a === Number(amount));
        if (found) {
          switch (minorService) {
            case dataMethodEnum.TOPUP_DATA:
              dispatch(topupServiceActions.setDenomination(found));
              dispatch(topupServiceActions.setDenominationDownload(denos[0]));
              break;

            case dataMethodEnum.TOPUP_DOWNLOAD:
              dispatch(topupServiceActions.setDenomination(denos[0]));
              dispatch(topupServiceActions.setDenominationDownload(found));
              break;

            default:
              break;
          }
        } else {
          navigate(MainRoutesEnum.INVOICES);
          dispatch(authActions.setErrorMess("Không tìm thấy dịch vụ"));
        }
      } else {
        dispatch(topupServiceActions.setDenomination(denos[0]));
        dispatch(topupServiceActions.setDenominationDownload(denos[0]));
      }
    }
  }, [denos]);

  // Set phone
  useEffect(() => {
    const { phone } = Util.getByQuery([queryEnum.phone]);
    if (phone) dispatch(topupServiceActions.setPhone(phone));
  }, []);

  return {};
};

export default useGetPhoneServices;
