import Api from "../../../Providers/Api";
import { createOrderReqType, createOrderResType } from "./payment.type";

export default class paymentAPI {
  static createOrderAPI = async (data: createOrderReqType) => {
    return await Api.post<createOrderResType>(
      `/v1.0/api/billing/req-payment`,
      data
    );
  };
}
