import React, { useEffect, useMemo } from "react";
import "./SubBillingService.scss";
import Buttons from "../../Components/Buttons/Buttons";
import useSubmit from "./hooks/useSubmit";
import { RootState, useAppDispatch } from "../../Redux/store";
import { subbillingServiceActions } from "./services/subBillingService.slice";
import Util from "../../Util/Util";
import { queryEnum } from "../../Types/global.type";
import {
  INTERNET_VIETTEL_TRA_SAU,
  TRUYEN_HINH_VIETTEL,
} from "../Auth/services/auth.type";
import OrtherProvider from "./components/OrtherProvider/OrtherProvider";
import ViettelProvider from "./components/ViettelProvider/ViettelProvider";
import { useSelector } from "react-redux";

const SubBillingService = () => {
  const dispatch = useAppDispatch();
  const loading = useSelector<RootState, boolean>(
    (state) => state.auth.loading
  );

  const { provider } = useMemo(() => {
    return Util.getByQuery([queryEnum.provider]);
  }, []);

  const { onSubmitData, onBack, disable } = useSubmit();

  useEffect(() => {
    return () => {
      dispatch(subbillingServiceActions.reset());
    };
  }, []);

  return (
    <div className="sub-billing-service-container">
      <div className="sub-billing-service-main">
        {provider !== TRUYEN_HINH_VIETTEL &&
          provider !== INTERNET_VIETTEL_TRA_SAU && (
            <OrtherProvider onBack={onBack} />
          )}
        {provider === TRUYEN_HINH_VIETTEL && (
          <ViettelProvider onBack={onBack} />
        )}
        {provider === INTERNET_VIETTEL_TRA_SAU && (
          <ViettelProvider onBack={onBack} />
        )}
      </div>

      {/* Các buttons ở dưới cùng */}
      {!loading && <Buttons onBack={onBack} onOk={onSubmitData} disabledOk={disable} />}
    </div>
  );
};

export default React.memo(SubBillingService);
