import React from "react";
import "./ClientIDField.scss";
import CustomField from "../CustomField/CustomField";
import { fieldEnum } from "../../services/billingService.type";
import { RootState, useAppDispatch } from "../../../../Redux/store";
import { billingServiceActions } from "../../services/billingService.slice";
import { useSelector } from "react-redux";
import { selectOptionType } from "../../../../Types/global.type";
import useGetClientID from "./hooks/useGetClientID";
import { LoanIDTypeEnum } from "../LoanIDTypeField/services/loanIDType.type";

const ClientIDField = () => {
  const dispatch = useAppDispatch();

  const loanIDType = useSelector<RootState, selectOptionType | null>(
    (state) => state.billingService.loanIDType
  );
  const needLoanIDType = useSelector<RootState, boolean>(
    (state) => state.billingService.needLoanIDType
  );

  const { title, placeholder, initClientID } = useGetClientID();

  const setClientID = (e: string) => {
    dispatch(billingServiceActions.setClientID(e));
  };

  return !needLoanIDType || loanIDType ? (
    <CustomField
      type={
        loanIDType?.value === LoanIDTypeEnum.CCCD
          ? fieldEnum.NUMBER
          : fieldEnum.TEXT
      }
      title={title}
      placeholder={placeholder}
      setValue={setClientID}
      initValue={initClientID}
    />
  ) : (
    <></>
  );
};

export default React.memo(ClientIDField);
