import React from "react";
import "./BillingService.scss";
import useSubmit from "./hooks/useSubmit";
import DemoSmallDevice from "../../Components/DemoSmallDevice/DemoSmallDevice";
import ProvinceField from "./components/ProvinceField/ProvinceField";
import LoanIDTypeField from "./components/LoanIDTypeField/LoanIDTypeField";
import ProviderField from "./components/ProviderField/ProviderField";
import ClientIDField from "./components/ClientIDField/ClientIDField";
import { useSelector } from "react-redux";
import { RootState } from "../../Redux/store";
import useSetNeedProvider from "./hooks/useSetNeedProvider";
import useSetNeedLoanIdType from "./hooks/useSetNeedLoanIdType";
import Buttons from "../../Components/Buttons/Buttons";

const BillingService = () => {
  const { onSubmitData, onBack, disabledSubmit } = useSubmit();
  const needLoanIDType = useSelector<RootState, boolean>(
    (state) => state.billingService.needLoanIDType
  );
  const needProvince = useSelector<RootState, boolean>(
    (state) => state.billingService.needProvince
  );

  useSetNeedProvider();
  useSetNeedLoanIdType();

  return (
    <div className="billing-service-container">
      <div className="billing-service-main">
        <ProviderField />
        {needProvince && <ProvinceField />}
        {needLoanIDType && <LoanIDTypeField />}
        <ClientIDField />

        {/* Demo test scroll bar */}
        <DemoSmallDevice />
      </div>

      {/* Các buttons ở dưới cùng */}
      <Buttons
        onBack={onBack}
        onOk={onSubmitData}
        disabledOk={disabledSubmit}
      />
    </div>
  );
};

export default React.memo(BillingService);
