import { useEffect, useState } from "react";
import {
  serviceTransFull,
  serviceType,
  serviceTypeEnum,
} from "../../Auth/services/auth.type";
import Util from "../../../Util/Util";
import { useSelector } from "react-redux";
import { RootState } from "../../../Redux/store";
import {
  IBillDetailViettel,
  getInvoiceResType,
  hideAmountServices,
} from "../services/payment.type";
import {
  paymentRowSpecialType,
  paymentRowType,
} from "../../../Components/PaymentRow/PaymentRow";
import { queryEnum } from "../../../Types/global.type";

const useRenderData = (provider: serviceType | null) => {
  const [infoArray, setInfoArray] = useState<paymentRowType[]>([]);
  const [infoArrayViettel, setInfoArrayViettel] = useState<paymentRowType[]>(
    []
  );

  const invoice = useSelector<RootState, getInvoiceResType | null>(
    (state) => state.payment.invoice
  );

  useEffect(() => {
    const invoiceViettel: IBillDetailViettel[] =
      (invoice?.billDetail as unknown as IBillDetailViettel[]) ?? [];

    if (provider && invoice) {
      const { addedAmount, accountId } = Util.getByQuery([queryEnum.addedAmount, queryEnum.accountId]);

      const amount = addedAmount ? +addedAmount : 0;

      setInfoArrayViettel([
        {
          title: "Nhà cung cấp",
          text: provider.name,
        },
        {
          title: "Dịch vụ",
          text: Util.convertTeleComServiceType(
            invoiceViettel[0].teleComServiceType
          ),
        },
        {
          title: "Mã hợp đồng",
          text: accountId,
        },
        {
          title: "Tên chủ hợp đồng",
          text: invoice.customerName,
          isShown: !invoice.customerName || invoice.customerName.trim() === "",
        },
        {
          title: "Tổng nợ cước (VND)",
          isShown: !addedAmount,
          text: hideAmountServices.includes(provider.code)
            ? "*** *** ***"
            : Util.formatNumberWithFixedAndComma(
                invoice.amount.reduce((pre, curr) => curr + pre),
                0
              ),
        },
        {
          title: "Cước đóng trước (VND)",
          text: Util.formatNumberWithFixedAndComma(addedAmount, 0),
          isShown: !addedAmount,
        },
        {
          title: "Số tiền thanh toán (VND)",
          text: hideAmountServices.includes(provider.code)
            ? "*** *** ***"
            : Util.formatNumberWithFixedAndComma(
                invoice.amount.reduce((pre, curr) => curr + pre) + amount,
                0
              ),
          className: "payment-amount",
        },
      ]);
    }
  }, [provider, invoice]);

  useEffect(() => {
    const { service } = Util.getByQuery([queryEnum.service]);
    if (provider && invoice)
      setInfoArray([
        {
          title: "Nhà cung cấp",
          text: provider.name,
        },
        {
          title: "Chiều đi",
          text: provider.name,
          type: paymentRowSpecialType.AIR,
          addInfo: invoice.addInfo,
          service: [serviceTypeEnum["VAN-TAI"]],
        },
        // {
        //   title: "Chiều về",
        //   text: provider.name,
        //   type: paymentRowSpecialType.AIR,
        //   addInfo: invoice.addInfo,
        //   service: [serviceTypeEnum["VAN-TAI"]],
        // },
        {
          title: "Dịch vụ",
          text: Util.convertTrans(service, serviceTransFull),
          service: Object.values(serviceTypeEnum).filter(
            (a) => ![serviceTypeEnum["VAN-TAI"]].includes(a)
          ),
        },
        {
          title: "Mã khách hàng",
          text: invoice.billingCode,
          service: [serviceTypeEnum.DIEN, serviceTypeEnum.NUOC],
        },
        {
          title: "Mã học sinh",
          text: invoice.billingCode,
          service: [serviceTypeEnum.EDU],
        },
        {
          title: "Số hợp đồng",
          text: invoice.billingCode,
          service: [serviceTypeEnum["TAI-CHINH"], serviceTypeEnum["BAO-HIEM"]],
        },
        {
          title: "Số thuê bao",
          text: invoice.billingCode,
          service: [
            serviceTypeEnum["VIEN-THONG-TRA-SAU"],
            serviceTypeEnum["VIEN-THONG-CO-DINH"],
          ],
        },
        // {
        //   title: "Số điện thoại",
        //   text: invoice.billingCode,
        //   service: [serviceTypeEnum["TRUYEN-HINH"], serviceTypeEnum.INTERNET],
        // },
        {
          title: "Mã khách hàng",
          text: invoice.billingCode,
          service: [serviceTypeEnum["TRUYEN-HINH"], serviceTypeEnum.INTERNET],
        },
        // {
        //   title: "Mã khách hàng",
        //   text: "...",
        //   service: [serviceTypeEnum["TRUYEN-HINH"], serviceTypeEnum.INTERNET],
        // },
        {
          title: "Tên khách hàng",
          text: invoice.customerName,
          isShown: !invoice.customerName || invoice.customerName.trim() === "",
          service: Object.values(serviceTypeEnum).filter(
            (a) => ![serviceTypeEnum.EDU].includes(a)
          ),
        },
        {
          title: "Người thụ hưởng bảo hiểm",
          text: invoice.addInfo.InsuranceInfo?.beneficiaryName,
          service: [serviceTypeEnum["BAO-HIEM"]],
        },
        {
          title: "CMND",
          text: invoice.addInfo.FeInfo?.identityNumber,
          service: [serviceTypeEnum["TAI-CHINH"], serviceTypeEnum["BAO-HIEM"]],
        },
        {
          title: "Tên học sinh",
          text: invoice.addInfo.EduInfo?.studentName,
          service: [serviceTypeEnum.EDU],
        },
        {
          title: "Tên lớp",
          text: invoice.addInfo.EduInfo?.className,
          service: [serviceTypeEnum.EDU],
        },
        {
          title: "Trường",
          text: invoice.addInfo.EduInfo?.schoolName,
          service: [serviceTypeEnum.EDU],
        },
        // ====== minPay ====== //
        {
          title: "Số tiền TT tối thiểu (VND)",
          text: Util.formatNumberWithFixedAndComma(
            invoice.addInfo.FeInfo?.minPay,
            0
          ),
          isShown:
            Util.formatNumberWithFixedAndComma(
              invoice.addInfo.FeInfo?.minPay,
              0
            ) === "0",
          service: [serviceTypeEnum["TAI-CHINH"]],
        },

        {
          title: "Số tiền TT tối thiểu (VND)",
          text: Util.formatNumberWithFixedAndComma(
            invoice.addInfo.InsuranceInfo?.minPay,
            0
          ),
          isShown:
            Util.formatNumberWithFixedAndComma(
              invoice.addInfo.InsuranceInfo?.minPay,
              0
            ) === "0",
          service: [serviceTypeEnum["BAO-HIEM"]],
        },
        // ====== end minPay ====== //

        // ====== maxPay ====== //
        {
          title: "Số tiền TT tối đa (VND)",
          text: Util.formatNumberWithFixedAndComma(
            invoice.addInfo.FeInfo?.maxPay,
            0
          ),
          isShown:
            Util.formatNumberWithFixedAndComma(
              invoice.addInfo.FeInfo?.maxPay,
              0
            ) === "0",
          service: [serviceTypeEnum["TAI-CHINH"]],
        },
        {
          title: "Số tiền TT tối đa (VND)",
          text: Util.formatNumberWithFixedAndComma(
            invoice.addInfo.InsuranceInfo?.maxPay,
            0
          ),
          isShown:
            Util.formatNumberWithFixedAndComma(
              invoice.addInfo.InsuranceInfo?.maxPay,
              0
            ) === "0",
          service: [serviceTypeEnum["BAO-HIEM"]],
        },

        // ====== end maxPay ====== //
        {
          title: "Ngày đến hạn",
          text: invoice.addInfo.FeInfo?.dueDate,
          service: [serviceTypeEnum["BAO-HIEM"], serviceTypeEnum["TAI-CHINH"]],
        },
        {
          title: "Kỳ thanh toán",
          text: invoice.addInfo.period,
          service: [serviceTypeEnum["BAO-HIEM"], serviceTypeEnum["TAI-CHINH"]],
        },
        {
          title: "Kỳ thanh toán",
          text: invoice.addInfo.multiplePeriod
            ? invoice.addInfo.multiplePeriod
            : invoice.addInfo.period
            ? invoice.addInfo.period
            : "",
          isShown: !invoice.addInfo.period && !invoice.addInfo.multiplePeriod,
          service: Object.values(serviceTypeEnum).filter(
            (a) =>
              ![
                serviceTypeEnum["TAI-CHINH"],
                serviceTypeEnum["BAO-HIEM"],
              ].includes(a)
          ),
        },
        {
          title: "Mã đặt chỗ",
          text: invoice.billingCode,
          service: [serviceTypeEnum["VAN-TAI"]],
        },
        {
          title: "Số tiền thanh toán (VND)",
          text: hideAmountServices.includes(provider.code)
            ? "*** *** ***"
            : Util.formatNumberWithFixedAndComma(
                invoice.amount.reduce((pre, curr) => curr + pre),
                0
              ),
          className: "payment-amount",
          service: Object.values(serviceTypeEnum).filter(
            (a) =>
              ![
                serviceTypeEnum.EDU,
                serviceTypeEnum["TAI-CHINH"],
                serviceTypeEnum["BAO-HIEM"],
              ].includes(a)
          ),
        },
        {
          title: "Tổng nợ đến hạn (VND)",
          text: hideAmountServices.includes(provider.code)
            ? "*** *** ***"
            : Util.formatNumberWithFixedAndComma(
                invoice.amount.reduce((pre, curr) => curr + pre),
                0
              ),
          className: "payment-amount",
          service: [serviceTypeEnum["TAI-CHINH"], serviceTypeEnum["BAO-HIEM"]],
        },
        {
          title: "Số tiền thanh toán",
          text: provider.name,
          type: paymentRowSpecialType.EDU,
          addInfo: invoice.addInfo,
          billDetail: invoice.billDetail,
          service: [serviceTypeEnum.EDU],
        },
        {
          title: "Nhập số tiền thanh toán (VND)",
          text: provider.name,
          type: paymentRowSpecialType.NUMBER_INPUT,
          addInfo: invoice.addInfo,
          billDetail: invoice.billDetail,
          service: [serviceTypeEnum["TAI-CHINH"], serviceTypeEnum["BAO-HIEM"]],
        },
        {
          title: "Địa chỉ",
          text: invoice.addInfo.address ? invoice.addInfo.address : "",
          isShown: !invoice.addInfo.address,
        },
      ]);
  }, [provider, invoice]);

  return { infoArray, infoArrayViettel };
};

export default useRenderData;
