import React, { ReactNode, useEffect } from "react";
import ReactDOM from "react-dom";
import "./CustomModal.scss";

interface PT {
  children: ReactNode;
  isShowing: boolean;
  onCancel?: () => void;
  className?: string;
}

const CustomModal: React.FC<PT> = ({
  children,
  isShowing,
  onCancel,
  className,
}) => {
  useEffect(() => {
    const onKeyDown = (e: any) => {
      e.keyCode === 27 && onCancel && onCancel();
    };

    isShowing
      ? addEventListener("keydown", onKeyDown)
      : removeEventListener("keydown", onKeyDown);

    return () => {
      removeEventListener("keydown", onKeyDown);
    };
  }, [isShowing]);

  return isShowing ? (
    ReactDOM.createPortal(
      <React.Fragment>
        <div className="custom-modal-overlay" />
        <div
          className="custom-modal-wrapper"
          aria-modal
          aria-hidden
          tabIndex={-1}
          role="dialog"
        >
          <div>
            <div className={`custom-modal${className ? " " + className : ""}`}>
              {children}
            </div>
          </div>
        </div>
      </React.Fragment>,
      document.body
    )
  ) : (
    <></>
  );
};

export default React.memo(CustomModal);
