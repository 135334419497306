import React from "react";
import FixInfo from "../FixInfo/FixInfo";
import { Collapse } from "antd";
import useGetInitAddedAmount from "../../hooks/useGetInitAddedAmount";
import useGetInvoice from "../../../Payment/hooks/useGetInvoice";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../../Redux/store";
import { getInvoiceResType } from "../../../Payment/services/payment.type";
import CustomSwitch from "../../../../Components/CustomSwitch/CustomSwitch";
import { subbillingServiceActions } from "../../services/subBillingService.slice";
import AddedAmount from "../AddedAmount/AddedAmount";

interface IPropsOrtherProvider {
  onBack: () => void;
}

const OrtherProvider = ({ onBack }: IPropsOrtherProvider) => {
  const dispatch = useAppDispatch();

  const invoice = useSelector<RootState, getInvoiceResType | null>(
    (state) => state.payment.invoice
  );

  const isOpenAddedAmount = useSelector<RootState, boolean>(
    (state) => state.subbillingService.isOpenAddedAmount
  );

  useGetInitAddedAmount();
  useGetInvoice(onBack);

  const onClickSwitch = (event: any) => {
    event.stopPropagation();
  };

  const setOpen = (e: boolean) => {
    dispatch(subbillingServiceActions.setOpenAddedAmount(e));
  };

  return (
    <>
      {invoice && <FixInfo />}

      {invoice && (
        <Collapse
          activeKey={isOpenAddedAmount ? ["0"] : []}
          items={[
            {
              showArrow: false,
              label: (
                <div className="sub-billing-service-switch">
                  <div className="sub-billing-service-text">
                    Thanh toán trước
                  </div>
                  <div onClick={onClickSwitch}>
                    <CustomSwitch
                      isCheck={isOpenAddedAmount}
                      onChange={setOpen}
                    />
                  </div>
                </div>
              ),
              children: <AddedAmount />,
            },
          ]}
          onChange={(e) => {
            setOpen(e.length > 0);
          }}
          expandIconPosition="end"
          ghost
          destroyInactivePanel
        />
      )}
    </>
  );
};

export default OrtherProvider;
