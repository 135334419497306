import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../Redux/store";
import { setTokenHeader } from "../../../Providers/Api";
import {
  authActions,
  getServiceList,
  getSessionFromSSOC,
  getWalletStatus,
} from "../services/auth.slice";
import {
  VNeIDSessionResType,
  serviceType,
  walletStatusEnum,
  walletStatusResType,
} from "../services/auth.type";
import { fixedTheme } from "../../../Config/global.config";
import {
  savedInvoiceType,
  trainServiceCode,
} from "../../Invoices/services/invoices.type";
import {
  getSavedInvoices,
  invoicesActions,
} from "../../Invoices/services/invoices.slice";
import { MainRoutesEnum } from "../../../Types/routes.type";
import Util from "../../../Util/Util";
import { themeTrans } from "../../../Types/global.type";

const useAuthorize = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const getSavedInvoicesSuccess = (data: savedInvoiceType[]) => {
    dispatch(invoicesActions.setSavedInvoices(data.slice(0, 10)));
    dispatch(authActions.setLoadingGetSavedInvoices(false));
  };

  const getSavedInvoicesError = () => {
    dispatch(invoicesActions.setSavedInvoices([]));
    dispatch(authActions.setLoadingGetSavedInvoices(false));
  };

  const getAuthSuccess = (data: serviceType[]) => {
    const existedTrain = data.find((a) => a.code === trainServiceCode);
    const uniqueData = Util.getUniqueData(
      data.filter((a) => a.code !== trainServiceCode),
      "code"
    );
    dispatch(authActions.setServices(uniqueData));
    dispatch(authActions.setExistedTrain(!!existedTrain));
    dispatch(authActions.setLoadingGetService(false));
  };

  const getAuthError = () => {
    setTokenHeader("");
    dispatch({ type: "LOGOUT" });
    dispatch(authActions.setAuth(false));
    dispatch(authActions.setLoadingGetService(false));
    navigate("/");
  };

  const getWalletStatusSuccess = (data: walletStatusResType) => {
    dispatch(authActions.setExistedEpay(data.walletStatus));
  };

  const getWalletStatusError = () => {
    dispatch(authActions.setExistedEpay(walletStatusEnum["NOT-EXIST"]));
  };

  const startAuth = async (token: string) => {
    await setTokenHeader(token);

    // Lấy data màn hình chính (dịch vụ + hóa đơn đã lưu)
    dispatch(getServiceList(getAuthSuccess, getAuthError));
    dispatch(getSavedInvoices(getSavedInvoicesSuccess, getSavedInvoicesError));

    // Lấy wallet status
    const theme = document
      .getElementsByTagName("HTML")[0]
      .getAttribute("data-theme");

    if (theme !== themeTrans.EPAY_PARTNER)
      dispatch(getWalletStatus(getWalletStatusSuccess, getWalletStatusError));
  };

  const getVNeIDAuth = (token: string) => {
    const getSessionFromSSOCSuccess = (data: VNeIDSessionResType) => {
      startAuth(data.session);
      dispatch(authActions.setExistedEpay(data.walletStatus));
    };

    dispatch(
      getSessionFromSSOC(token, getSessionFromSSOCSuccess, getAuthError)
    );
  };

  useEffect(() => {
    // Setup + tính toán các biến
    const params = new URLSearchParams(window.location.search);
    const token = params.get("session");
    const SSOCode = params.get("authorizationCode");
    const themeRaw = params.get("partnerCode");
    const theme = themeRaw
      ? Util.convertTrans(themeRaw, themeTrans)
      : fixedTheme;

    // Setup theme cho HTML
    document.getElementsByTagName("HTML")[0].setAttribute("data-theme", theme);

    if (token || SSOCode) {
      // Setup lazy load
      dispatch(authActions.setLoadingGetService(true));
      dispatch(authActions.setLoadingGetSavedInvoices(true));
      dispatch(authActions.setAuth(true));

      // Setup path & auth về trang chủ nếu không phải là màn gạch nợ
      if (!window.location.pathname.includes(MainRoutesEnum.CLEAR)) {
        navigate(MainRoutesEnum.INVOICES);
      }

      // Start auth (SSOCode / Session)
      if (SSOCode) getVNeIDAuth(SSOCode);
      if (token) startAuth(token);
    } else getAuthError();
  }, []);

  return {};
};

export default useAuthorize;
