import { useSelector } from "react-redux";
import PaymentItem from "../components/PaymentItem/PaymentItem";
import { RootState } from "../../../Redux/store";
import { walletStatusEnum } from "../../Auth/services/auth.type";
import {
  paymentMethodEnum,
  paymentMethodTrans,
} from "../services/payment.type";
import Util from "../../../Util/Util";

const useGetPaymentList = () => {
  const existedEpay = useSelector<RootState, walletStatusEnum | null>(
    (state) => state.auth.existedEpay
  );

  const paymentListRaw = [
    {
      value: paymentMethodEnum.WALLET,
      disabled:
        !existedEpay || existedEpay !== walletStatusEnum["PLEASE-CHECK-ID"],
    },
    {
      value: paymentMethodEnum.LOCAL_CARD,
      disabled: false,
    },
    {
      value: paymentMethodEnum.BANK_ACCOUNT,
      disabled: false,
    },
    {
      value: paymentMethodEnum.VISA,
      disabled: false,
    },
    {
      value: paymentMethodEnum.OTHER,
      disabled:
        !!existedEpay && existedEpay === walletStatusEnum["PLEASE-CHECK-ID"],
    },
  ];

  const paymentList = paymentListRaw
    .filter(
      (a) =>
        a.value !== paymentMethodEnum.OTHER ||
        !(a.value === paymentMethodEnum.OTHER && a.disabled)
    )
    .map((a) => {
      return {
        value: a.value,
        label: Util.convertTrans(a.value, paymentMethodTrans),
        render: (
          <PaymentItem
            disabled={a.disabled}
            title={Util.convertTrans(a.value, paymentMethodTrans)}
            icon={a.value}
          />
        ),
        disabled: a.disabled,
      };
    });

  return { paymentList };
};

export default useGetPaymentList;
