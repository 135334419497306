export const UnauthErrorCode = 1003;
export const UnauthErrorMess = "Lỗi ủy quyền, xin mời đăng nhập lại";

export const errorCodeList = {
  "0": "Giao dịch thành công",
  "1": "Mã partner không tồn tại hoặc chưa được kích hoạt",
  "2": "Sai tài khoản hoặc mật khẩu của partner",
  "3": "Session giao dịch không đúng hoặc hết hạn",
  "4": "Không tìm thấy giao dịch cần truy vấn",
  "5": "Request đã tồn tại",
  "6": "Message type không đúng",
  "8": "Giao dịch không thành công",

  "10": "Lỗi dữ liệu đầu vào",
  "11": "Partner code không đúng",
  "17": "Hạn mức bảo lãnh không đủ",

  "30": "Đơn hàng đã hết hạn thanh toán",
  "31": "Số tiền giao dịch không hợp lệ. Quý khách vui lòng thực hiện lại giao dịch",
  "32": "Mã hóa đơn không hợp lệ",
  "33": "Đơn hàng đã được thanh toán",
  "34": "Không hỗ trợ dịch vụ này",
  "35": "Lỗi với bên cung cấp dịch vụ. Quý khách vui lòng quay lại sau",
  "36": "Lỗi với bên cung cấp dịch vụ. Quý khách vui lòng quay lại sau",

  "40": "Hệ thống đang được bảo trì",

  "51": "Lỗi kết nối tới hệ thống VNeID",
  "52": "Lỗi kết nối tới CTT Epay",
  "53": "Lỗi kết nối tới Ví Epay",
  "54": "Lỗi kết nối tới hệ thống Billing",

  "90": "Chữ ký không đúng",
  "91": "Không giải mã được dữ liệu",
  "92": "Chữ kí không hợp lệ",
  "93": "Không thể xác thực thông tin session ID",
  "94": "Độ dài không hợp lệ",
  "95": "Không thể kết nối được với partner",
  "96": "Partner không trả dữ liệu user info",
  "97": "Token không hợp lệ",
  "98": "Timeout",
  "99": "Lỗi không được định nghĩa hoặc không rõ nguyên nhân",

  "100": "Không thể thực hiện gạch nợ",
  "101": "Không tìm thấy biller",
  "1000": "Lỗi hệ thống",
  "1300": "Không thể truy vấn dịch vụ",
  "1301": "Không có quyền truy cập vào dịch vụ",
  "1303": "Không thể thực hiện thanh toán hoá đơn",
  "1304": "Không thể lấy thông tin lịch sử giao dịch hoá đơn",
  "1305": "Không tìm thấy dịch vụ",
  "1400": "Không thể tạo thanh toán hoá đơn",
  "1401": "Không thể lấy trạng thái hoá đơn",
  "1402": "Không thể hoàn tiền giao dịch cho hoá đơn",
  "1500": "Mã hóa đơn không hợp lệ",
  "1501": "Hoá đơn chưa tới kì thanh toán",
};
