import { useEffect } from "react";
import { RootState, useAppDispatch } from "../../../Redux/store";
import { billingServiceActions } from "../services/billingService.slice";
import { providerNeedProvince } from "../components/ProvinceField/hooks/useGetProvince";
import { serviceTypeEnum } from "../../Auth/services/auth.type";
import { useSelector } from "react-redux";
import { selectOptionType } from "../../../Types/global.type";

const servicesAutoNeedProvince: string[] = [
  serviceTypeEnum["VIEN-THONG-CO-DINH"],
];

const servicesNeedProvince: string[] = [
  serviceTypeEnum["TRUYEN-HINH"],
  serviceTypeEnum.INTERNET,
];

const useSetNeedProvider = () => {
  const dispatch = useAppDispatch();

  const provider = useSelector<RootState, selectOptionType | null>(
    (state) => state.billingService.provider
  );
  const service = useSelector<RootState, string>(
    (state) => state.billingService.service
  );

  useEffect(() => {
    switch (true) {
      case servicesAutoNeedProvince.includes(service):
        dispatch(billingServiceActions.setNeedProvince(true));
        break;

      case servicesNeedProvince.includes(service) &&
        providerNeedProvince.includes(provider?.value):
        dispatch(billingServiceActions.setNeedProvince(true));
        break;

      default:
        dispatch(billingServiceActions.setNeedProvince(false));
        break;
    }
  }, [service, provider]);

  return {};
};

export default useSetNeedProvider;
