import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../Redux/store";
import { useState } from "react";
import { queryEnum, selectOptionType } from "../../../Types/global.type";
import {
  createOrderResType,
  paymentMethodCode,
  paymentMethodEnum,
} from "../../Payment/services/payment.type";
import { authActions } from "../../Auth/services/auth.slice";
import Util from "../../../Util/Util";
import { MainRoutesEnum } from "../../../Types/routes.type";
import { createTopupServiceOrder } from "../../TopupService/services/topupService.slice";

const useSubmit = (setOpen: (e: boolean) => void) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isOpenPaymentList, setOpenPaymentList] = useState<boolean>(false);

  const onClose = () => {
    setOpenPaymentList(false);
  };

  const createOrderSuccess = (data: createOrderResType) => {
    if (data.deepLink) window.location.href = data.deepLink;
    else window.location.href = data.paymentUrl;
  };

  const createOrderError = () => {
    dispatch(authActions.setLoading(false));
  };

  const createOrder = (paymentMethod: paymentMethodCode) => {
    const { provider, amount, phone } = Util.getByQuery([
      queryEnum.provider,
      queryEnum.amount,
      queryEnum.phone,
    ]);

    if (provider && phone && amount && !isNaN(Number(amount))) {
      dispatch(authActions.setLoading(true));
      dispatch(
        createTopupServiceOrder(
          {
            serviceCode: provider + "-TOPUP-" + amount,
            phoneNumber: phone,
            paymentMethod: paymentMethod,
          },
          createOrderSuccess,
          createOrderError
        )
      );
    } else dispatch(authActions.setErrorMess("Dữ liệu chưa hợp lệ"));
  };

  const onChangeValue = (e: selectOptionType) => {
    if (e.value === paymentMethodEnum.OTHER) setOpen(true);
    else createOrder(Util.convertTrans(e.value, paymentMethodCode));
  };

  const onBack = () => {
    navigate(`/${MainRoutesEnum.TOPUP}/${window.location.search}`);
  };

  const onSubmit = () => {
    createOrder(paymentMethodCode.WALLET);
  };

  return {
    onSubmit,
    onClose,
    onChangeValue,
    onBack,
    isOpenPaymentList,
    setOpenPaymentList,
  };
};

export default useSubmit;
