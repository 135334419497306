import React from "react";
import "./DemoSmallDevice.scss";
import CustomInput from "../CustomInput/CustomInput";

const demoArray = new Array(0).fill(null);

const DemoSmallDevice = () => {
  return (
    <>
      {demoArray.map((a, i) => (
        <CustomInput
          key={i}
          onChange={() => {
            console.log("Demo small device");
          }}
          title="Demo small device"
          placeholder="Demo small device"
          allowClear
        />
      ))}
    </>
  );
};

export default React.memo(DemoSmallDevice);
