import { PROVIDER_ENUM } from "../../../Config/global.config";

export interface paymentState {
  eduTotal: eduBillDetailType[];
  amount: number;
  invoice: getInvoiceResType | null;
  disabledSubmit: boolean;
  amountError: boolean;
}

export enum paymentMethodTrans {
  WALLET = "Ví Epay",
  LOCAL_CARD = "Thẻ ATM",
  VISA = "Thẻ tín dụng & ghi nợ quốc tế",
  BANK_ACCOUNT = "Tài khoản ngân hàng",
  OTHER = "Phương thức thanh toán khác",
}

export enum paymentMethodCode {
  WALLET = "01",
  LOCAL_CARD = "02",
  VISA = "03",
  BANK_ACCOUNT = "04",
  OTHER = "09",
}

export enum paymentMethodEnum {
  WALLET = "WALLET",
  LOCAL_CARD = "LOCAL_CARD",
  VISA = "VISA",
  BANK_ACCOUNT = "BANK_ACCOUNT",
  OTHER = "OTHER",
}

export interface createOrderReqType {
  serviceCode: string;
  billingCode: string;
  paymentMethod: paymentMethodCode;
  provinceCode?: string;
  billingDetail?: createEduOrderReqType[] | IBillDetailViettel;
  amount?: number;
  typeBillingCode?: string;
  accountId?: string;
}

export interface createEduOrderReqType {
  id?: string;
}

export interface createOrderResType {
  paymentUrl: string;
  deepLink?: string;
}

export const hideAmountServices = ["DI-DONG-TRA-SAU-VIETTEL"];
export const hideAmountText =
  "Lưu ý: Theo quy định, Tổng công ty Viễn thông Viettel sẽ không hiển thị thông tin nợ cước đối với các giao dịch thanh toán cước thuê bao di động trả sau của Viettel. Quý khách vui lòng kiểm tra kỹ và đảm bảo về tính chính xác của số điện thoại đã khai báo trước khi xác nhận thực hiện giao dịch.";

export interface getInvoiceResType {
  billingCode: string;
  customerName: string;
  serviceCode: string;
  referenceCode: string;
  billDetail: eduBillDetailType[];
  amount: number[];
  addInfo: resAddInfoType;
  provider?: PROVIDER_ENUM;
}

export const TeleComServiceTypeTV = {
  35: "Truyền hình số 1 chiều",
  19: "Truyền hình cáp",
  9: "Truyền hình NextTV",
  45: "Truyền hình số 2 chiều",
  153: "BoxTV 360",
  57: "BoxTV",
  41: "SmartTV 360",
  58: "SmartTV",
  4: "Internet",
  25: "Internet",
  28: "Internet",
};

export const TeleComServiceTypeInternet = {
  4: "Internet",
  25: "Internet",
  28: "Internet",
};

export interface IBillDetailListViettel {
  reasonIdChangePromotion: number;
  totalMoney: string;
  totalMonth: string;
  prepaidMonthBO: { prepaidId: number; prepaidValue: string };
  promotionTypeDTO: {
    promProgramCode: string;
    numMonth100: number;
    priceAfterDisscount: number;
  };
}
export interface IBillDetailViettel {
  isdn: string;
  list: IBillDetailListViettel[];
  subId: string;
  teleComServiceType: number;
  contactTelFax: string;
  debtCharges: string;
}

export interface getContractsResType {
  accountId: number;
  accountNo: string;
  custName: string;
  contactTelFax: string;
  cmkIsdn: string;
  teleComServiceType: number;
  debtCharges: number;
  phoneNumber?: string;
}

export type eduBillDetailType = {
  description: string;
  details: eduItemType[];
} & createEduOrderReqType;

export interface eduItemType {
  d_name: string;
  d_amount: number;
}

export interface resAddInfoType {
  address?: string;
  period?: string;
  multiplePeriod?: string;

  FlightInfo?: resFlightAddInfoType;
  FeInfo?: resLoanAddInfoType;
  EduInfo?: resEduAddInfoType;
  InsuranceInfo?: resInsAddInfoType;
}

export interface resInsAddInfoType {
  beneficiaryName?: string;
  minPay?: number;
  maxPay?: number;
}

export interface resEduAddInfoType {
  className?: string;
  schoolName?: string;
  studentName?: string;
}

export interface resFlightAddInfoType {
  dest?: string;
  flightDate?: string;
}

export interface resLoanAddInfoType {
  dueDate?: string;
  identityNumber?: string;
  minPay?: number;
  maxPay?: number;
}
