import { serviceCategoryEnum } from "../../Auth/services/auth.type";

export interface confirmState {
  refresh: boolean;

  clearErrorMess: string;

  data: invoiceDataType | null;
  clearData: clearStatusResType | null;
}

export interface invoiceDataType {
  billingCode: string;
  orderDesc: string;
  orderId: string;
  serviceCode: string;
  serviceName: string;
  totalAmount: number;
  transactionStatus: number;
}

export enum paymentStatusEnum {
  UN_PAID = "Chưa thanh toán",
  SUCCESS = "Đã thanh toán thành công",
  FAILED = "Đã thanh toán thất bại",
  PENDING = "Đang chờ xử lý",
  CANCEL = "Hủy bởi người sử dụng",
  THIRD_PARTY = "Đang chờ xử lý phía đối tác",
}

export enum paymentStatusTransNumber {
  UN_PAID = "0",
  SUCCESS = "1",
  FAILED = "2",
  PENDING = "3",
  CANCEL = "4",
  THIRD_PARTY = "5",
}

export enum clearStatusEnum {
  INIT = 0,
  SUCCESS = 1,
  PENDING = 3,
  FAIL = 2,
}

export enum clearStatusTrans {
  SUCCESS = "Thành công",
  INIT = "Đang chờ xử lý ...",
  PENDING = "Đang chờ xử lý",
  FAIL = "Không thành công",
}

export interface clearStatusResType {
  transStatus: clearStatusEnum;
  billingCode: string;
  transDate: number;
  serviceCode: string;
  transCode: string;
  responseCode: number | null;
  categoryType: serviceCategoryEnum;
}
